import {
  PrimaryIntakeHumanTraffickingInformation
} from '../../../../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {
  StandardParentInputRow ,
  StandardTopPaddedRow,
  StandardRadioInput,
  StandardInputColumn
} from '../../../../../../../../components/util/form-components/standardLayout';
import Input from '../../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import React from 'react';
import CheckBoxInput
  from '../../../../../../../../components/util/form-components/formik-inputs/CheckBoxInput/CheckBoxInput';
import {Row} from 'react-bootstrap';
import {
  EPrimaryIntakeRelocation
} from '../../../../../../../../redux/web/entities/forms/intake/primaryIntake/primaryIntakeHelperModels';
import {useFormikContext} from 'formik';
import {TPrimaryIntakeUpsertForm} from '../../PrimaryIntakeModal';

export type THumanTraffickingInformationForm = Omit<PrimaryIntakeHumanTraffickingInformation, 'id'> & {
  id?: string;
};

interface Props {
  fieldPrefix: string;
}

export const HumanTraffickingInformationForm = (props: Props) => {
  const {values} = useFormikContext<TPrimaryIntakeUpsertForm>();
  const getFieldName = (name: keyof THumanTraffickingInformationForm) => `${props.fieldPrefix}.${name}`;
  return (
    <>
      <StandardParentInputRow label={'Human Trafficking Information - PLEASE VERIFY'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Trafficker'} columnSize={6}>
            <Input name={getFieldName('trafficker')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'When'} columnSize={6}>
            <Input name={getFieldName('when')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Safety Concerns'} columnSize={8}>
            <Input name={getFieldName('safetyConcerns')} type={'textarea'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'City'} columnSize={4}>
            <Input name={getFieldName('city')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Type of Exploitation'} columnSize={8}>
            <Input name={getFieldName('typeOfExploitation')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Length of Time'} columnSize={4}>
            <Input name={getFieldName('lengthOfTime')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Age of Entry'} columnSize={8}>
            <Input name={getFieldName('ageOfEntry')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Nickname'} columnSize={4}>
            <Input name={getFieldName('nickname')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Safe Location'} columnSize={8}>
            <Input name={getFieldName('safeLocation')} type={'textarea'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Re-location'} columnSize={4}>
            <Row>
              <StandardRadioInput
                name={getFieldName('relocation')}
                type={'radio'} label={'Yes'}
                value={EPrimaryIntakeRelocation.yes}
                checked={values.humanTraffickingInformation.relocation === EPrimaryIntakeRelocation.yes}
              />
              <StandardRadioInput
                name={getFieldName('relocation')}
                type={'radio'}
                label={'No'}
                value={EPrimaryIntakeRelocation.no}
                checked={values.humanTraffickingInformation.relocation === EPrimaryIntakeRelocation.no}
              />
              <StandardRadioInput
                name={getFieldName('relocation')}
                type={'radio'}
                label={'Maybe'}
                value={EPrimaryIntakeRelocation.maybe}
                checked={values.humanTraffickingInformation.relocation === EPrimaryIntakeRelocation.maybe}
              />
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
    </>
  );
};
