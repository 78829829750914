import React, {useState} from 'react';
import {useMount} from '../../../../hooks/useMount';
import {Alert, Button, Card, Col, Modal, Row, Tab, Tabs} from 'react-bootstrap';
import styles from '../../GrantExport.module.scss';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import {WebState} from '../../../../redux/core/types/WebState';
import {
  FlattenedSecondaryIntake, SecondaryIntake,
  secondaryIntakeStore
} from '../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
// tslint:disable-next-line:no-import-side-effect
import 'react-pivottable/pivottable.css';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import Plot from 'react-plotly.js';
import {downloadExport, processExport, sendBuilderQuery} from '../../../../api/exportApi';
import {AxiosError} from 'axios';
import {ReportFilterSelectionModal} from './ReportFilterSelectionModal/ReportFilterSelectionModal';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {TCombinedIntake} from '../ReportBuilder';
import {FlattenedPrimaryIntake} from '../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {AppTheme} from '../../../../appTheme';
import {v4} from 'uuid';

type FlattenedCombinedIntake = FlattenedPrimaryIntake & FlattenedSecondaryIntake;

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

type Props = {
  queriedIntakes: TCombinedIntake[];
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function ReportPivotTable(props: Props) {
  const {queriedIntakes, intakes, getIntakesForUserIds} = props;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableState, setTableState] = useState<any>();
  const [hiddenAttributes, setHiddenAttributes] = useState<string[]>([]);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const runExport = async () => {
    try {
      setProcessing(true);
      setErrorMessage('');
      const reportName = await processExport('');
      await downloadExport(reportName);
      setProcessing(false);
    } catch (e: AxiosError | any) {
      setErrorMessage('Error Generating the Report.');
      setProcessing(false);
    }
  };

  // @ts-ignore
  const data: FlattenedCombinedIntake[] =
    queriedIntakes.flatMap(i => {
      return {
        userId: i.userId,
        ...i.personalInformation,
        ...i.humanTraffickingIdentificationQuestions,
        ...i.parProgramQuestions,
        ...i.assessmentQuestions,
        ...i.humanTraffickingInformation,
        ...i.closingInformation,
        ...i.citizenshipAndIncomeInformation,
        ...i.commentsAndConcerns,
        ...i.criminalAndHousing,
        ...i.educationAndEmployment,
        ...i.familyAndMedicalHistory,
        ...i.personalEmergencyInformationAndLifeIssues,
        ...i.substanceAbuseAndTraffickingInformation
      };
    });

  useMount(() => {
    // @ts-ignore
    const [{zipCode, streetAddress, ...rest}] = data;
    setHiddenAttributes(Object.keys(rest));
  });

  const renderButtons = () => (
    <Row style={{display: 'flex'}}>
      {/*<Button*/}
      {/*  disabled={processing} onClick={runExport} variant={'info'}*/}
      {/*  className={styles['close-button']}*/}
      {/*  style={{backgroundColor: AppTheme.colors.oceanBlue, marginBottom: 'auto', marginRight: '2em'}}*/}
      {/*>*/}
      {/*  Download Report*/}
      {/*</Button>*/}
      <Button
        disabled={processing} variant={'info'}
        className={styles['close-button']}
        style={{backgroundColor: AppTheme.colors.oceanBlue, marginBottom: 'auto', marginRight: '2em'}}
        onClick={() => setShowFiltersModal(true)}
      >
        Edit filters
      </Button>
    </Row>
  );

  const [refreshUI, setRefreshUI] = useState('a');

  return (
    <Col>
      {renderButtons()}
      <Row           key={refreshUI}
                     style={{marginTop: '2em'}}>
        <PivotTableUI
          data={data}
          onChange={(s: any) => setTableState(s)}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          unusedOrientationCutoff={10}
          hiddenAttributes={hiddenAttributes}
          {...tableState}
        />
      </Row>
      {showFiltersModal ?
        <ReportFilterSelectionModal
          setShow={setShowFiltersModal}
          initialHiddenAttributes={hiddenAttributes}
          onSubmit={(values: string[]) => {
            setHiddenAttributes((prev) => values);
            setShowFiltersModal(false);
            setRefreshUI(v4());
          }}
        />
        : null}
      {errorMessage !== '' ? <Alert style={{marginBottom: 0}} variant='danger'>{errorMessage}</Alert> : null}
    </Col>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  intakes: secondaryIntakeStore.selectors.getAsArray(state),
  getIntakesForUserIds: secondaryIntakeStore.selectors.getByUserIds(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportPivotTable);
