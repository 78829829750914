import {BootstrapConfig, Config, Utils as QbUtils} from '@react-awesome-query-builder/bootstrap';
import React from 'react';
import {PrimaryConfig, SecondaryConfig} from './QueryBuilderConfigs';

// Sort keys to alphabetical order, then use fromEntries to convert the resulting array back to an object
/*const sortedFieldsConfig: Fields =
  Object.fromEntries(
    Object.entries(
      {...PersonalFieldsConfig})
      .sort(([aKey, aVal], [bKey, bVal]) => aVal.label && bVal.label && aVal.label > bVal.label ? 1 : -1)
  );*/

export const intakeConfig: Config = {
  ...BootstrapConfig,
  fields: {...PrimaryConfig, ...SecondaryConfig},
  settings: {
    ...BootstrapConfig.settings,
    customFieldSelectProps: {showSearch: true, useAsyncSearch: true}
  },
  widgets: {
    ...BootstrapConfig.widgets,
    date: {
      ...BootstrapConfig.widgets.date,
      sqlFormatValue: val => `'${val} 12:00:00'`
    }
  },
  operators: {
    ...BootstrapConfig.operators,
    equal: {
      ...BootstrapConfig.operators.equal,
      label: 'Equals'
    },
    not_equal: {
      ...BootstrapConfig.operators.not_equal,
      label: 'Does not Equal'
    },
    less: {
      ...BootstrapConfig.operators.less,
      label: 'Less than'
    },
    less_or_equal: {
      ...BootstrapConfig.operators.less_or_equal,
      label: 'Less than or Equal to'
    },
    greater: {
      ...BootstrapConfig.operators.greater,
      label: 'Greater than'
    },
    greater_or_equal: {
      ...BootstrapConfig.operators.greater_or_equal,
      label: 'Greater than or Equal to'
    }
  }
};

export const queryValue = {'id': QbUtils.uuid(), 'type': 'group'};
