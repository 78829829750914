import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../redux/core/types/WebState';
import PageHeader from '../../components/layout/PageHeader/PageHeader';
import {Card, Col, Container, Row} from 'react-bootstrap';
import styles from './Home.module.scss';
import {userStore} from '../../redux/web/entities/user';
import {getLogoLink} from '../../appTheme';
import HomePageLinkedCard from './components/HomePageLinkedCard/HomePageLinkedCard';
import {RoutePaths} from '../../router/RoutePaths';
import AdministratorComponent from '../../components/util/AdministratorComponent/AdministratorComponent';
import ParticipantComponent from '../../components/util/AdministratorComponent/ParticipantComponent';
import ShelterStaffComponent from '../../components/util/AdministratorComponent/ShelterStaffComponent';
import NotParticipantComponent from '../../components/util/AdministratorComponent/NotParticipantComponent';
import StaffComponent from '../../components/util/AdministratorComponent/StaffComponent';
import PaddedRowsListFormatter from '../../components/util/lists/PaddedRowsListFormatter/PaddedRowsListFormatter';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function Home(props: Props) {
  const {currentUser} = props;
  const renderTitleAndDescription = () => (
    <>
      <Row className='align-items-center' style={{padding: '0'}}>
        <Col style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
          <div style={{display: 'flex', flex: '1', justifyContent: 'center'}}>
            <img src={getLogoLink()} alt={'largeLogo'} style={{maxHeight: '8rem', maxWidth: '90%', display: 'flex'}}/>
          </div>
        </Col>
      </Row>
      <Row style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'column'}}>
        <Col style={{maxWidth: '1000px', padding: '0'}}>
          <div style={{padding: '0.5rem 0'}}>
            Welcome to the BTC portal!
          </div>
          <div style={{padding: '0.5rem 0'}}>
            The Breaking the Chains portal is a central point for the management of participant case files,
            participant classes, and both the shelter's participant and shelter staff tasks.
          </div>
          <div style={{textAlign: 'left'}}>
            <AdministratorComponent>
              <PaddedRowsListFormatter type={'unordered'} padding={'0.2rem 0'}>
                <div>
                  <span style={{fontWeight: 'bold'}}>Manage Classes </span>
                  - Track participant class times, specify which participants will attend and mark attendance.
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Manage Case Files </span>
                  - View all participants to select a case file to manage. Case files management includes the ability to upload documents, assign a document
                  from the document library, edit assigned documents, send DocuSign signature requests, and filling out pre-made forms.
                  Participants can view their own case files but are not able to edit their own documents.
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Manage Document Library </span>
                  - Upload common documents that are stored on the BTC portal server to be assigned to participants. Documents can be uploaded to participant
                  case files from local storage, but the document library is accessible to all administrators.
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Chore Chart </span>
                  - Assign and view participant chores which they can mark as complete.
                  Participants will only be able to complete chores which are assigned to themselves.
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Shelter Staff Checklist </span>
                  - Assign and view shelter staff tasks which they can mark as complete.
                  Shelter staff will only be able to complete tasks which are assigned to themselves, and a timestamp of the last update will be available.
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Manage Users </span>
                  - Create users, edit user information, and change user roles.
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Export </span>
                  - Query and visualize data related to participant intake forms.
                </div>
              </PaddedRowsListFormatter>
            </AdministratorComponent>
            <ShelterStaffComponent>
              <PaddedRowsListFormatter type={'unordered'} padding={'0.2rem 0'}>
                <div>
                  <span style={{fontWeight: 'bold'}}>Manage Classes </span>
                  - Track participant class times, specify which participants will attend and mark attendance.
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Participant Checklist </span>
                  - View participant chores which can be marked as complete. Participants can only complete tasks that are assigned to themselves.
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Shelter Staff Checklist </span>
                  - View shelter staff tasks which can be marked on they day of as complete. You will only be able to complete tasks that are assigned to you.
                </div>
              </PaddedRowsListFormatter>
            </ShelterStaffComponent>
            <StaffComponent>
              <PaddedRowsListFormatter type={'unordered'} padding={'0.2rem 0'}>
                <div>
                  <span style={{fontWeight: 'bold'}}>Manage Classes </span>
                  - Track participant class times, specify which participants will attend and mark attendance.
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Participant Checklist </span>
                  - View participant chores which can be marked as complete. Participants can only complete tasks that are assigned to themselves.
                </div>
              </PaddedRowsListFormatter>
            </StaffComponent>
            <ParticipantComponent>
              <PaddedRowsListFormatter type={'unordered'} padding={'0.2rem 0'}>
                <div>
                  <span style={{fontWeight: 'bold'}}>Classes </span>
                  - View both upcoming and previous classes assigned to you. Staff will keep track of attendance,
                  the classes page is only for personal planning.
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Case File </span>
                  - View your own case file containing documents and forms assigned to you. Items in your case file will be read-only and will only require
                  action if a DocuSign request is sent to your email address.
                </div>
                <div>
                  <span style={{fontWeight: 'bold'}}>Chore Chart </span>
                  - Track weekly chores assigned by BTC to their participants. You can only mark chores assigned to yourself as complete.
                </div>
              </PaddedRowsListFormatter>
            </ParticipantComponent>
          </div>
        </Col>
      </Row>
    </>
  );

  const renderLinkedCards = () => (
    <>
      <ParticipantComponent>
        <div style={{display: 'flex', flexShrink: '1'}}>
          <HomePageLinkedCard
            icon={'chalkboard-user'}
            text={'Classes'}
            link={RoutePaths.participantClasses}
            size={'8x'}
          />
        </div>
      </ParticipantComponent>
      <ParticipantComponent>
        <div style={{display: 'flex', flexShrink: '1'}}>
          <HomePageLinkedCard
            icon={'file'}
            text={'Case File'}
            link={RoutePaths.caseFilePaths.CaseFile.replace(':id', currentUser?.id ?? '')}
            size={'8x'}
          />
        </div>
      </ParticipantComponent>
      <NotParticipantComponent>
        <div style={{display: 'flex', flexShrink: '1'}}>
          <HomePageLinkedCard
            icon={'chalkboard-user'}
            text={'Manage Classes'}
            link={RoutePaths.participantClassManagement}
            size={'8x'}
          />
        </div>
      </NotParticipantComponent>
      <AdministratorComponent>
        <div style={{display: 'flex', flexShrink: '1'}}>
          <HomePageLinkedCard
            icon={'file'}
            text={'Manage Case Files'}
            link={RoutePaths.caseFileManagement}
            size={'8x'}
          />
        </div>
      </AdministratorComponent>
      <AdministratorComponent>
        <div style={{display: 'flex', flexShrink: '1'}}>
          <HomePageLinkedCard
            icon={'file-upload'}
            text={'Manage Document Library'}
            link={RoutePaths.documentManagement}
            size={'8x'}
          />
        </div>
      </AdministratorComponent>
      <div style={{display: 'flex', flexShrink: '1'}}>
        <HomePageLinkedCard
          icon={'tasks'}
          text={'Chore Chart'}
          link={RoutePaths.choreChartPaths.View}
          size={'8x'}
        />
      </div>
      <AdministratorComponent>
        <div style={{display: 'flex', flexShrink: '1'}}>
          <HomePageLinkedCard
            icon={'check'}
            text={'Shelter Staff Checklist'}
            link={RoutePaths.checklistPaths.View}
            size={'8x'}
          />
        </div>
      </AdministratorComponent>
      <ShelterStaffComponent>
        <div style={{display: 'flex', flexShrink: '1'}}>
          <HomePageLinkedCard
            icon={'check'}
            text={'Shelter Staff Checklist'}
            link={RoutePaths.checklistPaths.View}
            size={'8x'}
          />
        </div>
      </ShelterStaffComponent>
      <AdministratorComponent>
        <div style={{display: 'flex', flexShrink: '1'}}>
          <HomePageLinkedCard
            icon={'users'}
            text={'Manage Users'}
            link={RoutePaths.userManagement}
            size={'8x'}
          />
        </div>
      </AdministratorComponent>
    </>
  );

  const renderContent = () => {
    return (
        <Card className={styles['page-body']} style={{borderLeftWidth: 0, borderRightWidth: 0}}>
          <Card.Body style={{width: '100%'}}>
            {renderTitleAndDescription()}
            <Row style={{display: 'flex', flex: '1', justifyContent: 'center'}}>
              {renderLinkedCards()}
            </Row>
          </Card.Body>
        </Card>
    );
  };

    return (
      <PageHeader label='BTC Home' removeMargin={true}>
        <Container fluid={true} className={styles['config-container']}>
          {renderContent()}
        </Container>
      </PageHeader>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({}, dispatch)});
const mapStateToProps = (state: WebState) => ({currentUser: userStore.selectors.getCurrentUser(state)});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
