import {
  number,
  object, string, mixed,
} from 'yup';
import {AssignDocumentProps} from './CaseFileDocumentAssignmentModal';

// @ts-ignore
export const CaseFileDocumentAssignmentModalSchema = object<AssignDocumentProps>({
  btcDocumentId: string().required('Required')
});
