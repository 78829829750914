import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import styles from './UserGrid.module.scss';
import gridStyles from '../../../../GridStyles.module.scss';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/core/types/WebState';
import {ConfirmationDialog} from '../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import IconButton, {
  LabeledIconButton,
  LabeledIconButtonProps
} from '../../../../../components/util/widgets/IconButton/IconButton';
import {Button, Col, Modal, Row, TableProps} from 'react-bootstrap';
import BootstrapTable, {BootstrapTableProps, ColumnDescription, SearchProps} from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {ColumnToggle, Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {AccountStatus, User, userStore} from '../../../../../redux/web/entities/user';
import UserModal from '../UserModal/UserModal';
import {makeUser} from '../../../../../redux/web/factory';
import {Navigate, useSearchParams} from 'react-router-dom';
import {Role, roleStore} from '../../../../../redux/web/entities/role';
import {ToggleListProps} from 'react-bootstrap-table2-toolkit';
import {RoutePaths} from '../../../../../router/RoutePaths';
import PrimaryIntakeModal from './PrimaryIntakeModal/PrimaryIntakeModal';
import {primaryIntakeStore} from '../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {loadUserFormData} from '../../../../../redux/web/stateResponses/userFormsData';
import {format, utcToZonedTime} from 'date-fns-tz';
import {parseISO} from 'date-fns';
import {localTz, localTzFriendlyFormat} from '../../../../../util';
import {noProfilePicture} from '../../../../../appTheme';
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber
} from 'react-phone-number-input';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function StyledLabeledIconButton (buttonProps: LabeledIconButtonProps) {
  return (
    <Col style={{justifyContent: 'center', alignItems: 'center', wordBreak: 'initial'}}>
      <LabeledIconButton
        {...buttonProps}
        size={'3x'}
        labelStyle={{marginBottom: '10px', flexWrap: 'nowrap', whiteSpace: 'nowrap', ...buttonProps.labelStyle}}
      />
    </Col>
  );
}

type modalType = 'none' | 'editUser' | 'selectUser' | 'intake' | 'createStaff' | 'createShelterStaff' | 'createAdministrator';

function UserGrid(props: Props) {
  const {archivedUsers, nonArchivedUsers, getRoleByName, currentUser,
    actions: {archiveUser, restoreUser, upsertUser, loadUserForms}} = props;
  const { SearchBar } = Search;
  const {ToggleList} = ColumnToggle;
  const [isDeleting, setIsDeleting] = useState('');
  const [isActivating, setIsActivating] = useState('');
  const [viewingArchived, setViewingArchived] = useState(false);
  const [currentModalType, setCurrentModalType] = useState<modalType>('none');
  const [loadingForms, setLoadingForms] = useState(true);
  const [editingUser, setEditingUser] = useState(makeUser());
  const [showColumnToggleFilters, setShowColumnToggleFilters] = useState(false);
  const [searchParams] = useSearchParams();
  const [redirectUrl, setRedirectUrl] = useState('');
  const resetState = () => {
    setEditingUser(makeUser());
    setCurrentModalType('none');
  };
  const getFieldName = (name: keyof User) => name;

  const getRoleForUserModal = (userType: 'editUser' | 'createStaff' | 'createShelterStaff' | 'createAdministrator'): Role | undefined => {
    if (userType === 'createStaff')
      return getRoleByName('Staff');
    if (userType === 'createShelterStaff')
      return getRoleByName('Shelter Staff');
    if (userType === 'createAdministrator')
      return getRoleByName('Administrator');
  };

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

  const userCreationTypeSelection = () => (
    <Modal show={true} centered={true} className={styles['on-top']} onHide={() => undefined}>
      <Modal.Body>
        <Modal.Title>
          Create a New User
        </Modal.Title>
        <Row style={{paddingTop: '10px', justifyContent: 'center', alignItems: 'center'}}>
          <StyledLabeledIconButton
            label={'Participant'}
            icon={'user'}
            onClick={() => {
              setCurrentModalType('intake');
            }}
          />
          <StyledLabeledIconButton
            label={'Staff'}
            icon={'user-edit'}
            labelStyle={{paddingRight: '20px'}}
            onClick={() => {
              setEditingUser(makeUser());
              setCurrentModalType('createStaff');
            }}
          />
          <StyledLabeledIconButton
            label={'Shelter Staff'}
            icon={'house-user'}
            onClick={() => {
              setEditingUser(makeUser());
              setCurrentModalType('createShelterStaff');
            }}
          />
          <StyledLabeledIconButton
            label={'Administrator'}
            icon={'user-lock'}
            onClick={() => {
              setEditingUser(makeUser());
              setCurrentModalType('createAdministrator');
            }}
          />
        </Row>
        <Row style={{paddingTop: '10px'}}>
          <Button variant={'danger'} className={styles['modal-buttons']} onClick={() => setCurrentModalType('none')}>
            Cancel
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );

  const renderCurrentModal = () => {
    if (currentModalType === 'none')
      return null;
    if (currentModalType === 'selectUser')
      return userCreationTypeSelection();
    if (currentModalType === 'intake')
      return (
        <PrimaryIntakeModal
          onSubmit={resetState}
          onCancel={resetState}
          disabled={false}
        />
      );
    return (
      <UserModal
        editable={true}
        onSubmit={resetState}
        onCancel={resetState}
        role={getRoleForUserModal(currentModalType)}
        existingUser={editingUser?.id ? editingUser : undefined}
      />
    );
  };

  const setViewingArchivedAndColumnView = async (newState: boolean) => {
    await setViewingArchived(() => newState);
    if (!newState) {
      const updatedColumns = columns.map((value) => value.dataField === getFieldName('archivedAt') ? {...value, hidden: true} : value);
      setColumns(() => updatedColumns);
    } else {
      const updatedColumns = columns.map((value) => value.dataField === getFieldName('archivedAt') ? {...value, hidden: false} : value);
      setColumns(() => updatedColumns);
    }
  };

  const onColumnToggle = (col: ColumnDescription<User>) => {
    const updatedTableColumns = columns.map((value) => value.dataField === col.dataField ? {...value, hidden: !col.hidden} : value);
    const visibleRecordInfo = updatedTableColumns.filter((value) => (!value.hidden || false) && !col.isDummyField).length > 1;
    setColumns(visibleRecordInfo ? updatedTableColumns : columns);
  };

  const FilteredToggleList = ({toggles}: {toggles: boolean[]}) => (
    <div className={gridStyles['filters-button-group']}>
      {
        columns
          .filter(col => !(col.isDummyField || col.dataField === (getFieldName('id')) ||
            (!viewingArchived && col.dataField === getFieldName('archivedAt'))))
          .map(column => ({...column, toggle: toggles[column.dataField]}))
          .map(column => (
            <Button
              variant={column.toggle ? 'success' : 'secondary'}
              key={ column.dataField }
              onClick={ () => onColumnToggle(column) }
            >
              { column.dataField === getFieldName('archivedAt') ? 'Archived At' :
                column.dataField === getFieldName('createdAt') ? 'Created On' :
                column.dataField === getFieldName('profilePicturePath') ? 'Picture' :
                column.text }
            </Button>
          ))
      }
    </div>
  );

  const phoneNumberFormatter = (phoneNumber: string) => {
    if (typeof phoneNumber !== 'string') return phoneNumber;
    if (isValidPhoneNumber(phoneNumber)) return formatPhoneNumber(phoneNumber);
    if (isPossiblePhoneNumber(phoneNumber)) return formatPhoneNumberIntl(phoneNumber);
    return phoneNumber;
  };

  const actionsFormatter = useCallback((cell: any, user: User) => {
    return (
      <div className={gridStyles['table-actions-wrapper']}>
        {user.archivedAt ?
            <IconButton
              icon={'plus'}
              styles={{marginLeft: '.5rem'}}
              size={'2x'}
              iconToolTipText={'Activate User'}
              onClick={() => setIsActivating(user.id.toString())}
              color={'green'}
            />
        : (
          <>
          {user.role.roleName === 'Participant' ?
            <div>
              <IconButton
              icon='file'
              size={'2x'}
              styles={{marginLeft: '.5rem'}}
              onClick={() => {
                setRedirectUrl(RoutePaths.caseFilePaths.CaseFile.replace(':id', user.id));
              }}
              color={'#005A9C'}
              />
            </div> : null}
            <div>
              <IconButton
                icon='edit'
                size={'2x'}
                styles={{marginLeft: '.5rem'}}
                onClick={() => {
                  if(user.id === currentUser?.id) {
                    setRedirectUrl(RoutePaths.profilePaths.Edit);
                  } else {
                    setEditingUser(user);
                    setCurrentModalType('editUser');
                  }
                }}
                color={'#005A9C'}
              />
            </div>
            <div>
              <IconButton
                icon={'trash-alt'}
                styles={{color: 'red', marginLeft: '.5rem'}}
                size={'2x'}
                iconToolTipText={'Deactivate User'}
                onClick={() => setIsDeleting(user.id.toString())}
              />
            </div>
          </>
          )
        }
      </div>
    );
  }, [viewingArchived]);

  const profilePictureFormatter = (cell: any, user: User) => {
    return (
      <div style={{display: 'flex', justifyContent: 'flex-start'}}>
        <img
          style={{height: '2rem', width: '2rem', borderRadius: '50%'}}
          src={user.profilePicturePath !== '' ? `${user.profilePicturePath}` : noProfilePicture()}
          alt={'Profile picture'}
        />
      </div>
    );
  };

  const dateTimeFormatter = (isoDateTime: string) => {
    const formatDate = (isoDate: string) =>
      format(utcToZonedTime(parseISO(isoDate), localTz as any as string), 'MM/dd/yyyy hh:mm:ss a', {timeZone: localTz as any as string});
    return (
      <div style={{display: 'flex', justifyContent: 'flex-start'}}>
        {`${formatDate(isoDateTime)}`}
      </div>
    );
  };

  const accountStatusFormatter = (cell: any, user: User) => {
    return (
      <>
        {AccountStatus[user.accountStatus].replace(/([A-Z])/g, ' $1').trim()}
      </>
    );
  };

  const filterActivatingUser = () => {
    return nonArchivedUsers.filter(u => u.email === searchParams.get('edit')!);
  };

  const [columns, setColumns] = useState<ColumnDescription<User>[]>([
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      searchable: false,
      hidden: true
    },
    {
      dataField: 'profilePicturePath',
      text: '',
      sort: false,
      searchable: false,
      formatter: profilePictureFormatter,
      style: {width: '1px'}
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      searchable: true
    },
    {
      dataField: 'standardId',
      text: 'Participant ID',
      sort: true,
      hidden: true
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true
    },
    {
      dataField: 'primaryPhone',
      text: 'Primary Phone',
      formatter: (cell, user) => phoneNumberFormatter(user.primaryPhone)
    },
    {
      dataField: 'secondaryPhone',
      text: 'Secondary Phone',
      hidden: true,
      formatter: (cell, user) => phoneNumberFormatter(user.secondaryPhone)
    },
    {
      dataField: 'address',
      text: 'Address',
      formatter: (cell: any, user: User) => user.address || user.addressLineTwo ? `${user.address}${user.addressLineTwo ? ` ${user.addressLineTwo}` : ''}` : '',
      hidden: true
    },
    {
      dataField: 'state',
      text: 'City & State',
      sort: true,
      formatter: (cell: any, user: User) => user.city || user.state ? `${user.city ? `${user.city}, ` : ''}${user.state}` : '',
      hidden: true
    },
    {
      dataField: 'zip',
      text: 'ZIP',
      sort: true,
      hidden: true
    },
    {
      dataField: 'role.roleName',
      formatter: (cell: any, user: User) => (user.role.roleName),
      text: 'Role',
      sort: true
    },
    {
      dataField: 'birthDate',
      text: 'DOB',
      formatter: (cell: any, user: User) => user.birthDate ? format(parseISO(user.birthDate), 'MM/dd/yyyy') : '',
      sort: true,
      searchable: true
    },
    {
      dataField: 'createdAt',
      hidden: true,
      text: `Created On (${localTzFriendlyFormat})`,
      formatter: (cell: any, user: User) => dateTimeFormatter(user.createdAt)
    },
    {
      dataField: 'archivedAt',
      hidden: !viewingArchived,
      text: `Deactivated On (${localTzFriendlyFormat})`,
      formatter: (cell: any, user: User) => user.archivedAt ? dateTimeFormatter(user.archivedAt) : ''
    },
    {
      dataField: 'actionsColumn',
      text: '',
      isDummyField: true,
      searchable: false,
      formatExtraData: viewingArchived,
      formatter: (cell, row, rowIndex, formatExtraData) => actionsFormatter(cell, row),
      headerStyle: () => {
        return { width: '8rem'};
      },
      style: () => {
        return { width: '8rem'};
      }
    }
  ]);

  return (
    <ToolkitProvider
      keyField='id'
      data={searchParams.get('edit')! !== null ? filterActivatingUser() : viewingArchived ? archivedUsers : nonArchivedUsers}
      columns={columns}
      search={{
        searchFormatted: true
      }}
      columnToggle={true}
    >
      {(tableProps:
          { baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<User, number>> & Readonly<BootstrapTableProps>;
            searchProps: SearchProps<User>;
            columnToggleProps: ToggleListProps;}) => (
        <>
          <div className={gridStyles['button-groups-container']}>
            <div className={gridStyles['button-group']}>
              <div>
                <Button
                  onClick={async () => setViewingArchivedAndColumnView(!viewingArchived)}
                  style={{backgroundColor: '#005A9C'}}
                >
                  {viewingArchived ? 'View Active Users' : 'View Archived Users'}
                </Button>
              </div>
              <Button
                onClick={() => {
                  setCurrentModalType('selectUser');
                }}
                style={{backgroundColor: '#005A9C'}}
              >
                Add User
              </Button>
              <Button style={{backgroundColor: '#005A9C'}} onClick={() => setShowColumnToggleFilters(!showColumnToggleFilters)}>
                {showColumnToggleFilters ? 'Hide' : 'Show'} Column Filters
              </Button>
            </div>
            {showColumnToggleFilters ?
              <div className={gridStyles['button-group']}>
                <FilteredToggleList {...tableProps.columnToggleProps}/>
              </div>
              : null
            }
          </div>
          {renderRedirect()}
          <SearchBar {...tableProps.searchProps}/>
          <BootstrapTable
            wrapperClasses={gridStyles['responsive-table-wrapper']}
            classes={gridStyles['table-auto']}
            rowStyle={{height: '100%'}}
            {...tableProps.baseProps}
          />
          {isDeleting !== '' && (
            <ConfirmationDialog
              onAccept={async () => {
              await archiveUser(isDeleting);
              setIsDeleting('');
              }}
              onDecline={async () => { setIsDeleting(''); }}
              open={isDeleting !== ''}
              prompt='Are you sure you want to archive this User?'
              positiveText='Yes'
              negativeText='No'
              positiveVariant='success'
              negativeVariant='danger'
            />
            )}
          {isActivating !== '' && (
            <ConfirmationDialog
              onAccept={async () => {
                await restoreUser(isActivating);
                setIsActivating('');
              }}
              onDecline={async () => { setIsActivating(''); }}
              open={isActivating !== ''}
              prompt='Are you sure you want to Activate this User?'
              positiveText='Yes'
              negativeText='No'
              positiveVariant='success'
              negativeVariant='danger'
            />
          )}
          {renderCurrentModal()}
        </>
      )}
    </ToolkitProvider>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    archiveUser: userStore.actions.archiveUser,
    restoreUser: userStore.actions.restoreUser,
    upsertUser: userStore.actions.upsert,
    getPrimaryIntake: primaryIntakeStore.actions.getByUserId,
    loadUserForms: loadUserFormData
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  nonArchivedUsers: userStore.selectors.getNonArchivedUsers(state),
  archivedUsers: userStore.selectors.getArchivedUsers(state),
  getUserById: userStore.selectors.getById(state),
  getRoleByName: roleStore.selectors.getRoleByName(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserGrid);
