import {Accordion, Button, Modal, Row} from 'react-bootstrap';
import React, {FormEvent, SetStateAction, useMemo, useState} from 'react';
import styles from './ReportFilterSelectionModalStyles.module.scss';
import {SecondaryIntake} from '../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {
  makePrefilledIntakeForm,
  makePrefilledSecondaryIntakeForm
} from '../../../../../redux/web/factory/forms/intakeForms';
import {startCase} from 'lodash';
import {StandardCheckboxInput} from '../../../../../components/util/form-components/standardLayout';
import {Form, Formik} from 'formik';
import {TCombinedIntake} from '../../ReportBuilder';
import {PrimaryIntake} from '../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';

const samplePrimary = makePrefilledIntakeForm();
const primaryKeys = Object.keys(samplePrimary);
const sampleSecondary = makePrefilledSecondaryIntakeForm('treeSample');
const secondaryKeys = Object.keys(sampleSecondary);
const combinedIntake: TCombinedIntake = {...samplePrimary as PrimaryIntake, ...sampleSecondary};
const {id, userId, employeeName, fpdReferral, referredBy, reasonForCall, ...intakeFieldsWithChildren} = combinedIntake;

interface Props {
  setShow: React.Dispatch<SetStateAction<boolean>>;
  initialHiddenAttributes: string[];
  onSubmit: (values: string[]) => void;
}

export function ReportFilterSelectionModal(props: Props) {
  const {setShow, initialHiddenAttributes, onSubmit} = props;
  const [hiddenAttributes, setHiddenAttributes] = useState(initialHiddenAttributes);

  const processSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.persist();
    e.preventDefault();
    onSubmit(hiddenAttributes);
  };

  // not sure if spread needed but don't want to mutate
  const renderFilters = (obj: object) => {
    return [...Object.keys(obj)]
      .sort((a, b) => a > b ? 1 : -1)
      .filter((key) => key !== 'id')
      .map((key, index) =>
        <StandardCheckboxInput
          key={key}
          name={key}
          label={startCase(key)}
          checked={!hiddenAttributes.includes(key)}
          onChange={(checked: boolean) => {
            checked ? setHiddenAttributes(hiddenAttributes.filter(k => k !== key)) : setHiddenAttributes([...hiddenAttributes, key]);
          }}
          style={{marginRight: '2.5rem'}}
        />);
  };

  // formik not needed but easier to just use formik checkbox input component's style
  // this is so jank lol but im hurry
  return (
    <Modal show={true} size={'lg'}>
      <Modal.Body>
        <Formik<any>
          initialValues={{...Object.keys(combinedIntake).map((k) => ({[k]: false})), ...initialHiddenAttributes.map((k) => ({[k]: true}))}}
          onSubmit={() => undefined}
        >
          {()  => (
            <Form noValidate={false}
                  onSubmit={(e) => processSubmit(e)}>
              <Modal.Title>
                Edit Report Filters
              </Modal.Title>
              <Accordion>
                {Object.entries(intakeFieldsWithChildren).map(([key, val]: [string, object], index) => {
                  const header = `${primaryKeys.includes(key) ? 'Primary Intake' : 'Secondary Intake'} ${startCase(key)}`;
                  return (
                  <Accordion.Item eventKey={key} key={index}>
                    <Accordion.Header>{header}</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        {renderFilters(val)}
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  );
                })}
              </Accordion>
              <Row style={{marginTop: '10px'}}>
                <div className={styles['form-buttons']}>
                  <Button variant='danger' onClick={() => setShow(false)} className={styles['close-button']}>Close</Button>
                  <Button variant='success' type={'submit'}>Submit</Button>
                </div>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
