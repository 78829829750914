import {bindActionCreators, Dispatch} from 'redux';
import {loadPrimaryIntakeData} from '../../../redux/web/stateResponses/stateManagement';
import {WebState} from '../../../redux/core/types/WebState';
import {
  SecondaryIntake,
  secondaryIntakeStore
} from '../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {connect} from 'react-redux';
import {sendBuilderQuery} from '../../../api/exportApi';
import {AxiosError} from 'axios';
import React, {useState} from 'react';
import {Alert, Col, Tab, Tabs} from 'react-bootstrap';
import {IntakeQueryBuilder} from './IntakeQueryBuilder/IntakeQueryBuilder';
import ReportPivotTable from './PivotTable/ReportPivotTable';
import {PrimaryIntake, primaryIntakeStore} from '../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';

export type TCombinedIntake = PrimaryIntake & SecondaryIntake;

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function ReportBuilder(props: Props) {
  const {primaryIntakes, secondaryIntakes, getPrimaryIntakesForUserIds, getSecondaryIntakesForUserIds} = props;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [queriedIntakes, setQueriedIntakes] = useState<TCombinedIntake[]>([]);
  const [currentTab, setCurrentTab] = useState('query');

  const runQuery = async (query: string | undefined) => {
    if (query) {
      try {
        setProcessing(true);
        setErrorMessage('');
        const userIds = await sendBuilderQuery(query);
        setErrorMessage('');
        const primaries = getPrimaryIntakesForUserIds(userIds);
        const secondaries = getSecondaryIntakesForUserIds(userIds);
        const combined: TCombinedIntake[] = [];
        primaries.forEach((p, i) => {
          combined.push({
            ...primaries[i],
            ...secondaries.find(s => s.userId === p.userId)
          } as TCombinedIntake);
        });
        setQueriedIntakes(combined);
        if (userIds.length) {
          setCurrentTab('pivot');
        }
        else
          setErrorMessage('No matching records found!');
        setProcessing(false);
      } catch (e: AxiosError | any) {
        setErrorMessage('Error querying records.');
        setProcessing(false);
      }
    }
  };

  return (
    <Tabs activeKey={currentTab} onSelect={(tab) => tab && setCurrentTab(tab)}>
      <Tab title={'Build Report'} eventKey={'query'} style={{paddingTop: '10px'}}>
        <IntakeQueryBuilder onSubmit={runQuery} fields={secondaryIntakes} loading={loading}/>
        {errorMessage !== '' ? <Alert style={{marginBottom: 0}} variant='danger'>{errorMessage}</Alert> : null}
      </Tab>
      <Tab title={'Visualize'} eventKey={'pivot'} disabled={!queriedIntakes.length} style={{paddingTop: '10px'}}>
        {queriedIntakes.length ? <ReportPivotTable queriedIntakes={queriedIntakes}/> : null}
      </Tab>
    </Tabs>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  primaryIntakes: primaryIntakeStore.selectors.getAsArray(state),
  secondaryIntakes: secondaryIntakeStore.selectors.getAsArray(state),
  getPrimaryIntakesForUserIds: primaryIntakeStore.selectors.getByUserIds(state),
  getSecondaryIntakesForUserIds: secondaryIntakeStore.selectors.getByUserIds(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportBuilder);
