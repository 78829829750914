import {combineReducers} from 'redux';
import {UserActions, userPersistConfig, users} from './user';
import {persistReducer} from 'redux-persist';
import {RoleActions, roles} from './role';
import {BtcDocumentActions, btcDocuments} from './btcDocument';
import {StandardParticipantClassActions, participantClasses} from './participantClass';
import {PrimaryIntakeActions, primaryIntakes} from './forms/intake/primaryIntake/PrimaryIntake';
import {CaseFileActions, caseFiles} from './caseFile';
import {CaseFileDocumentActions, caseFileDocuments} from './caseFileDocument';
import {weeklyCaseManagementNotes, WeeklyCaseManagementNotesActions} from './forms/WeeklyCaseManagementNotes';
import {AdmissionCoverSheetActions, admissionCoverSheets} from './forms/admissionCoverSheet';
import {GoalSheetActions, goalSheets} from './forms/goalSheet';
import {NewParticipantChecklistActions, newParticipantChecklists} from './forms/newParticipantChecklist';
import {ChoreChartWeekActions, choreChartWeeks} from './choreChartWeek';
import {SecondaryIntakeActions, secondaryIntakes} from './forms/intake/secondaryIntake/secondaryIntake';
import {ChecklistWeekActions, checklistWeeks} from './checklistWeek';

export const entities = combineReducers({
  users: persistReducer(userPersistConfig, users) as unknown as typeof users,
  roles: roles,
  btcDocuments: btcDocuments,
  participantClasses: participantClasses,
  primaryIntakes: primaryIntakes,
  secondaryIntakes: secondaryIntakes,
  caseFiles: caseFiles,
  caseFileDocuments: caseFileDocuments,
  // forms
  weeklyCaseManagementNotes: weeklyCaseManagementNotes,
  admissionCoverSheets: admissionCoverSheets,
  goalSheets: goalSheets,
  newParticipantChecklists: newParticipantChecklists,
  choreCharts: choreChartWeeks,
  checklists: checklistWeeks
});

export type Entities  = ReturnType<typeof entities>;

export type EntitiesActions =
  UserActions |
  RoleActions |
  BtcDocumentActions |
  StandardParticipantClassActions |
  CaseFileActions |
  CaseFileDocumentActions |
  // form actions
  PrimaryIntakeActions |
  SecondaryIntakeActions |
  WeeklyCaseManagementNotesActions |
  AdmissionCoverSheetActions |
  GoalSheetActions |
  NewParticipantChecklistActions |
  ChoreChartWeekActions |
  ChecklistWeekActions;
