import React, {FormEvent, useState} from 'react';
import {Alert, Button, Col, Modal, Row, Spinner, Tab, Tabs} from 'react-bootstrap';
import {Form, Formik, FormikErrors, FormikValues} from 'formik';
import styles from '../ParticipantFormModalStyles.module.scss';
import {Form as BSForm} from 'react-bootstrap';
import {StandardTopPaddedRow} from '../../../../../../components/util/form-components/standardLayout';
import {ConfirmationDialog} from '../../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../../../redux/util/http';
import {bindActionCreators, Dispatch} from 'redux';
import {userStore} from '../../../../../../redux/web/entities/user';
import {WebState} from '../../../../../../redux/core/types/WebState';
import {connect} from 'react-redux';
import {GoalSheet, goalSheetStore} from '../../../../../../redux/web/entities/forms/goalSheet';
import {GoalFieldArray} from './GoalFieldArray';
import {makeGoalSheet} from '../../../../../../redux/web/factory/forms/goalSheet';
import {
  CompletionStateIndicator
} from '../../../../../../components/util/widgets/CompletionStateIndicator/CompletionStateIndicator';

export type TGoalSheetForm = Omit<GoalSheet, 'id'> & {
  id?: string;
};

type Props = {
  userId: string;
  editable: boolean;
  existingGoalSheet?: GoalSheet;
  onSubmit: () => void;
  onCancel: () => void;
  disabled?: boolean;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function WeeklyCaseManagementNotesModal(props: Props) {
  const {userId, editable, onSubmit, onCancel, existingGoalSheet, getUserById, disabled = true, getGoalSheetByUserId, actions: {upsertGoalSheet}} = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentModal, setCurrentModal] = useState<'goalSheet' | 'cancel'>('goalSheet');
  const [errorMessage, setErrorMessage] = useState('');
  const [validateOnChangeAfterSubmit, setValidateOnChangeAfterSubmit] = useState(false);
  const [tabKey, setTabKey] = useState<any>('sixMonths');
  const username = getUserById(userId)?.name ?? 'undefined name';
  const getFieldName = (name: keyof TGoalSheetForm) => name;
  const userGoalSheet = () => {
    const goalSheet = getGoalSheetByUserId(userId);
    return goalSheet ? {...goalSheet} : makeGoalSheet(userId);
  };

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    values: TGoalSheetForm,
    validate: (values: TGoalSheetForm) => Promise<FormikErrors<TGoalSheetForm>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    setValidateOnChangeAfterSubmit(true);
    e.persist();
    e.preventDefault();
    const errors = await validate(values);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
      setErrorMessage('Errors were found in the form.');
    } else {
      try {
        await upsertGoalSheet(values);
        onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(getErrorResponseMessage(e));
      }
      setIsSubmitting(false);
    }
  };

  const renderCancelConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        open={currentModal === 'cancel'}
        onAccept={async () => onCancel()}
        onDecline={async () => setCurrentModal('goalSheet')}
        positiveVariant={'success'}
        negativeVariant={'danger'}
        positiveText={'Yes'}
        negativeText={'No'}
        prompt={`Are you sure you would like to cancel your edits to ${username}'s goal sheet?`}
      />
    );
  };

  const renderButtons = (setValues: (val: TGoalSheetForm) => any) => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={() => disabled ? onCancel() : setCurrentModal('cancel')} variant={'danger'} className={styles['close-button']}>
            {disabled ? 'Close' : 'Cancel'}
          </Button>
        }
        {!disabled && !isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  const tabTitleFormatter = (title: string, form: (keyof TGoalSheetForm)[], errors: FormikErrors<TGoalSheetForm>) => {
    const originalFormat = {tabClassName: undefined, title: title};
    const errorFormat = {tabClassName: styles['tab'], title: `${title}*`};
    return Object.values(form).find(k => k in errors) ? errorFormat : originalFormat;
  };


  return (
    <>
      <Formik
        initialValues={makeGoalSheet(userId, existingGoalSheet)}
        onSubmit={() => undefined}>
        {({values, validateForm, handleSubmit, errors, setValues}) => (
          <Modal show={currentModal === 'goalSheet'} centered={true} size={'xl'}>
            <Modal.Body>
              <Modal.Title>
                <Row>
                  {`${username}'s Goal Sheet`}
                  <CompletionStateIndicator
                    existing={!!existingGoalSheet}
                    existingText={'Editing Completed Form'}
                    styles={{alignSelf: 'flex-end', marginLeft: 'auto', marginRight: '15px'}}
                  />
                </Row>
              </Modal.Title>
              <Col style={{paddingTop: '10px'}}>
                <Form noValidate={false} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                  <Tabs activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                    <Tab
                      eventKey={'sixMonths'}
                      {...tabTitleFormatter('Six Months', [getFieldName('sixMonths')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <GoalFieldArray
                          goals={values.sixMonths}
                          editable={true}
                          fieldPrefix={getFieldName('sixMonths')}
                          headerLabel={'Six Months'}
                        />
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={'oneYear'}
                      {...tabTitleFormatter('One Year', [getFieldName('oneYear')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <GoalFieldArray
                          goals={values.oneYear}
                          editable={true}
                          fieldPrefix={getFieldName('oneYear')}
                          headerLabel={'One Year'}
                        />
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={'fiveYears'}
                      {...tabTitleFormatter('Five Years', [getFieldName('fiveYears')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <GoalFieldArray
                          goals={values.fiveYears}
                          editable={true}
                          fieldPrefix={getFieldName('fiveYears')}
                          headerLabel={'Five Years'}
                        />
                      </fieldset>
                    </Tab>
                  </Tabs>
                  {errorMessage !== '' ?
                    <div style={{marginTop: '1rem'}}>
                      <Alert variant='danger'>{errorMessage}</Alert>
                    </div>
                    : null}
                  <StandardTopPaddedRow style={{paddingTop: '10px'}}>
                    <BSForm.Group className={styles['form-buttons']}>
                      {renderButtons(setValues)}
                    </BSForm.Group>
                  </StandardTopPaddedRow>
                </Form>
              </Col>
            </Modal.Body>
          </Modal>
        )}
      </Formik>
      {renderCancelConfirmationDialog()}
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertGoalSheet: goalSheetStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getUserById: userStore.selectors.getById(state),
  getGoalSheetByUserId: (userId: string) => goalSheetStore.selectors.getAsArray(state).find(gs => gs.userId === userId)
});
export default connect(mapStateToProps, mapDispatchToProps)(WeeklyCaseManagementNotesModal);

