import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities} from '../selectors';
import {CommonDispatch} from '../index';
import {
  assignCaseFileDocument, disableCaseFileDocument,
  getCaseFileDocumentDataById, mergeCaseFileDocuments, sendSigningEmail,
  upsertCaseFileDocument, voidSigningEmail
} from '../../../api/caseFileManagementApi';
import {
  MergeCaseFileDocumentsRequest
} from '../../../pages/CaseFile/Modals/CaseFileDocumentMergeModal/CaseFileDocumentMergeModal';
import {createAction} from 'typesafe-actions';

export interface CaseFileDocument {
  id: string;
  name: string;
  data: string;
  caseFileId: string;
  status: CaseFileDocumentStatus;
  envelopeId: string;
  signatures: DocumentSignatureLocation[];
  wetSignatureProvided: boolean;
  disabled: boolean;
}

export interface DocumentSignatureLocation {
  formFieldId: string;
  pageNumber: string;
  tabLabel: string;
  xPosition: string;
  yPosition: string;
}

export enum CaseFileDocumentStatus
{
  None = 0,
  AwaitingSignature = 1,
  Signed = 2
}

export const disableCaseFileDocumentAction = createAction('CASE_FILE_DOCUMENT/DELETE')<string>();
const actions = createStandardActions(placeholder<CaseFileDocument>(), 'CASE_FILE_DOCUMENT/SET', 'CASE_FILE_DOCUMENT/SAVE');
const selectors = createStandardSelectors(placeholder<CaseFileDocument>(), s => getEntities(s).caseFileDocuments);
export type CaseFileDocumentActions = GetActions<typeof actions> | ReturnType<typeof disableCaseFileDocumentAction>;
export const caseFileDocuments = combineReducers({
  items: standardItemsReducer<CaseFileDocument, CaseFileDocumentActions>(actions)
    .handleAction(disableCaseFileDocumentAction, (state, action) => {
    const updatedState = {...state};
    delete updatedState[action.payload as any];
    return updatedState;
  })});
export const caseFileDocumentStore = readonly({
  selectors: {
    ...selectors
  },
  actions: {
    ...actions,
    upsert: (caseFile: CaseFileDocument) => async (dispatch: CommonDispatch) => {
      const response = await upsertCaseFileDocument(caseFile);
      dispatch(caseFileDocumentStore.actions.save(response));
      return response;
    },
    assignDocument: (userId: string, btcDocumentId: string) => async (dispatch: CommonDispatch) => {
      const response = await assignCaseFileDocument(userId, btcDocumentId);
      dispatch(caseFileDocumentStore.actions.save(response));
      return response;
    },
    disableCaseFileDocument: (caseFileDocumentId: string) => async (dispatch: CommonDispatch) => {
      await disableCaseFileDocument(caseFileDocumentId);
      dispatch(disableCaseFileDocumentAction(caseFileDocumentId));
    },
    merge: (userId: string, request: MergeCaseFileDocumentsRequest) => async (dispatch: CommonDispatch) => {
      const response = await mergeCaseFileDocuments(userId, request);
      dispatch(caseFileDocumentStore.actions.save(response));
      return response;
    },
    sendSigningEmail: (userId: string, caseFileDocumentId: string) => async (dispatch: CommonDispatch) => {
      const response = await sendSigningEmail(userId, caseFileDocumentId);
      dispatch(caseFileDocumentStore.actions.save(response));
      return response;
    },
    voidSigningEmail: (envelopeId: string) => async (dispatch: CommonDispatch) => {
      const response = await voidSigningEmail(envelopeId);
      dispatch(caseFileDocumentStore.actions.save(response));
      return response;
    },
    getDocumentDataById: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await getCaseFileDocumentDataById(id);
      dispatch(caseFileDocumentStore.actions.save(response));
      return response;
    }
  }
});
