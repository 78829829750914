import React from 'react';
import {Form} from 'react-bootstrap';
import IconButton from '../IconButton/IconButton';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

interface InputIconButtonProps {
  inputComponent: any;
  icon: IconProp;
  iconToolTip: string;
  onClick: () => void;
  hideButton?: boolean;
}

export const InputIconButton = (props: InputIconButtonProps) => (
  <Form.Group style={{display: 'flex', alignItems: 'center'}}>
    <div style={{flexShrink: 1, flexGrow: 1}}>
    {props.inputComponent}
    </div>
    {props.hideButton !== null && props.hideButton ?
      <IconButton
        icon={props.icon}
        size='2x'
        onClick={props.onClick}
        styles={{marginLeft: '5px'}}
      />
      : null}
  </Form.Group>
);
