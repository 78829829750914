import {mapIsStaffToProps, mapIsStaffToPropsType} from '../../../redux/web/entities/user';
import {connect} from 'react-redux';

type StaffRouteProps = mapIsStaffToPropsType & {
  children: JSX.Element;
};

// TODO once manager role is implemented, create prop for whether managers can access
function StaffComponent({children, staff}: StaffRouteProps) {
  return staff ? children : null;
}

export default connect(mapIsStaffToProps)(StaffComponent);
