import {Route, RouteProps, Navigate} from 'react-router';
import React from 'react';
import {
  mapIsNotParticipantToProps,
  mapIsNotParticipantToPropsType,
  mapIsAdministratorToProps,
  mapIsAdministratorToPropsType,
  mapIsAuthenticatedToProps,
  mapIsAuthenticatedToPropsType, mapIsShelterStaffToProps, mapIsShelterStaffToPropsType
} from '../../../redux/web/entities/user';
import {connect} from 'react-redux';
import { useLocation } from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';

type NotParticipantRouteProps = mapIsNotParticipantToPropsType & {
  children: JSX.Element;
};

// TODO once manager role is implemented, create prop for whether managers can access
function NotParticipantComponent({children, notParticipant}: NotParticipantRouteProps) {
  return notParticipant ? children : null;
}

export default connect(mapIsNotParticipantToProps)(NotParticipantComponent);
