import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {CommonDispatch} from '../index';
import {getCaseFileHistoryByUserId, upsertCaseFile} from '../../../api/caseFileManagementApi';
import {userStore} from './user';

export interface CaseFile {
  id: string;
  name: string;
  data: string;
  currentSnapshotId: number;
  userId: string;
}

const actions = createStandardActions(placeholder<CaseFile>(), 'CASE_FILE/SET', 'CASE_FILE/SAVE');
const selectors = createStandardSelectors(placeholder<CaseFile>(), s => getEntities(s).caseFiles);
export type CaseFileActions = GetActions<typeof actions>;
export const caseFiles = combineReducers({items: standardItemsReducer<CaseFile, CaseFileActions>(actions)});
export const caseFileStore = readonly({
  selectors: {
    ...selectors,
    getCaseFileIdByUserId: selector(s => (userId: string) => selectors.getAsArray(s).filter(c => c.userId === userId)[0].id),
    getCurrentUserCaseFile: selector(s => getCaseFileHistoryByUserId(userStore.selectors.getCurrentUser(s)?.id || ''))
  },
  actions: {
    ...actions,
    upsert: (caseFile: CaseFile) => async (dispatch: CommonDispatch) => {
      const response = await upsertCaseFile(caseFile);
      dispatch(caseFileStore.actions.save(response));
      return response;
    }
  }
});
