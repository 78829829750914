import React, {CSSProperties} from 'react';
import {Col, Form, Row} from 'react-bootstrap';

export type InputRowProps = React.PropsWithChildren & {
  label: string;
  columnSize?: number;
  labelSize?: number;
  labelStyle?: CSSProperties;
  hidden?: boolean;
  style?: CSSProperties;
  colStyle?: CSSProperties;
  hintText?: string;
  requiredAsterisk?: boolean;
};

export const InputRow = ({columnSize, labelSize, hidden, label, labelStyle, style, colStyle, hintText, children, requiredAsterisk}: InputRowProps) => {
  return (
    <Form.Group as={Row} hidden={hidden} style={style}>
      <Form.Label column={true} sm={labelSize || 2} style={labelStyle}>
        {label}
        {requiredAsterisk ? <span style={{color: '#ff0000'}}>*</span> : null}
        {!!hintText &&
          <Col sm={3} style={{paddingLeft: 0}}>
            <span style={{position: 'relative', top: '-10px', fontSize: '12px', color: 'gray', fontStyle: 'italic'}}>{hintText}</span>
          </Col>
        }
      </Form.Label>
      <Col sm={columnSize || 4} style={colStyle}>
        {children}
      </Col>
    </Form.Group>
  );
};
