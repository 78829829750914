import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../../utils';
import {createStandardSelectors, getEntities, selector} from '../../selectors';
import {combineReducers} from 'redux';
import {
  TNewParticipantChecklistForm
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/NewParticipantChecklistModal/NewParticipantChecklistModal';
import {CommonDispatch} from '../../index';
import {getNewParticipantChecklist, upsertNewParticipantChecklist} from '../../../../api/forms/formApi';

export enum ENewParticipantChecklistEducation {
  needsDiplomaOrGed,
  college,
  tradeSchool,
  other
}

export interface NewParticipantChecklist {
  id: string;
  userId: string;
  name: string;
  startDate: string;
  dateOfBirth: string;
  caId: boolean;
  ssCard: boolean;
  birthCertificate: boolean;
  mediCal: boolean;
  calFresh: boolean;
  probationOrParole: boolean;
  diplomaOrGed: boolean;
  cps: boolean;
  victimCompApp: boolean;
  outcomeOfInitialNeeds: string;
  education: ENewParticipantChecklistEducation;
  updatedOn: string;
}

const actions = createStandardActions(placeholder<NewParticipantChecklist>(), 'NEW_PARTICIPANT_CHECKLIST/SET', 'NEW_PARTICIPANT_CHECKLIST/SAVE');
const selectors = createStandardSelectors(placeholder<NewParticipantChecklist>(), s => getEntities(s).newParticipantChecklists);
export type NewParticipantChecklistActions = GetActions<typeof actions>;
export const newParticipantChecklists = combineReducers({items: standardItemsReducer<NewParticipantChecklist, NewParticipantChecklistActions>(actions)});
export const newParticipantChecklistStore = readonly({
  selectors: {
    ...selectors,
    getByUserId: selector(s => (userId: string) => selectors.getAsArray(s).find(checklist => checklist.userId === userId))
  },
  actions: {
    ...actions,
    upsert: (form: TNewParticipantChecklistForm) => async (dispatch: CommonDispatch) => {
      const response = await upsertNewParticipantChecklist(form);
      await dispatch(actions.save(response));
    },
    getByUserId: (userId: string) => async (dispatch: CommonDispatch) => {
      const response = await getNewParticipantChecklist(userId);
      if (response && response.id !== '')
        await dispatch(actions.save(response));
    }
  }
});
