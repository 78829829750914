import {object, string} from 'yup';
import {createAllObjectShapes} from '../../../../../../redux/util/yup';
import {TAdmissionCoverSheetForm} from './AdmissionCoverSheetModal';
import {makeAdmissionCoverSheet} from '../../../../../../redux/web/factory/forms/admissionCoverSheet';

// @ts-ignore
export const AdmissionCoverSheetModalSchema = object<TAdmissionCoverSheetForm>(createAllObjectShapes(makeAdmissionCoverSheet(''), undefined, {
  ['middleInitial']: string().nullable(),
  ['primaryPhone']: string().nullable(),
  ['secondaryPhone']: string().nullable()
}));
