import {Alert} from 'react-bootstrap';
import React from 'react';

interface Props {
  errorMessage: string;
}

export default function StandardErrorMessage(props: Props) {
  if (props.errorMessage !== '') {
    return (
      <div style={{marginTop: '1rem'}}>
        <Alert variant='danger'>{props.errorMessage}</Alert>
      </div>
    );
  }
  return null;
}
