import React, {CSSProperties, PropsWithChildren} from 'react';
import {Col, Row} from 'react-bootstrap';
import {InputRow, InputRowProps} from '../InputRow';
import {InputColumn, InputColumnProps} from '../InputColumn';
import CheckBoxInput, {CheckboxInputProps} from '../formik-inputs/CheckBoxInput/CheckBoxInput';

type StandardTopPaddedRowProps = {
  style?: CSSProperties;
} & PropsWithChildren;

export function StandardTopPaddedRow(props: StandardTopPaddedRowProps) {
  return (
    <Row style={{paddingTop: '10px', paddingBottom: '10px', ...props.style}}>
      {props.children}
    </Row>
  );
}

export function StandardParentInputRow(props: InputRowProps) {
  return (
    <Col style={props.style}>
      <Row style={{paddingTop: '10px'}}>
        <h3>{props.label}</h3>
      </Row>
      <Row>
        <Col style={{padding: '0', paddingBottom: '30px'}}>
          {props.children}
        </Col>
      </Row>
    </Col>
  );
}

export function StandardInputRow(props: InputRowProps) {
  return (
    <InputRow {...props} labelSize={props.labelSize ?? 2} columnSize={props.columnSize ?? 10} style={{paddingTop: '10px', ...props.style}}>
      {props.children}
    </InputRow>
  );
}

export function StandardInputColumn(props: InputColumnProps) {
  return (
    <InputColumn {...props} columnSize={props.columnSize ?? 12} style={{paddingLeft: '0', ...props.style}}>
      {props.children}
    </InputColumn>
  );
}

export function StandardCheckboxInput(props: CheckboxInputProps) {
  return (
    <CheckBoxInput {...props} style={{paddingTop: '5px', paddingBottom: '15px', ...props.style}}/>
  );
}

export function StandardRadioInput(props: CheckboxInputProps) {
  return (
    <CheckBoxInput
      singleRadioAllowed={true}
      type={'radio'}
      style={{paddingTop: '5px', paddingBottom: '15px', marginRight: '15px', ...props.style}}
      {...props}
    />
  );
}
