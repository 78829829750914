import React from 'react';
import {PrimaryIntakeAssessmentQuestions} from '../../../../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import Input from '../../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {Row} from 'react-bootstrap';
import {useFormikContext} from 'formik';
import {TPrimaryIntakeUpsertForm} from '../../PrimaryIntakeModal';
import {EPrimaryIntakeHelpType, EPrimaryIntakeHumanTraffickingType} from '../../../../../../../../redux/web/entities/forms/intake/primaryIntake/primaryIntakeHelperModels';
import {
  StandardParentInputRow ,
  StandardTopPaddedRow,
  StandardRadioInput,
  StandardInputColumn,
  StandardCheckboxInput
} from '../../../../../../../../components/util/form-components/standardLayout';

export type TAssessmentQuestionsForm = Omit<PrimaryIntakeAssessmentQuestions, 'id'> & {
  id?: string;
};

interface Props {
  fieldPrefix: string;
}

// TODO help type checkboxes
export const AssessmentQuestionsForm = (props: Props) => {
  const {values, setFieldValue} = useFormikContext<TPrimaryIntakeUpsertForm>();
  const assessValues = values.assessmentQuestions;
  const getFieldName = (name: keyof TAssessmentQuestionsForm) => `${props.fieldPrefix}.${name}`;
  return (
    <>
      <StandardParentInputRow label={'Help Description'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'What kind of help are you seeking?'} columnSize={12}>
            <Input name={getFieldName('helpSeeking')} type={'text'}/>
            <StandardTopPaddedRow>
              <StandardCheckboxInput label={'Housing'} name={getFieldName('seekingHousing')} style={{marginRight: '2rem'}}/>
              <StandardCheckboxInput label={'Food'} name={getFieldName('seekingFood')} style={{marginRight: '2rem'}}/>
              <StandardCheckboxInput label={'Clothing'} name={getFieldName('seekingClothing')} style={{marginRight: '2rem'}}/>
              <StandardCheckboxInput label={'Counseling'} name={getFieldName('seekingCounseling')} style={{marginRight: '2rem'}}/>
              <StandardCheckboxInput label={'Relocation'} name={getFieldName('seekingRelocation')} style={{marginRight: '2rem'}}/>
              <StandardCheckboxInput label={'Legal Advice'} name={getFieldName('seekingLegalAdvice')} style={{marginRight: '2rem'}}/>
              <StandardCheckboxInput label={'Financial Assistance'} name={getFieldName('seekingFinancialAssistance')} style={{marginRight: '2rem'}}/>
              <StandardCheckboxInput label={'Other'} name={getFieldName('seekingOtherHelp')} style={{marginRight: '2rem'}}/>
            </StandardTopPaddedRow>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Reason for Help'} columnSize={6}>
            <Input name={getFieldName('helpReason')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If other, please explain:'} columnSize={6}>
            <Input name={getFieldName('helpOther')} disabled={!assessValues.seekingOtherHelp} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Resources'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Income'} columnSize={4}>
            <Input name={getFieldName('income')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Other Resources'} columnSize={8}>
            <Input name={getFieldName('otherResources')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Insurance'} columnSize={2}>
            <StandardCheckboxInput name={getFieldName('insurance')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Insurance Types'} columnSize={7}>
            <Input name={getFieldName('type')} disabled={!assessValues.insurance} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Medical ID #'} columnSize={3}>
            <Input name={getFieldName('medicalIdNumber')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Vehicle'} columnSize={2}>
            <StandardCheckboxInput name={getFieldName('vehicle')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Make'} columnSize={3}>
            <Input name={getFieldName('make')} disabled={!assessValues.vehicle} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Model'} columnSize={3}>
            <Input name={getFieldName('model')} disabled={!assessValues.vehicle} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'License Plate #'} columnSize={4}>
            <Input name={getFieldName('licensePlateNumber')} disabled={!assessValues.vehicle} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Health History'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you have any disabilities?'}>
            <Row>
              <StandardCheckboxInput label={'Physical'} name={getFieldName('physicalDisability')} style={{marginRight: '2rem'}}/>
              <StandardCheckboxInput label={'Developmental'} name={getFieldName('developmentDisability')} style={{marginRight: '2rem'}}/>
              <StandardCheckboxInput label={'Learning'} name={getFieldName('learningDisability')} style={{marginRight: '2rem'}}/>
              <StandardCheckboxInput label={'Hearing'} name={getFieldName('hearingDisability')} style={{marginRight: '2rem'}}/>
              <StandardCheckboxInput label={'Vision'} name={getFieldName('visionDisability')} style={{marginRight: '2rem'}}/>
              <StandardCheckboxInput label={'Other'} name={getFieldName('otherDisability')} style={{marginRight: '2rem'}}/>
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If other, please explain:'}>
            <Input name={getFieldName('otherDisabilityDescription')} disabled={!assessValues.otherDisability} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you or your child have any diagnosed mental or behavioral health issues?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('mentalHealthIssues')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Who?'} columnSize={8}>
            <Input name={getFieldName('mentalHealthIssuesNames')} disabled={!assessValues.mentalHealthIssues} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If yes, please explain'}>
            <Input name={getFieldName('mentalHealthIssuesDescription')} type={'textarea'} disabled={!assessValues.mentalHealthIssues} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Are you taking any medications?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('medications')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Name and Reason'} columnSize={9}>
            <Input name={getFieldName('medicationNamesAndDescriptions')} type={'textarea'} disabled={!assessValues.medications} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you have any existing medical conditions?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('existingMedicalConditions')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Medical Condition Descriptions'} columnSize={9}>
            <Input
              name={getFieldName('existingMedicalConditionsDescriptions')}
              type={'textarea'}
              disabled={!assessValues.existingMedicalConditions}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever been hospitalized?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('hospitalization')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Reason'} columnSize={9}>
            <Input name={getFieldName('hospitalizationDescription')} type={'textarea'} disabled={!assessValues.hospitalization} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever been placed on a psychiatric hold?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('psychiatricHold')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'When, Where, and Why?'} columnSize={9}>
            <Input name={getFieldName('psychiatricHoldDescription')} type={'textarea'} disabled={!assessValues.psychiatricHold} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever been placed on a 51/50 hold?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('fiftyOneFiftyHold')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'When, Where, and Why?'} columnSize={9}>
            <Input name={getFieldName('fiftyOneFiftyHoldDescription')} type={'textarea'} disabled={!assessValues.fiftyOneFiftyHold} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you hear voices?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('hearsVoices')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Do you have depression?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('depression')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Please explain'} columnSize={6}>
            <Input name={getFieldName('voicesOrDepressionExplanation')} type={'textarea'}
              disabled={!(assessValues.hearsVoices || assessValues.depression)} valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever been in a shelter, or any type of residential treatment program?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('residentialTreatmentProgram')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Where?'} columnSize={9}>
            <Input name={getFieldName('residentialTreatmentProgramDescription')} type={'textarea'}
                   disabled={!assessValues.residentialTreatmentProgram} valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever been in any type of outpatient treatment program?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('outpatientTreatmentProgram')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Where?'} columnSize={9}>
            <Input name={getFieldName('outpatientTreatmentProgramDescription')} type={'textarea'}
                   disabled={!assessValues.outpatientTreatmentProgram} valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Crime and Drug History'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever been convicted of a crime?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('crimeOccurrence')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Please describe'} columnSize={9}>
            <Input name={getFieldName('crimeOccurrenceDescription')} type={'textarea'} disabled={!assessValues.crimeOccurrence} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Are you on probation or parole?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('onProbationOrParole')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'What for?'} columnSize={9}>
            <Input name={getFieldName('probationOrParoleDescription')} type={'textarea'}
                   disabled={!assessValues.onProbationOrParole} valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you have any pending legal matters?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('pendingLegalMatters')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'What for?'} columnSize={9}>
            <Input name={getFieldName('pendingLegalMattersDescription')} type={'textarea'}
                   disabled={!assessValues.pendingLegalMatters} valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Date(s)'} columnSize={6}>
            <Input name={getFieldName('pendingLegalMattersDates')} disabled={!assessValues.pendingLegalMatters} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
          <StandardInputColumn label={`Officer's Name`} columnSize={6}>
            <Input name={getFieldName('officerName')} disabled={!assessValues.pendingLegalMatters} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'County?'} columnSize={6}>
            <Input name={getFieldName('county')} disabled={!assessValues.pendingLegalMatters} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
          <StandardInputColumn label={`Do you have any CPS or custodial cases?`} columnSize={6}>
            <Input name={getFieldName('cpsOrCustodialCases')} type={'textarea'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you smoke?'} columnSize={2}>
            <StandardCheckboxInput
              name={getFieldName('smokes')}
              label={'Yes'}
              onChange={(val: boolean) => {
                setFieldValue(getFieldName('smokes'), val);
                if (!val) {
                  setFieldValue(getFieldName('willingToQuit'), val);
                }
              }}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'How long?'} columnSize={6} style={{marginRight: 'auto'}}>
            <Input name={getFieldName('smokingLength')} type={'textarea'} disabled={!assessValues.smokes} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Are you willing to quit?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('willingToQuit')} label={'Yes'} disabled={!assessValues.smokes}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Drugs used'} columnSize={6}>
            <Input name={getFieldName('drugsUsed')} type={'text'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Sobriety Date'} columnSize={6}>
            <Input name={getFieldName('sobrietyDate')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you have any questions?'} columnSize={12}>
            <Input name={getFieldName('questions')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
    </>
  );
};
