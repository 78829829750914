import {Button, Col, Modal} from 'react-bootstrap';
import React from 'react';
import {StandardTopPaddedRow} from '../../../../../../../components/util/form-components/standardLayout';
import PaddedRowsListFormatter
  from '../../../../../../../components/util/lists/PaddedRowsListFormatter/PaddedRowsListFormatter';

interface Props {
  show: boolean;
  onSubmit: () => void;
}

export function PrimaryIntakeInformationModal(props: Props) {
  const {show, onSubmit} = props;
  return (
    <Modal show={show} centered={true} size={'xl'} style={{zIndex: '1100'}}>
      <Modal.Body>
        <Modal.Title>Initial Assessment Guidelines</Modal.Title>
        <Col>
          <StandardTopPaddedRow>
            <h6>BTC's four main client based programs:</h6>
          </StandardTopPaddedRow>
          <PaddedRowsListFormatter type={'unordered'}>
            <div>
              <span style={{fontWeight: 'bold'}}>Non-Residential</span> - This is an opportunity to live in an independent housing of the client’s
              choosing and still receive comprehensive case management and counseling services.
            </div>
            <div>
              <span style={{fontWeight: 'bold'}}>ER Shelter Services</span> - ER case management & relocation shelter for 0-14 days
            </div>
            <div>
              <span style={{fontWeight: 'bold'}}>Residential</span> - For clients that would like a comprehensive residential trauma treatment
              program. This is not a shelter, but rather a treatment facility.
              <Col>
                <StandardTopPaddedRow>
                  Note: in order to qualify for a residential program, they must agree to the
                  following:
                </StandardTopPaddedRow>
                <Col>
                  <PaddedRowsListFormatter type={'ordered'} orderedFormat={'i'}>
                    <div>
                      <span style={{textDecoration: 'underline'}}>30 day protection period:</span> they will not be able to communicate with
                      anyone outside of BTC for the first 30 days! (special circumstances, such
                      as children or family, may be allowed only if approved by executive team)
                    </div>
                    <div>
                      <span style={{textDecoration: 'underline'}}>No cell phones</span> allowed on campus!
                    </div>
                    <div>
                      <span style={{textDecoration: 'underline'}}>Non-smoking</span> campus! However, patches and gum are provided.
                    </div>
                    <div>
                      <span style={{textDecoration: 'underline'}}>Zero tolerance</span> policy for drugs and alcohol!
                    </div>
                    <div>
                      Program duration is a <span style={{textDecoration: 'underline'}}>minimum</span> of 9 months
                      and a <span style={{textDecoration: 'underline'}}>maximum</span> of 2 years.
                    </div>
                    <div>
                      <span style={{textDecoration: 'underline'}}>No intimate relationship</span> policy!
                    </div>
                  </PaddedRowsListFormatter>
                </Col>
                <StandardTopPaddedRow>
                  Let them know that we have their best interests at heart, and we want to help
                  them rebuild their life.
                </StandardTopPaddedRow>
              </Col>
            </div>
            <div>
              <span style={{fontWeight: 'bold'}}>Diversion</span> - for clients that are court or probation/ parole ordered or to receive BTC services.
            </div>
            <div>
              <span style={{fontWeight: 'bold'}}>Juvenile Services (CSEC)</span>- This program serves youth residing in the County of Fresno who
              have come to the attention of/or is in the care of Child Welfare Services. This program will
              target youth that have been identified as Commercially Sexually Exploited Children (CSEC)
              and those who are at risk of CSEC. Those under the age of 21 may be dependents of the
              juvenile court, non-minor dependents (ages 18-21), or non-dependent youth.
            </div>
          </PaddedRowsListFormatter>
          <StandardTopPaddedRow>
            Please make sure that you do the following:
          </StandardTopPaddedRow>
          <PaddedRowsListFormatter type={'ordered'}>
            <div>
              Speak <span style={{fontWeight: 'bold'}}>slowly, clearly, and repeat</span> all information given for accuracy. It is very important
              that the information obtained is correct, especially contact information.
            </div>
            <div>
              <span style={{fontWeight: 'bold'}}>Introduce yourself and BTC</span>, and have them introduce themselves. Let them know the
              assessment will take a minimum of 30 minutes and make sure they are in a safe and
              private place where they are able to disclose information.
            </div>
            <div>
              <span style={{fontWeight: 'bold'}}>Go over the 4 programs</span> as described above.
            </div>
            <div>
              <span style={{fontWeight: 'bold'}}>Fill out the entire assessment</span>, even if they did not feel comfortable answering some of
              the questions. Write “declined to answer” or N/A.
            </div>
            <div>
              Be <span style={{fontWeight: 'bold'}}>KIND</span> and <span style={{fontWeight: 'bold'}}>ATTENTIVE</span>.
              Put yourself in their shoes, how would you want to be treated in this situation?
            </div>
          </PaddedRowsListFormatter>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{marginLeft: 'auto', marginRight: '10px', borderRadius: '10px', minWidth: '80px'}} variant={'success'} onClick={onSubmit}>
          I Understand
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
