import React, {FormEvent, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import styles from './DocumentModal.module.scss';
import {ErrorMessage, Form, Formik, FormikErrors} from 'formik';
import {Alert, Button, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {WebState} from '../../../../../redux/core/types/WebState';
import {DocumentModalSchema} from './DocumentModalSchema';
import {BtcDocument, btcDocumentStore} from '../../../../../redux/web/entities/btcDocument';
import {roleStore} from '../../../../../redux/web/entities/role';
import {convertToDropDownOptions} from '../../../../../redux/util';
import {makeBtcDocument} from '../../../../../redux/web/factory';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../../../redux/util/http';
import {bindActionCreators, Dispatch} from 'redux';
import {getBase64} from '../../../../../util';
import {HistoryViewer} from '../../../../../components/util/HistoryViewer/HistoryViewer';
import {getDocumentHistoryById} from '../../../../../api/documentManagementApi';

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
  editable: boolean;
  onTop?: boolean;
  existingBtcDocument?: BtcDocument;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function DocumentModal(props: Props) {
  const {onTop, onSubmit, editable, onCancel, rolesOptions, existingBtcDocument, actions: {upsertBtcDocument}} = props;
  const getFieldName = (name: keyof BtcDocument) => name;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [documentFileData, setDocumentFileData] = useState('');
  const [documentFileError, setDocumentFileError] = useState('');

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: BtcDocument,
    validate: (values: BtcDocument) => Promise<FormikErrors<BtcDocument>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    setDocumentFileError('');
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if(association.id === undefined && association.data !== '') {
      setDocumentFileError('You must select a file');
    }
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
    } else {
      try {
        association.data = documentFileData;
        await upsertBtcDocument(association);
        onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(handleAxiosError(e));
      }
    }
    setIsSubmitting(false);
  };


  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={editable ? 'danger' : 'info'} className={styles['close-button']}>
            {editable ? 'Cancel' : 'Close'}
          </Button>
        }
        {!isSubmitting ? editable && <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };


  return (
    <Modal backdropClassName={onTop ? styles['on-top'] : ''} show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Formik initialValues={existingBtcDocument !== null ? existingBtcDocument as BtcDocument : makeBtcDocument(null)}
                onSubmit={() => undefined} validationSchema={DocumentModalSchema}>
          {({values, validateForm, handleSubmit, errors}) => {
            return (
              <Form noValidate={false}
                    onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Modal.Title>{existingBtcDocument?.id !== '' ? 'Update Document' : 'Add Document'}</Modal.Title>
                <InputRow label={'Name'} columnSize={5} labelSize={2} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Input name={getFieldName('name')}/>
                </InputRow>
                <InputRow label={'Upload file'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={'file'} type={'file'} setFormDataState={async (file: File) => {
                    if(file)
                      setDocumentFileData(await getBase64(file));
                    else
                      setDocumentFileData('');
                  }}/>
                  <div className={styles['form-errors']}>{documentFileError}</div>
                </InputRow>
                {errorMessage !== '' ?
                  <div style={{marginTop: '1rem'}}>
                    <Alert variant='danger'>{errorMessage}</Alert>
                  </div>
                  : null}
                <Row>
                  <BSForm.Group className={styles['form-buttons']}>
                    {renderButtons()}
                  </BSForm.Group>
                </Row>
              </Form>
            );
          }}
        </Formik>
        {existingBtcDocument?.id ?
          <HistoryViewer apiHistoryFunc={getDocumentHistoryById(existingBtcDocument?.id!)} label={'History'}/> : null
        }
      </Modal.Body>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertBtcDocument: btcDocumentStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  rolesOptions: convertToDropDownOptions(roleStore.selectors.getAsArray(state), 'roleName')
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentModal);
