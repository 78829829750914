import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';

export interface Role {
  id: string;
  roleName: string;
}

const actions = createStandardActions(placeholder<Role>(), 'ROLE/SET', 'ROLE/SAVE');
const selectors = createStandardSelectors(placeholder<Role>(), s => getEntities(s).roles);
export type RoleActions = GetActions<typeof actions>;
export const roles = combineReducers({items: standardItemsReducer<Role, RoleActions>(actions)});
export const roleStore = readonly({
  selectors: {
    ...selectors,
    administratorRole: selector(s =>  selectors.getAsArray(s).find(r => r.roleName === 'Administrator')),
    getRoleByName: selector(s => (name: string) => selectors.getAsArray(s).find(role => role.roleName === name))
  },
  actions: {
    ...actions
  }
});
