import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import PageHeader from '../../components/layout/PageHeader/PageHeader';
import styles from './Profile.module.scss';
import {Container, Row, Col, Card} from 'react-bootstrap';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {RoutePaths} from '../../router/RoutePaths';
import {useStandardEditor} from '../../components/util/form-components/EditorForm/hooks';
import {InputColumn} from '../../components/util/form-components/InputColumn';
import Input from '../../components/util/form-components/formik-inputs/Input/Input';
import {EditorForm} from '../../components/util/form-components/EditorForm/EditorForm';
import {AppTheme} from '../../appTheme';
import {UserPreferences, userStore} from '../../redux/web/entities/user';
import {makeUser, makeUserPreferences} from '../../redux/web/factory';
import {WebState} from '../../redux/core/types/WebState';
import ProfilePicture, {UpdateProfilePictureRequest} from './components/ProfilePicture';
import {updateProfilePicture} from '../../api/userManagementApi';
import DatepickerInput from '../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import AdministratorComponent from '../../components/util/AdministratorComponent/AdministratorComponent';
import {StandardInputColumn} from '../../components/util/form-components/standardLayout';
import PhoneInputField from '../../components/util/form-components/formik-inputs/PhoneNumberInput/PhoneNumberInput';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function Profile(props: Props) {
  const {currentUser, actions: {updateUserPreferences, updateProfilePictureAction}} = props;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const getFieldName = (name: keyof UserPreferences) => name;
  const standardEditor = useStandardEditor<UserPreferences>(
    'Profile',
    makeUser(),
    props,
    s => ({
      getEditUrl: () => RoutePaths.profilePaths.Edit,
      getCancelUrl: () => RoutePaths.profilePaths.View,
      onLoadForm: async () => makeUserPreferences(currentUser!),
      onNewForm: async () => makeUserPreferences(currentUser!),
      saveAndRedirect: async (form) => {
        await props.actions.updateUserPreferences(form);
        return RoutePaths.profilePaths.View;
      },
      footer: true,
      editButtonText: 'Edit Profile',
      saveButtonText: 'Save Profile',
      submitButtonColor: AppTheme.colors.oceanBlue
    }));
  const {editable} = standardEditor;

  const renderContent = () => {
    return (
      <Row style={{flexGrow: 1}}>
        <Col>
          <Card className={styles['page-body']} style={{borderLeftWidth: 0, borderRightWidth: 0}}>
            <Card.Body style={{display: 'flex', justifyContent: 'center'}}>
              <Col xl={5}>
                <Row style={{justifyContent: 'center'}}>
                  <ProfilePicture userId={currentUser?.id ?? ''} profilePicturePath={currentUser?.profilePicturePath ?? ''}
                                  action={updateProfilePictureAction}/>
                </Row>
                <EditorForm standardEditor={standardEditor}>
                  {({values, setFieldValue}) => {
                    return (
                      <Row>
                        <Col style={{padding: '0'}}>
                        <StandardInputColumn label='Primary Phone'>
                          <PhoneInputField name={getFieldName('primaryPhone')} disabled={!editable}/>
                        </StandardInputColumn>
                        <StandardInputColumn label='Secondary Phone'>
                          <PhoneInputField name={getFieldName('secondaryPhone')} disabled={!editable}/>
                        </StandardInputColumn>
                        <StandardInputColumn label='Address'>
                          <Input name={getFieldName('address')} disabled={!editable}/>
                        </StandardInputColumn>
                        <StandardInputColumn label='Address Line Two'>
                          <Input name={getFieldName('addressLineTwo')} disabled={!editable}/>
                        </StandardInputColumn>
                        </Col>
                        <Col>
                        <StandardInputColumn label='City'>
                          <Input name={getFieldName('city')} disabled={!editable}/>
                        </StandardInputColumn>
                        <StandardInputColumn label='State'>
                          <Input name={getFieldName('state')} disabled={!editable}/>
                        </StandardInputColumn>
                        <StandardInputColumn label='Zip Code'>
                          <Input name={getFieldName('zip')} disabled={!editable}/>
                        </StandardInputColumn>
                        <StandardInputColumn label='Birth Date'>
                          <DatepickerInput
                            name={getFieldName('birthDate')}
                            disabled={!editable}
                            showYearDropdown={true}
                            onChangeUtcHour={12}
                          />
                        </StandardInputColumn>
                        </Col>
                      </Row>
                    );
                  }}
                </EditorForm>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <PageHeader label={'Profile'} removeMargin={true}>
      <Container fluid={true} className={styles['config-container']}>
        {loading ?  <CenteredSpinner/> : (
          errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
            renderContent()
        )}
      </Container>
    </PageHeader>
  );
}

const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    updateUserPreferences: userStore.actions.updateUserPreferences,
    updateProfilePictureAction: userStore.actions.updateProfilePicture
  }, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
