import {PrimaryIntake} from '../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {WeeklyCaseManagementNotes} from '../../../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import {NewParticipantChecklist} from '../../../../redux/web/entities/forms/newParticipantChecklist';
import {AdmissionCoverSheet} from '../../../../redux/web/entities/forms/admissionCoverSheet';
import {GoalSheet} from '../../../../redux/web/entities/forms/goalSheet';
import {SecondaryIntake} from '../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';

export type PreMadeFormModalType = 'none' | 'primaryIntake' | 'secondaryIntake' |
  'caseNotes' | 'admissionCoverSheet' | 'goalSheet' | 'newParticipantChecklist';

export interface PreMadeFormTableData {
  id: string;
  name: string;
  formType: PreMadeFormModalType;
  updatedOn: string;
}

export function makePreMadeFormTableData(): PreMadeFormTableData {
  return {
    id: '',
    name: '',
    formType: 'none',
    updatedOn: ''
  };
}

export function primaryIntakeTableData(intake: PrimaryIntake): PreMadeFormTableData {
  return {
    id: intake?.id ?? 'primaryIntake',
    name: 'Primary Intake',
    formType: 'primaryIntake',
    updatedOn: intake?.updatedOn ?? ''
  };
}

export function secondaryIntakeTableData(intake: SecondaryIntake): PreMadeFormTableData {
  return {
    id: intake.id ?? 'secondaryIntake',
    name: 'Secondary Intake',
    formType: 'secondaryIntake',
    updatedOn: intake?.updatedOn ?? ''
  };
}

export function weeklyCaseNotesTableData(caseNotes: WeeklyCaseManagementNotes): PreMadeFormTableData {
  return {
    id: caseNotes?.id ?? 'caseNotes',
    name: 'Weekly Case Management Notes',
    formType: 'caseNotes',
    updatedOn: caseNotes?.updatedOn ?? ''
  };
}

export function newParticipantChecklistTableData(checklist: NewParticipantChecklist): PreMadeFormTableData {
  return {
    id: checklist?.id ?? 'checklist',
    name: 'New Participant Checklist',
    formType: 'newParticipantChecklist',
    updatedOn: checklist?.updatedOn ?? ''
  };
}

export function admissionCoverSheetTableData(coverSheet: AdmissionCoverSheet): PreMadeFormTableData {
  return {
    id: coverSheet?.id ?? 'coverSheet',
    name: 'Admission Cover Sheet',
    formType: 'admissionCoverSheet',
    updatedOn: coverSheet?.updatedOn ?? ''
  };
}

export function goalSheetTableData(goalSheet: GoalSheet): PreMadeFormTableData {
  return {
    id: goalSheet?.id ?? 'goalSheet',
    name: 'Goal Sheet',
    formType: 'goalSheet',
    updatedOn: goalSheet?.updatedOn ?? ''
  };
}
