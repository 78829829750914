import React, {FormEvent, useState} from 'react';
import {connect} from 'react-redux';
import styles from '../CaseFile.module.scss';
import {ErrorMessage, Form, Formik, FormikErrors} from 'formik';
import {Alert, Button, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {AxiosError} from 'axios';

import {bindActionCreators, Dispatch} from 'redux';
import {caseFileDocumentStore} from '../../../redux/web/entities/caseFileDocument';
import {WebState} from '../../../redux/core/types/WebState';
import {roleStore} from '../../../redux/web/entities/role';
import {convertToDropDownOptions} from '../../../redux/util';
import {handleAxiosError} from '../../../redux/util/http';
import {btcDocumentStore} from '../../../redux/web/entities/btcDocument';
import {DropdownInput} from '../../../components/util/form-components/formik-inputs/DropdownInput/DropdownInput';
import {StandardInputRow} from '../../../components/util/form-components/standardLayout';
import {useLocation} from 'react-router-dom';
import {CaseFileDocumentAssignmentModalSchema} from './CaseFileDocumentAssignmentModalSchema';


export interface AssignDocumentProps {
  btcDocumentId: string;
}

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
  editable: boolean;
  onTop?: boolean;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function CaseFileDocumentAssignmentModal(props: Props) {
  const {onTop, onSubmit, editable, onCancel, rolesOptions, documentOptions,
    actions: {assignDocument}} = props;
  const getFieldName = (name: keyof AssignDocumentProps) => name;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: AssignDocumentProps,
    validate: (values: AssignDocumentProps) => Promise<FormikErrors<AssignDocumentProps>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');

    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
    } else {
      try {
        await assignDocument(location.pathname.split('/')[2], association.btcDocumentId);
        onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(handleAxiosError(e));
      }
    }
    setIsSubmitting(false);
  };


  const renderButtons = (values: any, validateForm: any, handleSubmit: any) => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={editable ? 'danger' : 'info'} className={styles['close-button']}>
            {editable ? 'Cancel' : 'Close'}
          </Button>
        }
        {!isSubmitting ? editable && <Button variant={'success'} type='button'
                                             onClick={(e: any) => processSubmit(e, values, validateForm, handleSubmit)}>Assign</Button> : null}
      </>
    );
  };


  return (
    <Modal backdropClassName={onTop ? styles['on-top'] : ''} show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Formik initialValues={{btcDocumentId: ''}}
                onSubmit={() => undefined} validationSchema={CaseFileDocumentAssignmentModalSchema}>
          {({values, validateForm, handleSubmit, errors}) => {
            return (
              <Form noValidate={false} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Modal.Title>Assign Document</Modal.Title>
                <StandardInputRow label={'Document'} columnSize={6}>
                  <DropdownInput name={getFieldName('btcDocumentId')} dropdownData={documentOptions}/>
                </StandardInputRow>
                {errorMessage !== '' ?
                  <div style={{marginTop: '1rem'}}>
                    <Alert variant='danger'>{errorMessage}</Alert>
                  </div>
                  : null}
                <Row>
                  <BSForm.Group className={styles['form-buttons']}>
                    {renderButtons(values, validateForm, handleSubmit)}
                  </BSForm.Group>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    assignDocument: caseFileDocumentStore.actions.assignDocument
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  rolesOptions: convertToDropDownOptions(roleStore.selectors.getAsArray(state), 'roleName'),
  documentOptions: convertToDropDownOptions(btcDocumentStore.selectors.getAsArray(state), 'name')
});
export default connect(mapStateToProps, mapDispatchToProps)(CaseFileDocumentAssignmentModal);
