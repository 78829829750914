import React, {useState} from 'react';
import {
  PrimaryIntakePersonalInformation
} from '../../../../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import Input from '../../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {
  StandardParentInputRow ,
  StandardTopPaddedRow,
  StandardRadioInput,
  StandardInputColumn
} from '../../../../../../../../components/util/form-components/standardLayout';
import {
  EPrimaryIntakeEthnicity,
  EPrimaryIntakeGenderIdentification, EPrimaryIntakeHelpType, EPrimaryIntakeLanguage
} from '../../../../../../../../redux/web/entities/forms/intake/primaryIntake/primaryIntakeHelperModels';
import {useFormikContext} from 'formik';
import {TPrimaryIntakeUpsertForm} from '../../PrimaryIntakeModal';
import {Row} from 'react-bootstrap';
import DatepickerInput
  from '../../../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {
  DeletableFieldArray
} from '../../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/DeletableFieldArray';
import {
  FieldArrayRow
} from '../../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/FieldArrayRow';
import {makeChildInformation} from '../../../../../../../../redux/web/factory/forms/intakeForms';
import PhoneInputField
  from '../../../../../../../../components/util/form-components/formik-inputs/PhoneNumberInput/PhoneNumberInput';

export type TPersonalInformationForm = Omit<PrimaryIntakePersonalInformation, 'id'> & {
  id?: string;
};

interface Props {
  fieldPrefix: string;
}

// TODO add ethnicity
export const PersonalInformationForm = (props: Props) => {
  const {values} = useFormikContext<TPrimaryIntakeUpsertForm>();
  const infoValues = values.personalInformation;
  const [currentGenderIdentification, setCurrentGenderIdentification] = useState<EPrimaryIntakeGenderIdentification>();
  const getFieldName = (name: keyof TPersonalInformationForm) => `${props.fieldPrefix}.${name}`;
  return (
    <>
      <StandardParentInputRow label={'Full Name'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'First Name'} columnSize={4}>
            <Input name={getFieldName('firstName')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Last Name'} columnSize={5}>
            <Input name={getFieldName('lastName')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Middle Initial (can be blank)'} columnSize={3}>
            <Input name={getFieldName('middleInitial')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Address'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Street Address'} columnSize={8}>
            <Input name={getFieldName('streetAddress')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Apartment / Unit #'} columnSize={4}>
            <Input name={getFieldName('apartmentOrUnit')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'City'} columnSize={5}>
            <Input name={getFieldName('city')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'State'} columnSize={3}>
            <Input name={getFieldName('state')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'ZIP'} columnSize={4}>
            <Input name={getFieldName('zipCode')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Contact'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Primary Phone (can be blank)'} columnSize={6}>
            <PhoneInputField name={getFieldName('primaryPhone')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Secondary Phone (can be blank)'} columnSize={6}>
            <PhoneInputField name={getFieldName('secondaryPhone')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Email'} columnSize={8}>
            <Input name={getFieldName('email')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Demographics'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Birth Date'} columnSize={4}>
            <DatepickerInput name={getFieldName('birthDate')} onChangeUtcHour={12} showYearDropdown={true}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Marital Status'} columnSize={8}>
            <Input name={getFieldName('maritalStatus')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'I Identify As'}>
            <Row>
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                singleRadioAllowed={true}
                label={'Female'}
                value={EPrimaryIntakeGenderIdentification.female}
                checked={infoValues.identifyAs === EPrimaryIntakeGenderIdentification.female}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Male'}
                singleRadioAllowed={true}
                value={EPrimaryIntakeGenderIdentification.male}
                checked={infoValues.identifyAs === EPrimaryIntakeGenderIdentification.male}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Transgender Male to Female'}
                singleRadioAllowed={true}
                value={EPrimaryIntakeGenderIdentification.transgenderMaleToFemale}
                checked={infoValues.identifyAs === EPrimaryIntakeGenderIdentification.transgenderMaleToFemale}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Transgender Female to Male'}
                value={EPrimaryIntakeGenderIdentification.transgenderFemaleToMale}
                checked={infoValues.identifyAs === EPrimaryIntakeGenderIdentification.transgenderFemaleToMale}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Gender Variant / Non-Conforming'}
                value={EPrimaryIntakeGenderIdentification.genderVariantNonConforming}
                checked={infoValues.identifyAs === EPrimaryIntakeGenderIdentification.genderVariantNonConforming}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Prefer not to answer'}
                value={EPrimaryIntakeGenderIdentification.preferNotToAnswer}
                checked={infoValues.identifyAs === EPrimaryIntakeGenderIdentification.preferNotToAnswer}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Not Listed'}
                value={EPrimaryIntakeGenderIdentification.notListed}
                checked={infoValues.identifyAs === EPrimaryIntakeGenderIdentification.notListed}
              />
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Ethnicity'}>
            <Row>
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'Asian'}
                value={EPrimaryIntakeEthnicity.asian}
                checked={infoValues.ethnicity === EPrimaryIntakeEthnicity.asian}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'American Indian or Alaska Native'}
                value={EPrimaryIntakeEthnicity.americanIndianOrAlaskaNative}
                checked={infoValues.ethnicity === EPrimaryIntakeEthnicity.americanIndianOrAlaskaNative}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'Black or African American'}
                value={EPrimaryIntakeEthnicity.blackOrAfricanAmerican}
                checked={infoValues.ethnicity === EPrimaryIntakeEthnicity.blackOrAfricanAmerican}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'Other'}
                value={EPrimaryIntakeEthnicity.other}
                checked={infoValues.ethnicity === EPrimaryIntakeEthnicity.other}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'Hispanic or Latino'}
                value={EPrimaryIntakeEthnicity.hispanicOrLatino}
                checked={infoValues.ethnicity === EPrimaryIntakeEthnicity.hispanicOrLatino}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'Native Hawaiian or Other Pacific Islander'}
                value={EPrimaryIntakeEthnicity.nativeHawaiianOrOtherPacificIslander}
                checked={infoValues.ethnicity === EPrimaryIntakeEthnicity.nativeHawaiianOrOtherPacificIslander}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'White or Caucasian'}
                value={EPrimaryIntakeEthnicity.whiteOrCaucasian}
                checked={infoValues.ethnicity === EPrimaryIntakeEthnicity.whiteOrCaucasian}
              />
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Primary Language'}>
            <Row>
              <StandardRadioInput
                name={getFieldName('primaryLanguage')}
                type={'radio'}
                label={'English'}
                value={EPrimaryIntakeLanguage.english}
                checked={infoValues.primaryLanguage === EPrimaryIntakeLanguage.english}
              />
              <StandardRadioInput
                name={getFieldName('primaryLanguage')}
                type={'radio'}
                label={'Spanish'}
                value={EPrimaryIntakeLanguage.spanish}
                checked={infoValues.primaryLanguage === EPrimaryIntakeLanguage.spanish}
              />
              <StandardRadioInput
                name={getFieldName('primaryLanguage')}
                type={'radio'}
                label={'American Indian'}
                value={EPrimaryIntakeLanguage.americanIndian}
                checked={infoValues.primaryLanguage === EPrimaryIntakeLanguage.americanIndian}
              />
              <StandardRadioInput
                name={getFieldName('primaryLanguage')}
                type={'radio'}
                label={'Hindu'}
                value={EPrimaryIntakeLanguage.hindu}
                checked={infoValues.primaryLanguage === EPrimaryIntakeLanguage.hindu}
              />
              <StandardRadioInput
                name={getFieldName('primaryLanguage')}
                type={'radio'}
                label={'Korean'}
                value={EPrimaryIntakeLanguage.korean}
                checked={infoValues.primaryLanguage === EPrimaryIntakeLanguage.korean}
              />
              <StandardRadioInput
                name={getFieldName('primaryLanguage')}
                type={'radio'}
                label={'Japanese'}
                value={EPrimaryIntakeLanguage.japanese}
                checked={infoValues.primaryLanguage === EPrimaryIntakeLanguage.japanese}
              />
              <StandardRadioInput
                name={getFieldName('primaryLanguage')}
                type={'radio'}
                label={'Chinese'}
                value={EPrimaryIntakeLanguage.chinese}
                checked={infoValues.primaryLanguage === EPrimaryIntakeLanguage.chinese}
              />
              <StandardRadioInput
                name={getFieldName('primaryLanguage')}
                type={'radio'}
                label={'Southeast Asian'}
                value={EPrimaryIntakeLanguage.southeastAsian}
                checked={infoValues.primaryLanguage === EPrimaryIntakeLanguage.southeastAsian}
              />
              <StandardRadioInput
                name={getFieldName('primaryLanguage')}
                type={'radio'}
                label={'Tagalog'}
                value={EPrimaryIntakeLanguage.tagalog}
                checked={infoValues.primaryLanguage === EPrimaryIntakeLanguage.tagalog}
              />
              <StandardRadioInput
                name={getFieldName('primaryLanguage')}
                type={'radio'}
                label={'Russian'}
                value={EPrimaryIntakeLanguage.russian}
                checked={infoValues.primaryLanguage === EPrimaryIntakeLanguage.russian}
              />
              <StandardRadioInput
                name={getFieldName('primaryLanguage')}
                type={'radio'}
                label={'Other'}
                value={EPrimaryIntakeLanguage.other}
                checked={infoValues.primaryLanguage === EPrimaryIntakeLanguage.other}
              />
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If other, please list:'} columnSize={6}>
            <Input
              name={getFieldName('primaryLanguageOther')}
              disabled={!(infoValues.primaryLanguage === EPrimaryIntakeLanguage.other)}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Children'}>
            <DeletableFieldArray
              name={getFieldName('childrenInformation')}
              values={infoValues.childrenInformation}
              factoryFn={makeChildInformation}
              noItemsLabel={'No children added.'}
              renderRow={(childInfo, rowName, getItemName) => (
                <FieldArrayRow style={{flex: 'auto', minWidth: '10rem', marginRight: '1.5rem'}}>
                  <StandardInputColumn label={'Name'} columnSize={4}>
                    <Input name={`${rowName}.${getItemName('name')}`}/>
                  </StandardInputColumn>
                  <StandardInputColumn label={'Birth Date'} columnSize={4}>
                    <DatepickerInput name={`${rowName}.${getItemName('birthDate')}`} onChangeUtcHour={12} ignoreOverflow={true} showYearDropdown={true}/>
                  </StandardInputColumn>
                  <StandardInputColumn label={'Lives With'} columnSize={4}>
                    <Input name={`${rowName}.${getItemName('livesWith')}`}/>
                  </StandardInputColumn>
                </FieldArrayRow>
              )}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
    </>
  );
};
