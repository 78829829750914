import {Route, RouteProps, Navigate} from 'react-router';
import React from 'react';
import {mapIsAuthenticatedToProps, mapRoleToProps, mapRoleToPropsType} from '../../../redux/web/entities/user';
import {connect} from 'react-redux';
import { useLocation } from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';

type RoleProtectedRouteProps = mapRoleToPropsType & {
  children: JSX.Element;
  allowedRoles: string[];
};

const RoleProtectedRouteComponent = ({children, roleName, allowedRoles}: RoleProtectedRouteProps) => {
  const location = useLocation();
  return (
    allowedRoles.includes(roleName) ? children : (
      <Navigate to={RoutePaths.home} state={{ from: location }} replace={true}/>
    )
  );
};

export const RoleProtectedRoute = connect(mapRoleToProps)(RoleProtectedRouteComponent);
