import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../../utils';
import {createStandardSelectors, getEntities, selector} from '../../selectors';
import {combineReducers} from 'redux';
import {CommonDispatch} from '../../index';
import {upsertWeeklyCaseManagementNotes} from '../../../../api/forms/formApi';
import {
  TWeeklyCaseNotesForm
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/WeeklyCaseManagementNotesModal';

export interface CaseManagementNote {
  id: string;
  date: string;
  shortDescription: string;
  longDescription: string;
}

export interface CaseManagementStepEvaluation {
  id: string;
  timeFrame: string;
  description: string;
  comment: string;
}

export interface CaseManagementMedication {
  id: string;
  name: string;
  doctor: string;
  strength: string;
  dosage: string;
  datePrescribed: string;
  expirationDate: string;
  use: string;
}

export interface CaseManagementDrugTest {
  id: string;
  date: string;
  drugTest: string;
  amp: boolean;
  bar: boolean;
  bzo: boolean;
  mtd: boolean;
  opi: boolean;
  met: boolean;
  thc: boolean;
  pcp: boolean;
  mdma: boolean;
  coc: boolean;
  tobacco: boolean;
}

export interface CaseManagementFinancialAssistance {
  id: string;
  date: string;
  amount: string;
  descriptionOfExpense: string;
  voucher: string;
}

export interface CaseManagementCounseling {
  id: string;
  date: string;
  notes: string;
}

export interface CaseManagementContact {
  id: string;
  contact: string;
  information: string;
  resourcePartner: string;
}

export interface WeeklyCaseManagementNotes {
  id: string;
  userId: string;
  caseNotes: CaseManagementNote[];
  stepEvaluations: CaseManagementStepEvaluation[];
  medications: CaseManagementMedication[];
  drugTests: CaseManagementDrugTest[];
  financialAssistances: CaseManagementFinancialAssistance[];
  counselings: CaseManagementCounseling[];
  contacts: CaseManagementContact[];
  updatedOn: string;
}

const actions = createStandardActions(placeholder<WeeklyCaseManagementNotes>(), 'WEEKLY_CASE_MANAGEMENT_NOTES/SET', 'WEEKLY_CASE_MANAGEMENT_NOTES/SAVE');
const selectors = createStandardSelectors(placeholder<WeeklyCaseManagementNotes>(), s => getEntities(s).weeklyCaseManagementNotes);
export type WeeklyCaseManagementNotesActions = GetActions<typeof actions>;
export const weeklyCaseManagementNotes = combineReducers({items: standardItemsReducer<WeeklyCaseManagementNotes, WeeklyCaseManagementNotesActions>(actions)});
export const weeklyCaseManagementNotesStore = readonly({
  selectors: {
    ...selectors,
    getByUserId: selector(s => (userId: string) => selectors.getAsArray(s).find(caseNotes => caseNotes.userId === userId))
  },
  actions: {
    ...actions,
    upsert: (form: TWeeklyCaseNotesForm) => async (dispatch: CommonDispatch) => {
      const response = await upsertWeeklyCaseManagementNotes(form);
      await dispatch(actions.save(response));
    }
  }
});
