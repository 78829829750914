import {
  mapIsAdministratorToProps,
  mapIsAdministratorToPropsType,
  mapIsParticipantToProps, mapIsParticipantToPropsType
} from '../../../redux/web/entities/user';
import {connect} from 'react-redux';

type ParticipantRouteProps = mapIsParticipantToPropsType & {
  children: JSX.Element;
};

// TODO once manager role is implemented, create prop for whether managers can access
function ParticipantComponent({children, participant}: ParticipantRouteProps) {
  return participant ? children : null;
}

export default connect(mapIsParticipantToProps)(ParticipantComponent);
