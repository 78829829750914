import {
  TGoalSheetForm
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/GoalSheetModal/GoalSheetModal';
import {
  TGoalFormEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/GoalSheetModal/GoalFieldArray';
import {GoalSheet} from '../../entities/forms/goalSheet';

export function makeGoalSheet(userId: string, existingGoalSheet?: GoalSheet): TGoalSheetForm {
  return existingGoalSheet ? {...existingGoalSheet, userId: userId} : {
    userId: userId,
    sixMonths: [],
    oneYear: [],
    fiveYears: [],
    updatedOn: ''
  };
}

export function makeGoal(): TGoalFormEntry {
  return {
    goal: ''
  };
}
