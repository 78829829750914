import {CaseManagementNote} from '../../../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import {ArrayHelpers, FieldArray} from 'formik';
import {DeletableListRow} from '../../../../components/util/lists/ListTable/ListTableRow';
import React from 'react';
import {OrderedDraggableList} from '../../../../components/util/lists/OrderedDraggableList/OrderedDraggableList';
import {bindActionCreators, Dispatch} from 'redux';
import {
  CaseFileDocument,
  CaseFileDocumentStatus,
  caseFileDocumentStore
} from '../../../../redux/web/entities/caseFileDocument';
import {WebState} from '../../../../redux/core/types/WebState';
import {convertToDropDownOptions} from '../../../../redux/util';
import {roleStore} from '../../../../redux/web/entities/role';
import {connect} from 'react-redux';
import {DropdownOption} from '../../../../components/util/form-components/SearchableDropdown/SearchableDropdown';
import {
  ListHeaderWithDropDown,
  ResetValueFunc
} from '../../../../components/util/lists/ListHeaderWithDropDown/ListHeaderWithDropDown';
import {InputColumn} from '../../../../components/util/form-components/InputColumn';
import {Row} from 'react-bootstrap';

type Props = {
  documentIds: string[];
  fieldPrefix: string;
  documentDropDownOptions: DropdownOption[];
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const CaseFileDocumentMergeDraggableFieldArray = (props: Props) => {
  const {fieldPrefix, documentIds, documentDropDownOptions, getCaseFileDocumentById} = props;
  const onAdd = (helpers: ArrayHelpers, id: string, resetValue: ResetValueFunc) => {
    helpers.push(id);
    resetValue();
  };

  return (
      <FieldArray
        name={fieldPrefix}
        validateOnChange={false}
        render={helpers=> {
          return (
            <>
                <ListHeaderWithDropDown
                  label={'Order (Drag First to Top)'}
                  dropDownOptions={documentDropDownOptions.filter(doc => documentIds.every(id => doc.value !== id))}
                  onAdd={(v, r) => onAdd(helpers, v, r)}
                  addOnSelect={true}
                  placeholder={'Search Documents'}
                />
              <OrderedDraggableList
                editable={true}
                prefix={fieldPrefix}
                items={documentIds}
                noItemsLabel={'No documents assigned to merge'}
                move={(from, to) => helpers.move(from, to)}
                renderItem={(item, index) => (
                  <DeletableListRow
                    iconSize={'2x'}
                    editable={true}
                    deleteColumnSize={1}
                    renderContentSize={11}
                    key={item}
                    onDelete={() => helpers.remove(index)}
                    render={() => <DocumentRow document={getCaseFileDocumentById(item)}/>}/>
                )}
              />
            </>
          );
        }}
      />
  );
};

function DocumentRow({document}: {document: CaseFileDocument}) {
  return  (
    <Row style={{flex: '1', flexWrap: 'nowrap'}}>
        <InputColumn label={'Name'} columnSize={6} className='pl-2'>
          <h6>{document.name}</h6>
        </InputColumn>
        <InputColumn label={'Status'} columnSize={6} className='pl-2'>
          <h6>{CaseFileDocumentStatus[document.status]}</h6>
        </InputColumn>
    </Row>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertCaseFileDocument: caseFileDocumentStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  rolesOptions: convertToDropDownOptions(roleStore.selectors.getAsArray(state), 'roleName'),
  getCaseFileDocumentById: caseFileDocumentStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(CaseFileDocumentMergeDraggableFieldArray);
