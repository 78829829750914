import styles from './CenteredErrorMessage.module.scss';
import {Alert} from 'react-bootstrap';
import React, {CSSProperties} from 'react';

export function CenteredErrorMessage(props: {message: string; style?: CSSProperties}) {
  return (
    <div className={styles['centered-error']} style={props.style}>
      <Alert variant='danger'>{props.message}</Alert>
    </div>
  );
}
