import React, {useEffect, useState} from 'react';
import {BtcHistoryEvent} from '../../../types/BtcHistoryEvent';
import {noProfilePicture} from '../../../appTheme';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../redux/util/http';
import {makeBtcHistoryEventList} from '../../../redux/web/factory';
import {Alert, Spinner} from 'react-bootstrap';
import {CenteredSpinner} from '../widgets/CenteredSpinner/CenteredSpinner';

interface Props {
  apiHistoryFunc: Promise<BtcHistoryEvent[] | null>;
  label: string;
}

export function HistoryViewer({apiHistoryFunc, label}: Props) {
  const [btcHistoryEvents, setBtcHistoryEvents] = useState(makeBtcHistoryEventList());
  const [historyErrorMessage, setHistoryErrorMessage] = useState('');
  const [loadingHistory, setLoadingHistory] = useState(true);

  useEffect(() => {
    async function loadHistory() {
      try {
        if(apiHistoryFunc !== null) {
          const historyResult = await apiHistoryFunc;
          setBtcHistoryEvents(historyResult as BtcHistoryEvent[]);
        }
      } catch (e: AxiosError | any) {
        setHistoryErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load history'}));
      }
      setLoadingHistory(false);
    }
    loadHistory();
  });

  const formatChanges = (changes: string) => {
    if(changes) {
      return ` - ${changes}`;
    }
    return '';
  };

  const renderHistoryItem = (item: BtcHistoryEvent, key: number) => {
    return (
      <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: '1rem'}} key={key}>
        <img
          style={{height: '2rem', width: '2rem', borderRadius: '50%', marginRight: '1rem'}}
          src={item.userProfileImageData !== '' ? `${item.userProfileImageData}` : noProfilePicture()}
          alt={'Profile picture'}
        />
        Updated on {item.updatedOn} by {item.name} {formatChanges(item.changes)}
      </div>
    );
  };

  return (
    <div>
      <h4>{label}</h4>
      {loadingHistory ? <Spinner animation='border' role='status'/> :
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {btcHistoryEvents.map((h, i) => renderHistoryItem(h, i))}
        </div>
      }
      {historyErrorMessage !== '' ?
        <div style={{marginTop: '1rem'}}>
          <Alert variant='danger'>{historyErrorMessage}</Alert>
        </div> : null
      }
    </div>
  );
}
