import React, {useMemo, useState} from 'react';
import styles from './ParticipantClassGrid.module.scss';
import gridStyles from '../../../../GridStyles.module.scss';
import {makeParticipantClass} from '../../../../../redux/web/factory';
import {Navigate, useSearchParams} from 'react-router-dom';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import BootstrapTable, {BootstrapTableProps, ColumnDescription, SearchProps} from 'react-bootstrap-table-next';
import {Button, Modal, Row, Spinner} from 'react-bootstrap';
import {ConfirmationDialog} from '../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/core/types/WebState';
import {connect} from 'react-redux';
// @ts-ignore
import ToolkitProvider, {ColumnToggle, Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ParticipantClassModal from '../ParticipantClassModal/ParticipantClassModal';
import {ParticipantClass, participantClassStore} from '../../../../../redux/web/entities/participantClass';
import {parseISO} from 'date-fns';
import {utcToZonedTime, format} from 'date-fns-tz';
import {localTz, localTzFriendlyFormat, now} from '../../../../../util';
import {ToggleListProps} from 'react-bootstrap-table2-toolkit';
import AttendanceModal from '../AttendanceModal/AttendanceModal';
import {AppTheme} from '../../../../../appTheme';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function ParticipantClassGrid(props: Props) {
  const {participantClasses, getParticipantClassById, actions: {deleteParticipantClass}} = props;
  const [isDeleting, setIsDeleting] = useState('');
  const [currentModal, setCurrentModal] = useState<'none' | 'upsert' | 'attendance'>('none');
  const [editingParticipantClass, setEditingParticipantClass] = useState(makeParticipantClass());
  const [showColumnToggleFilters, setShowColumnToggleFilters] = useState(false);
  const [searchParams] = useSearchParams();
  const {SearchBar} = Search;
  const [redirectUrl, setRedirectUrl] = useState('');
  const getFieldName = (name: keyof ParticipantClass) => name;

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl}/>;
    }
    return null;
  };

  const dateTimeFormatter = (cell: any, participantClass: ParticipantClass) => {
    const formatTime = (isoDate: string) => format(utcToZonedTime(parseISO(isoDate), localTz as any as string), 'p', {timeZone: localTz as any as string});
    const formatDate = (isoDate: string) => format(parseISO(isoDate), 'MM/dd/yyyy');
    return `${formatDate(participantClass.startDateTime)} ${formatTime(participantClass.startDateTime)} - ${formatTime(participantClass.endDateTime)}`;
  };

  const participantsFormatter = (cell: any, participantClass: ParticipantClass) => {
    const classAssignments = participantClass.participantClassAssignments;
    return (
      <div style={{display: 'flex', justifyContent: 'flex-start'}}>
        {`${classAssignments.length} ${classAssignments.length === 1 ? 'participant' : 'participants'}`}
        {parseISO(participantClass.endDateTime) < now ? `, ${classAssignments.filter(assignment => assignment.attendance).length} attended` : ''}
      </div>
    );
  };

  const actionsFormatter = (cell: any, participantClass: ParticipantClass) => {
    const attendanceDisabled = !participantClass.participantClassAssignments.length;
    return (
      <div className={gridStyles['table-actions-wrapper']}>
        <div>
          <IconButton
            icon='chalkboard-teacher'
            size={'2x'}
            styles={{marginLeft: '.5rem'}}
            iconToolTipText={attendanceDisabled ? 'No participants assigned' : 'Class attendance'}
            disabled={attendanceDisabled}
            onClick={() => {
              setEditingParticipantClass(participantClass);
              setCurrentModal('attendance');
            }}
            color={attendanceDisabled ? AppTheme.colors.darkGray : AppTheme.colors.oceanBlue}
          />
        </div>
        <div>
          <IconButton
            icon='edit'
            size={'2x'}
            styles={{marginLeft: '.5rem'}}
            iconToolTipText={'Edit class'}
            onClick={() => {
              setEditingParticipantClass(participantClass);
              setCurrentModal('upsert');
            }}
            color={'#005A9C'}
          />
        </div>
        <div>
          <IconButton
            icon={'trash-alt'}
            styles={{color: 'red', marginLeft: '.5rem'}}
            size={'2x'}
            iconToolTipText={'Delete class'}
            onClick={() => setIsDeleting(participantClass.id.toString())}
          />
        </div>
      </div>
    );
  };

  const onColumnToggle = (col: ColumnDescription<any>) => {
    const updatedTableColumns = columns.map((value) => value.dataField === col.dataField ? {...value, hidden: !col.hidden} : value);
    const visibleRecordInfo = updatedTableColumns.filter((value) => (!value.hidden || false) && !col.isDummyField).length > 1;
    setColumns(() => visibleRecordInfo ? updatedTableColumns : columns);
  };

  const FilteredToggleList = ({toggles}: {toggles: boolean[]}) => (
    <div className={gridStyles['filters-button-group']}>
      {
        columns
          .filter(col => !(col.isDummyField || col.dataField === (getFieldName('id'))))
          .map(column => ({...column, toggle: toggles[column.dataField]}))
          .map(column => (
            <Button
              variant={column.toggle ? 'success' : 'secondary'}
              key={ column.dataField }
              style={column.text.includes('Date / Time') ? {whiteSpace: 'nowrap'} : undefined}
              onClick={ () => onColumnToggle(column) }
            >
              {!column.text.includes('Date / Time') ? column.text : 'Date / Time'}
            </Button>
          ))
      }
    </div>
  );

  const filterActivatingUser = () => {
    return participantClasses.filter(pc => pc.name === searchParams.get('edit')!);
  };

  const [columns, setColumns] = useState<ColumnDescription[]>([
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true,
      searchable: false
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'startDateTime',
      text: `Date / Time (${localTzFriendlyFormat})`,
      sort: true,
      formatter: dateTimeFormatter
    },
    {
      dataField: 'description',
      text: 'Description',
      hidden: true
    },
    {
      dataField: 'participantClassAssignments',
      text: `Participants & Attendance`,
      sort: true,
      searchable: false,
      formatter: participantsFormatter
    },
    {
      dataField: 'actionsColumn',
      text: 'Actions',
      isDummyField: true,
      searchable: false,
      formatter: actionsFormatter
    }
  ]);

  return (
    <ToolkitProvider
      keyField='id'
      data={participantClasses}
      columns={columns}
      search={{
        searchFormatted: true
      }}
      columnToggle={true}
    >
      {(tableProps:
          { baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<ParticipantClass, number>> & Readonly<BootstrapTableProps>;
            searchProps: SearchProps<ParticipantClass>;
            columnToggleProps: ToggleListProps;}) => (
        <>
          <div className={gridStyles['button-groups-container']}>
            <div className={gridStyles['button-group']}>
              <Button
                onClick={() => {
                  setEditingParticipantClass(makeParticipantClass());
                  setCurrentModal('upsert');
                }}
                style={{backgroundColor: AppTheme.colors.oceanBlue}}
              >
                Add Class
              </Button>
              <Button style={{backgroundColor: '#005A9C'}} onClick={() => setShowColumnToggleFilters(!showColumnToggleFilters)}>
                {showColumnToggleFilters ? 'Hide' : 'Show'} Column Filters
              </Button>
            </div>
            {showColumnToggleFilters ?
              <div className={gridStyles['button-group']}>
                <FilteredToggleList {...tableProps.columnToggleProps}/>
              </div>
                : null
            }
          </div>
          {renderRedirect()}
          <SearchBar {...tableProps.searchProps}/>
          <BootstrapTable
            wrapperClasses={gridStyles['responsive-table-wrapper']}
            classes={styles['table-auto']}
            rowStyle={{height: '100%'}}
            {...tableProps.baseProps}
          />
          {isDeleting !== '' && (
            <ConfirmationDialog
              onAccept={async () => {
                await deleteParticipantClass(isDeleting);
                setIsDeleting('');
              }}
              onDecline={async () => { setIsDeleting(''); }}
              open={isDeleting !== ''}
              prompt='Are you sure you want to delete this class?'
              positiveText='Yes'
              negativeText='No'
              positiveVariant='success'
              negativeVariant='danger'
            />
          )}
          {currentModal === 'upsert' ?
            <ParticipantClassModal
              editable={true}
              onSubmit={() => {
                setCurrentModal('none');
              }}
              onCancel={() => setCurrentModal('none')}
              existingParticipantClass={editingParticipantClass}
            /> :
            currentModal === 'attendance' ?
              <AttendanceModal
                prefix={getFieldName('participantClassAssignments')}
                participantClass={editingParticipantClass}
                onSubmit={() => setCurrentModal('none')}
                onCancel={() => setCurrentModal('none')}
              /> : null
          }
        </>
      )}
    </ToolkitProvider>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    deleteParticipantClass: participantClassStore.actions.delete
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  participantClasses: participantClassStore.selectors.getAsArray(state),
  getParticipantClassById: participantClassStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(ParticipantClassGrid);
