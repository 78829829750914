import {
  TWeeklyCaseNotesForm
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/WeeklyCaseManagementNotesModal';
import {CaseManagementNote} from '../../entities/forms/WeeklyCaseManagementNotes';
import {
  TCaseManagementCaseNoteEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/CaseNotesForm/CaseNotesFieldArray';
import {
  TCaseManagementDrugTestEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/DrugTestsForm/DrugTestsFieldArray';
import {
  TCaseManagementStepEvaluationEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/StepEvaluationsForm/StepEvaluationsFieldArray';
import {
  TCaseManagementMedicationEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/MedicationForm/MedicationsFieldArray';
import {
  TCaseManagementFinancialAssistanceEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/FinancialAssistanceForm/FinancialAssistancesFieldArray';
import {
  TCaseManagementCounselingEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/CounselingForm/CounselingsFieldArray';
import {
  TCaseManagementContactEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/ContactsForm/ContactsForm';

export function makeWeeklyCaseManagementNotes(userId: string, current?: TWeeklyCaseNotesForm): TWeeklyCaseNotesForm {
  return current ? {...current} : {
    userId: userId,
    caseNotes: [],
    stepEvaluations: [],
    medications: [],
    drugTests: [],
    financialAssistances: [],
    counselings: [],
    contacts: [],
    updatedOn: ''
  };
}

export function makeCaseManagementNote(): TCaseManagementCaseNoteEntry {
  return {
    date: '',
    shortDescription: '',
    longDescription: ''
  };
}

export function makeCaseManagementStepEvaluation(): TCaseManagementStepEvaluationEntry {
  return {
    timeFrame: '',
    description: '',
    comment: ''
  };
}

export function makeCaseManagementMedication(): TCaseManagementMedicationEntry {
  return {
    name: '',
    doctor: '',
    strength: '',
    dosage: '',
    datePrescribed: '',
    expirationDate: '',
    use: ''
  };
}

export function makeCaseManagementDrugTest(): TCaseManagementDrugTestEntry {
  return {
    date: '',
    drugTest: '',
    amp: false,
    bar: false,
    bzo: false,
    coc: false,
    met: false,
    mtd: false,
    opi: false,
    mdma: false,
    pcp: false,
    thc: false,
    tobacco: false
  };
}

export function makeCaseManagementFinancialAssistance(): TCaseManagementFinancialAssistanceEntry {
  return {
    date: '',
    amount: '',
    descriptionOfExpense: '',
    voucher: ''
  };
}

export function makeCaseManagementCounseling(): TCaseManagementCounselingEntry {
  return {
    date: '',
    notes: ''
  };
}

export function makeCaseManagementContact(): TCaseManagementContactEntry {
  return {
    contact: '',
    information: '',
    resourcePartner: ''
  };
}
