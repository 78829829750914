import {combineReducers, Dispatch} from 'redux';
import {CommonDispatch, CommonState} from '../index';
import jwt_decode from 'jwt-decode';
import {AuthToken} from '../../core/types/AuthToken';
import storage from 'redux-persist/es/storage';
import {
  createStandardActions,
  GetActions,
  placeholder,
  readonly,
  standardItemsReducer
} from '../utils';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {createAction, createReducer} from 'typesafe-actions';
import {
  archiveUser,
  createUser, getUser,
  restoreUser, updateProfilePicture, updateUserPreferences,
  upsertUser
} from '../../../api/userManagementApi';
import {Role, roleStore} from './role';
import {UpdateProfilePictureRequest} from '../../../pages/Profile/components/ProfilePicture';
import {UserUpsert} from '../../../pages/Configuration/UserManagement/components/UserModal/UserModal';
import {Dictionary} from '../../util';

export interface LoginRequest {
  email: string;
  password: string;
}

export interface UserRegistrationForm extends User {
  password: string;
  confirmPassword: string;
}

export interface User extends UserPreferences{
  id: string;
  email: string;
  archivedAt: string | null;
  createdAt: string;
  roleId: string;
  role: Role;
  name: string;
  standardId: string;
  tosAccepted: boolean;
  receiveTextNotifications: boolean;
  accountStatus: AccountStatus;
  profilePicturePath: string;
}

// TODO add text notifications and possibly tos accepted status in user preferences instead of user
export interface UserPreferences {
  primaryPhone: string;
  secondaryPhone: string;
  address: string;
  addressLineTwo: string;
  city: string;
  state: string;
  zip: string;
  birthDate: string;
}


export interface UserNameAndRole extends User {
  roleName: string;
}


export enum AccountStatus {
  EmailValidationRequired = 0,
  PendingApproval = 1,
  Active = 2
}

export interface UserWithToken extends User  {
  token: string;
}

export interface UserState {
  currentUser: User | null;
  items: UserItems;
}

export interface UserItems {
  [key: number]: User;
}

export const userPersistConfig = {
  key: 'users',
  storage: storage,
  whitelist: ['currentUser']
};

const currentUserActions = {
  setCurrentUser: createAction('USER/SET_CURRENT_USER')<User | null>()
};

const partialDataActions = {
  setIncludedData: createAction('USER/SET_INCLUDED_DATA')<Dictionary<User>>()
};


const actions = createStandardActions(placeholder<User>(), 'USER/SET', 'USER/SAVE');
const selectors = createStandardSelectors(placeholder<User>(), s => getEntities(s).users);

export type UserActions = GetActions<typeof actions> | GetActions<typeof partialDataActions>;
type CurrentUserActions = GetActions<typeof currentUserActions>;

export const users = combineReducers<UserState>({
  currentUser: createReducer<User|null, CurrentUserActions>(null)
    .handleAction(currentUserActions.setCurrentUser, (state, action) => action.payload),
  items: standardItemsReducer<User, UserActions>(actions)
    .handleAction(partialDataActions.setIncludedData, (state, action) => {
      let returnedState = {...state};
      Object.entries(action.payload).forEach(([key, value]) => {
        const previousEntryState = returnedState[key as any];
        returnedState =  {...returnedState, [key as any]: {...previousEntryState, ...value}};
      });
      return ({...returnedState});
    })
});

export const userStore = readonly({
  selectors: {
    ...selectors,
    getCurrentUser: selector(s => selectors.getState(s).currentUser),
    getNonArchivedUsers: selector(s => selectors.getAsArray(s).filter(u => u.archivedAt === null)),
    getNonArchivedActiveUsers: selector(s => selectors.getAsArray(s).filter(u => u.archivedAt === null && u.accountStatus === AccountStatus.Active)),
    getArchivedUsers: selector(s => selectors.getAsArray(s).filter(u => u.archivedAt !== null)),
    getParticipants: selector(s => selectors.getAsArray(s).filter(u => u.role?.roleName === 'Participant' && u.archivedAt === null)),
    getArchivedParticipants: selector(s => selectors.getAsArray(s).filter(u => u.role?.roleName === 'Participant' && u.archivedAt !== null)),
    getShelterStaff: selector(s => selectors.getAsArray(s).filter(u => u.role?.roleName === 'Shelter Staff' && u.archivedAt === null)),
    isAdministrator: selector(s => selectors.getState(s).currentUser?.role.roleName === 'Administrator'),
    isParticipant: selector(s => selectors.getState(s).currentUser?.role.roleName === 'Participant'),
    isShelterStaff: selector(s => selectors.getState(s).currentUser?.role.roleName === 'Shelter Staff'),
    isStaff: selector(s => selectors.getState(s).currentUser?.role.roleName === 'Staff')
  },
  actions: {
    ...actions,
    ...partialDataActions,
    ...currentUserActions,
    getUser: (userId: string) => async (dispatch: CommonDispatch) => {
      const user = await getUser(userId);
      if (user.id)
        await dispatch(userStore.actions.save(user));
    },
    upsert: (form: UserUpsert) => async (dispatch: CommonDispatch) => {
      let response;
      if(!form.id || form.id === '') {
        response = await createUser(form);
      } else {
        response = await upsertUser(form);
      }
      dispatch(userStore.actions.save(response));
      return response;
    },
    archiveUser: (id: string) => async (dispatch: CommonDispatch) => {
      const user: User = await archiveUser(id);
      dispatch(userStore.actions.save(user));
    },
    restoreUser: (id: string) => async (dispatch: CommonDispatch) => {
      const user: User = await restoreUser(id);
      dispatch(userStore.actions.save(user));
    },
    updateUserPreferences: (form: UserPreferences) => async (dispatch: Dispatch) => {
      const response = await updateUserPreferences(form);
      dispatch(userStore.actions.save(response));
      dispatch(userStore.actions.setCurrentUser(response));
      return response;
    },
    updateUsersProfilePicture: (request: UpdateProfilePictureRequest) => async (dispatch: Dispatch) => {
      const user: User = await updateProfilePicture(request);
      dispatch(userStore.actions.save(user));
      return user.profilePicturePath;
    },
    updateProfilePicture: (request: UpdateProfilePictureRequest) => async (dispatch: Dispatch) => {
      const user: User = await updateProfilePicture(request);
      await dispatch(userStore.actions.save(user));
      await dispatch(userStore.actions.setCurrentUser(user));
    }
  }
});

export function isAuthenticated(state: CommonState) {
  const localUser: User | null = userStore.selectors.getCurrentUser(state);
  const token = localStorage.getItem('token');
  if (token && localUser) {
    const time = Math.ceil((new Date()).getTime() / 1000);
    const decoded = jwt_decode<AuthToken>(token);
    return time < decoded['exp'];
  }
  return false;
}

export function getRole(state: CommonState) {
  const localUser: User = userStore.selectors.getCurrentUser(state) as User;
  return localUser.role.roleName;
}

export type mapIsAuthenticatedToPropsType = ReturnType<typeof mapIsAuthenticatedToProps>;
export const mapIsAuthenticatedToProps = (state: CommonState) => ({ authenticated: isAuthenticated(state)});

export type mapIsAdministratorToPropsType = ReturnType<typeof mapIsAdministratorToProps>;
export const mapIsAdministratorToProps = (state: CommonState) => ({ administrator: userStore.selectors.isAdministrator(state)});

export type mapIsParticipantToPropsType = ReturnType<typeof mapIsParticipantToProps>;
export const mapIsParticipantToProps = (state: CommonState) => ({ participant: userStore.selectors.isParticipant(state)});

export type mapIsShelterStaffToPropsType = ReturnType<typeof mapIsShelterStaffToProps>;
export const mapIsShelterStaffToProps = (state: CommonState) => ({ shelterStaff: userStore.selectors.isShelterStaff(state)});

export type mapIsStaffToPropsType = ReturnType<typeof mapIsStaffToProps>;
export const mapIsStaffToProps = (state: CommonState) => ({ staff: userStore.selectors.isStaff(state)});

export type mapIsAdministratorOrShelterStaffToPropsType = ReturnType<typeof mapIsAdministratorOrShelterStaffToProps>;
export const mapIsAdministratorOrShelterStaffToProps = (state: CommonState) => ({
  administratorOrShelterStaff: (userStore.selectors.isAdministrator(state) || userStore.selectors.isShelterStaff(state))});

export type mapIsNotParticipantToPropsType = ReturnType<typeof mapIsNotParticipantToProps>;
export const mapIsNotParticipantToProps = (state: CommonState) => ({ notParticipant: !userStore.selectors.isParticipant(state)});

export type mapRoleToPropsType = ReturnType<typeof mapRoleToProps>;
export const mapRoleToProps = (state: CommonState) => ({ roleName: getRole(state)});
