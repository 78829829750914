import {PrimaryIntake, primaryIntakeStore} from '../entities/forms/intake/primaryIntake/PrimaryIntake';
import {NewParticipantChecklist, newParticipantChecklistStore} from '../entities/forms/newParticipantChecklist';
import {GoalSheet, goalSheetStore} from '../entities/forms/goalSheet';
import {AdmissionCoverSheet, admissionCoverSheetStore} from '../entities/forms/admissionCoverSheet';
import {Dispatch} from 'redux';
import {getUserFormsData} from '../../../api/forms/formApi';
import {batchActions} from 'redux-batched-actions';
import {WeeklyCaseManagementNotes, weeklyCaseManagementNotesStore} from '../entities/forms/WeeklyCaseManagementNotes';
import {SecondaryIntake, secondaryIntakeStore} from '../entities/forms/intake/secondaryIntake/secondaryIntake';

export interface UserFormsState {
  primaryIntake?: PrimaryIntake;
  secondaryIntake?: SecondaryIntake;
  weeklyCaseManagementNotes?: WeeklyCaseManagementNotes;
  newParticipantChecklist?: NewParticipantChecklist;
  goalSheet?: GoalSheet;
  admissionCoverSheet?: AdmissionCoverSheet;
}

export const loadUserFormData = (userId: string) => async (dispatch: Dispatch) => {
  const response: UserFormsState = await getUserFormsData(userId);
  if (response.primaryIntake)
    dispatch(primaryIntakeStore.actions.save(response.primaryIntake));
  if (response.secondaryIntake)
    dispatch(secondaryIntakeStore.actions.save(response.secondaryIntake));
  if (response.weeklyCaseManagementNotes)
    dispatch(weeklyCaseManagementNotesStore.actions.save(response.weeklyCaseManagementNotes));
  if (response.newParticipantChecklist)
    dispatch(newParticipantChecklistStore.actions.save(response.newParticipantChecklist));
  if (response.goalSheet)
    dispatch(goalSheetStore.actions.save(response.goalSheet));
  if (response.admissionCoverSheet)
    dispatch(admissionCoverSheetStore.actions.save(response.admissionCoverSheet));
};
