import {Route, RouteProps, Navigate} from 'react-router';
import React from 'react';
import {
  mapIsAdministratorOrShelterStaffToProps,
  mapIsAdministratorOrShelterStaffToPropsType,
  mapIsAdministratorToProps,
  mapIsAdministratorToPropsType,
  mapIsAuthenticatedToProps,
  mapIsAuthenticatedToPropsType, mapIsShelterStaffToProps, mapIsShelterStaffToPropsType
} from '../../../redux/web/entities/user';
import {connect} from 'react-redux';
import { useLocation } from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';

type AdministratorOrShelterStaffProps = mapIsAdministratorOrShelterStaffToPropsType & {
  children: JSX.Element;
};

// TODO once manager role is implemented, create prop for whether managers can access
function AdministratorOrShelterStaffComponent({children, administratorOrShelterStaff}: AdministratorOrShelterStaffProps) {
  return administratorOrShelterStaff ? children : null;
}

export default connect(mapIsAdministratorOrShelterStaffToProps)(AdministratorOrShelterStaffComponent);
