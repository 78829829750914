import {
  date,
  number,
  object, string,
} from 'yup';
import {User} from '../../../../../redux/web/entities/user';
import {UserUpsert} from './UserModal';



// @ts-ignore
export const UserModalSchema = object<UserUpsert>({
  id: string().nullable(),
  email: string().nullable().email('Email must be a valid format'),
  roleId: string().required('Role is required'),
  name: string().required('Name is required'),
  primaryPhone: string().nullable(),
  secondaryPhone: string().nullable(),
  address: string().nullable(),
  addressLineTwo: string().nullable(),
  city: string().nullable(),
  state: string().nullable(),
  zip: string().nullable(),
  birthDate: date().nullable()
});
