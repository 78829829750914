import {date, number, object, string, ref} from 'yup';
import {ParticipantClassModalInterface} from './ParticipantClassModal';

// @ts-ignore
export const ParticipantClassModalSchema = object<ParticipantClassModalInterface>({
  id: string().nullable(),
  name: string().required('Name is a required field'),
  classDate: date().required('Date is a required field').typeError('Date must be in the format MM/DD/YYYY'),
  startTime: string().required('Start time is a required field'),
  endTime: string().required('End time is a required field')
    .test('startTime', 'End time must be after the start time', (endTime, context) => {
      if(endTime !== undefined && context.parent.startTime !== undefined) {
        const startParts = context.parent.startTime.split(':');
        const endParts = (endTime as any).split(':');
        const startHours = startParts[0];
        const startMinutes = startParts[1];
        const endHours = endParts[0];
        const endMinutes = endParts[1];
        if(endHours > startHours) {
          return true;
        } else if(endHours === startHours) {
          if(endMinutes > startMinutes) {
            return true;
          }
        }
      }
      return false;
  }),
  description: string().nullable()
});
