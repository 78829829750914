import {
  CaseManagementFinancialAssistance,
  CaseManagementMedication,
  CaseManagementStepEvaluation
} from '../../../../../../../../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import {ArrayHelpers, FieldArray} from 'formik';
import {Col, Row} from 'react-bootstrap';
import IconButton from '../../../../../../../../../components/util/widgets/IconButton/IconButton';
import {
  makeCaseManagementDrugTest,
  makeCaseManagementFinancialAssistance,
  makeCaseManagementMedication,
  makeCaseManagementStepEvaluation
} from '../../../../../../../../../redux/web/factory/forms/weeklyCaseManagementForms';
import {ListTable} from '../../../../../../../../../components/util/lists/ListTable/ListTable';
import {DeletableListRow} from '../../../../../../../../../components/util/lists/ListTable/ListTableRow';
import React from 'react';
import {FieldArrayRow} from '../../../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/FieldArrayRow';
import {InputColumn} from '../../../../../../../../../components/util/form-components/InputColumn';
import {StandardTopPaddedRow} from '../../../../../../../../../components/util/form-components/standardLayout';
import Input from '../../../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import DatepickerInput
  from '../../../../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {makeFieldArrayFieldName} from '../../../../../../../../../util';

export type TCaseManagementFinancialAssistanceEntry = Omit<CaseManagementFinancialAssistance, 'id'>;

interface Props {
  financialAssistances: CaseManagementFinancialAssistance[];
  editable: boolean;
  fieldPrefix: string;
}

const getFieldName = (name: keyof CaseManagementFinancialAssistance) => name;

export const CaseManagementFinancialAssistancesFieldArray = (props: Props) => {
  const {fieldPrefix, financialAssistances, editable} = props;
  const generateFieldName = (index: number) => `${fieldPrefix}.${index}`;

  const renderHeader = (helpers: ArrayHelpers) => (
    <Col style={{width: '100%', marginBottom: '10px'}}>
      <Row>
        <h5>Case Management Medications</h5>
        <div style={{display: 'flex', flex: '1', textAlign: 'right'}}>
          <IconButton
            icon={'plus'}
            styles={{width: '100%', textAlign: 'right'}}
            size={'2x'}
            onClick={() => helpers.push(makeCaseManagementFinancialAssistance())}
          />
        </div>
      </Row>
    </Col>
  );

  return (
    <FieldArray
      name={fieldPrefix}
      validateOnChange={false}
      render={helpers=> {
        return (
          <ListTable
            style={{marginBottom: '1rem'}}
            noItemsLabel={'No financial assistance entries added'}
            items={financialAssistances}
            renderHeader={() => renderHeader(helpers)}
            renderItem={(item, index) => (
              <DeletableListRow
                iconSize={'2x'}
                key={generateFieldName(index)}
                deleteColumnSize={1}
                style={{flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'visible'}}
                render={() => (
                  <FinancialAssistanceRow
                    entryPrefix={generateFieldName(index)}
                    editable={editable}
                    financialAssistance={item}
                  />
                )}
                editable={editable}
                onDelete={() => helpers.remove(index)}
              />
            )}
          />
        );
      }}
    />
  );
};

type FinancialAssistanceRowProps = {
  editable: boolean;
  financialAssistance: CaseManagementFinancialAssistance;
  entryPrefix: string;
};
function FinancialAssistanceRow({financialAssistance, entryPrefix, editable}: FinancialAssistanceRowProps) {
  return  (
    <FieldArrayRow style={{flex: '1', minWidth: '13rem', marginRight: '15px'}}>
      <Col style={{padding: '0'}}>
        <StandardTopPaddedRow>
          <InputColumn label={'Date'} columnSize={4}>
            <DatepickerInput name={makeFieldArrayFieldName(entryPrefix, getFieldName('date'))}
                             onChangeUtcHour={12} showYearDropdown={true} ignoreOverflow={true}/>
          </InputColumn>
          <InputColumn label={'Amount'} columnSize={4}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('amount'))}/>
          </InputColumn>
          <InputColumn label={'Voucher'} columnSize={4}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('voucher'))}/>
          </InputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <InputColumn label={'Description of Expense'}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('descriptionOfExpense'))}/>
          </InputColumn>
        </StandardTopPaddedRow>
      </Col>
    </FieldArrayRow>
  );
}
