import React, {FormEvent, useState} from 'react';
import {Alert, Button, Col, Modal, Row, Spinner, Tab, Tabs} from 'react-bootstrap';
import {Form, Formik, FormikErrors, FormikValues} from 'formik';
import styles from '../ParticipantFormModalStyles.module.scss';
import {Form as BSForm} from 'react-bootstrap';
import {
  StandardInputColumn,
  StandardParentInputRow,
  StandardTopPaddedRow,
} from '../../../../../../components/util/form-components/standardLayout';
import {ConfirmationDialog} from '../../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../../../redux/util/http';
import {bindActionCreators, Dispatch} from 'redux';
import {userStore} from '../../../../../../redux/web/entities/user';
import {WebState} from '../../../../../../redux/core/types/WebState';
import {connect} from 'react-redux';
import DatepickerInput
  from '../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import Input from '../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {
  AdmissionCoverSheet,
  admissionCoverSheetStore
} from '../../../../../../redux/web/entities/forms/admissionCoverSheet';
import {makeAdmissionCoverSheet} from '../../../../../../redux/web/factory/forms/admissionCoverSheet';
import {AdmissionCoverSheetModalSchema} from './AdmissionCoverSheetModalSchema';
import {PrimaryIntake} from '../../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {SecondaryIntake} from '../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {
  CompletionStateIndicator
} from '../../../../../../components/util/widgets/CompletionStateIndicator/CompletionStateIndicator';
import PhoneInputField
  from '../../../../../../components/util/form-components/formik-inputs/PhoneNumberInput/PhoneNumberInput';

export type TAdmissionCoverSheetForm = Omit<AdmissionCoverSheet, 'id'> & {
  id?: string;
};

type Props = {
  userId: string;
  editable: boolean;
  existingCoverSheet?: AdmissionCoverSheet;
  existingPrimary?: PrimaryIntake;
  existingSecondary?: SecondaryIntake;
  onSubmit: () => void;
  onCancel: () => void;
  disabled?: boolean;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function NewParticipantChecklistModal(props: Props) {
  const {userId, editable, onSubmit, onCancel, existingCoverSheet, existingPrimary, existingSecondary,
    getUserById, disabled = true, actions: {upsertAdmissionCoverSheet}} = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentModal, setCurrentModal] = useState<'admission' | 'cancel'>('admission');
  const [errorMessage, setErrorMessage] = useState('');
  const [validateOnChangeAfterSubmit, setValidateOnChangeAfterSubmit] = useState(false);
  const [tabKey, setTabKey] = useState<any>('initialInfo');
  const username = getUserById(userId)?.name ?? 'undefined name';
  const getFieldName = (name: keyof TAdmissionCoverSheetForm) => name;
  const newFormMessage = existingSecondary ? 'New (Values Imported from Primary Intake & Secondary Intake Forms)' :
    'New (Values Imported from Primary Intake Form)';

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    values: TAdmissionCoverSheetForm,
    validate: (values: TAdmissionCoverSheetForm) => Promise<FormikErrors<TAdmissionCoverSheetForm>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    setValidateOnChangeAfterSubmit(true);
    e.persist();
    e.preventDefault();
    const errors = await validate(values);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
      setErrorMessage('Errors were found in the form.');
    } else {
      try {
        await upsertAdmissionCoverSheet(values);
        onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(getErrorResponseMessage(e));
      }
      setIsSubmitting(false);
    }
  };

  const renderCancelConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        open={currentModal === 'cancel'}
        onAccept={async () => onCancel()}
        onDecline={async () => setCurrentModal('admission')}
        positiveVariant={'success'}
        negativeVariant={'danger'}
        positiveText={'Yes'}
        negativeText={'No'}
        prompt={`Are you sure you would like to cancel your edits to ${username}'s admission cover sheet?`}
      />
    );
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={() => disabled ? onCancel() : setCurrentModal('cancel')} variant={'danger'} className={styles['close-button']}>
            {disabled ? 'Close' : 'Cancel'}
          </Button>
        }
        {!disabled && !isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  return (
    <>
      <Formik
        initialValues={makeAdmissionCoverSheet(userId, existingCoverSheet, existingPrimary, existingSecondary)}
        validationSchema={AdmissionCoverSheetModalSchema}
        onSubmit={() => undefined}>
        {({values, validateForm, handleSubmit, errors, setValues}) => (
          <Modal show={currentModal === 'admission'} centered={true} size={'xl'}>
            <Modal.Body>
              <Modal.Title>
                <Row>
                  {`${username}'s Admission Cover Sheet`}
                  <CompletionStateIndicator
                    existing={!!existingCoverSheet}
                    newText={newFormMessage}
                    existingText={'Editing Completed Form'}
                    styles={{alignSelf: 'flex-end', marginLeft: 'auto', marginRight: '15px'}}
                  />
                </Row>
              </Modal.Title>
              <Col style={{paddingTop: '10px'}}>
                <Form noValidate={false} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                  <fieldset disabled={disabled}>
                    <StandardParentInputRow label={'Client Information'}>
                      <StandardTopPaddedRow>
                        <StandardInputColumn label={'First Name'} columnSize={4}>
                          <Input name={getFieldName('firstName')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'Last Name'} columnSize={5}>
                          <Input name={getFieldName('lastName')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'Middle Initial (can be blank)'} columnSize={3}>
                          <Input name={getFieldName('middleInitial')}/>
                        </StandardInputColumn>
                      </StandardTopPaddedRow>
                      <StandardTopPaddedRow>
                        <StandardInputColumn label={'Birth Date'} columnSize={4}>
                          <DatepickerInput name={getFieldName('dateOfBirth')} onChangeUtcHour={12} showYearDropdown={true}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'Street Address'} columnSize={4}>
                          <Input name={getFieldName('streetAddress')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'Apartment / Unit #'} columnSize={4}>
                          <Input name={getFieldName('apartmentOrUnit')}/>
                        </StandardInputColumn>
                      </StandardTopPaddedRow>
                      <StandardTopPaddedRow>
                        <StandardInputColumn label={'City'} columnSize={5}>
                          <Input name={getFieldName('city')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'State'} columnSize={3}>
                          <Input name={getFieldName('state')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'ZIP'} columnSize={4}>
                          <Input name={getFieldName('zipCode')}/>
                        </StandardInputColumn>
                      </StandardTopPaddedRow>
                      <StandardTopPaddedRow>
                        <StandardInputColumn label={'Primary Phone (can be blank)'} columnSize={6}>
                          <PhoneInputField name={getFieldName('primaryPhone')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'Secondary Phone (can be blank)'} columnSize={6}>
                          <PhoneInputField name={getFieldName('secondaryPhone')}/>
                        </StandardInputColumn>
                      </StandardTopPaddedRow>
                      <StandardTopPaddedRow>
                        <StandardInputColumn label={'Marital Status'} columnSize={4}>
                          <Input name={getFieldName('maritalStatus')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'Sex / Gender'} columnSize={4}>
                          <Input name={getFieldName('sexOrGender')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'Race / Ethnicity'} columnSize={4}>
                          <Input name={getFieldName('raceOrEthnicity')}/>
                        </StandardInputColumn>
                      </StandardTopPaddedRow>
                    </StandardParentInputRow>
                    <StandardParentInputRow label={'Emergency Contact Information'}>
                      <StandardTopPaddedRow>
                        <StandardInputColumn label={'First Name'} columnSize={4}>
                          <Input name={getFieldName('emergencyContactFirstName')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'Last Name'} columnSize={5}>
                          <Input name={getFieldName('emergencyContactLastName')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'Relationship'} columnSize={3}>
                          <Input name={getFieldName('emergencyContactRelationshipToClient')}/>
                        </StandardInputColumn>
                      </StandardTopPaddedRow>
                      <StandardTopPaddedRow>
                        <StandardInputColumn label={'Street Address'} columnSize={8}>
                          <Input name={getFieldName('emergencyContactStreetAddress')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'Apartment / Unit #'} columnSize={4}>
                          <Input name={getFieldName('emergencyContactApartmentOrUnit')}/>
                        </StandardInputColumn>
                      </StandardTopPaddedRow>
                      <StandardTopPaddedRow>
                        <StandardInputColumn label={'City'} columnSize={5}>
                          <Input name={getFieldName('emergencyContactCity')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'State'} columnSize={3}>
                          <Input name={getFieldName('emergencyContactState')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'ZIP'} columnSize={4}>
                          <Input name={getFieldName('emergencyContactZipCode')}/>
                        </StandardInputColumn>
                      </StandardTopPaddedRow>
                      <StandardTopPaddedRow>
                        <StandardInputColumn label={'Primary Phone (can be blank)'} columnSize={6}>
                          <PhoneInputField name={getFieldName('emergencyContactPrimaryPhone')}/>
                        </StandardInputColumn>
                        <StandardInputColumn label={'Secondary Phone (can be blank)'} columnSize={6}>
                          <PhoneInputField name={getFieldName('emergencyContactSecondaryPhone')}/>
                        </StandardInputColumn>
                      </StandardTopPaddedRow>
                    </StandardParentInputRow>
                  </fieldset>
                  {errorMessage !== '' ?
                    <div style={{marginTop: '1rem'}}>
                      <Alert variant='danger'>{errorMessage}</Alert>
                    </div>
                    : null}
                  <StandardTopPaddedRow style={{paddingTop: '10px'}}>
                    <BSForm.Group className={styles['form-buttons']}>
                      {renderButtons()}
                    </BSForm.Group>
                  </StandardTopPaddedRow>
                </Form>
              </Col>
            </Modal.Body>
          </Modal>
        )}
      </Formik>
      {renderCancelConfirmationDialog()}
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertAdmissionCoverSheet: admissionCoverSheetStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getUserById: userStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(NewParticipantChecklistModal);

