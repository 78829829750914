import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../../utils';
import {createStandardSelectors, getEntities, selector} from '../../selectors';
import {combineReducers} from 'redux';
import {CommonDispatch} from '../../index';
import {getAdmissionCoverSheet, upsertAdmissionCoverSheet} from '../../../../api/forms/formApi';
import {
  TAdmissionCoverSheetForm
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/AdmissionCoverSheetModal/AdmissionCoverSheetModal';

export interface AdmissionCoverSheet {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  middleInitial: string;
  dateOfBirth: string;
  streetAddress: string;
  apartmentOrUnit: string;
  city: string;
  state: string;
  zipCode: string;
  primaryPhone: string;
  secondaryPhone: string;
  maritalStatus: string;
  sexOrGender: string;
  raceOrEthnicity: string;
  emergencyContactFirstName: string;
  emergencyContactLastName: string;
  emergencyContactRelationshipToClient: string;
  emergencyContactStreetAddress: string;
  emergencyContactApartmentOrUnit: string;
  emergencyContactCity: string;
  emergencyContactState: string;
  emergencyContactZipCode: string;
  emergencyContactPrimaryPhone: string;
  emergencyContactSecondaryPhone: string;
  updatedOn: string;
}

const actions = createStandardActions(placeholder<AdmissionCoverSheet>(), 'ADMISSION_COVER_SHEET/SET', 'ADMISSION_COVER_SHEET/SAVE');
const selectors = createStandardSelectors(placeholder<AdmissionCoverSheet>(), s => getEntities(s).admissionCoverSheets);
export type AdmissionCoverSheetActions = GetActions<typeof actions>;
export const admissionCoverSheets = combineReducers({items: standardItemsReducer<AdmissionCoverSheet, AdmissionCoverSheetActions>(actions)});
export const admissionCoverSheetStore = readonly({
  selectors: {
    ...selectors,
    getByUserId: selector(s => (userId: string) => selectors.getAsArray(s).find(coverSheet => coverSheet.userId === userId))
  },
  actions: {
    ...actions,
    upsert: (form: TAdmissionCoverSheetForm) => async (dispatch: CommonDispatch) => {
      const response = await upsertAdmissionCoverSheet(form);
      await dispatch(actions.save(response));
    },
    getByUserId: (userId: string) => async (dispatch: CommonDispatch) => {
      const response = await getAdmissionCoverSheet(userId);
      if (response && response.id !== '')
        await dispatch(actions.save(response));
    }
  }
});
