import React, {useEffect, useMemo, useState} from 'react';
import gridStyles from '../../../../GridStyles.module.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/core/types/WebState';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import {Button} from 'react-bootstrap';
import BootstrapTable, {BootstrapTableProps, ColumnDescription, SearchProps} from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.js';
import {makeBtcDocument} from '../../../../../redux/web/factory';
import {Navigate} from 'react-router-dom';
import {BtcDocument, btcDocumentStore} from '../../../../../redux/web/entities/btcDocument';
import DocumentModal from '../UserModal/DocumentModal';
import styles
  from '../../../ParticipantClassManagement/components/ParticipantClassGrid/ParticipantClassGrid.module.scss';
import {User} from '../../../../../redux/web/entities/user';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function DocumentGrid(props: Props) {
  const {btcDocuments, getBtcDocumentById, actions: {getBtcDocumentDataById, upsertBtcDocument}} = props;
  const { SearchBar } = Search;
  const [isDeleting, setIsDeleting] = useState('');
  const [viewBtcDocumentModal, setViewBtcDocumentModal] = useState(false);
  const [editingBtcDocument, setEditingBtcDocument] = useState(makeBtcDocument(null));
  const [redirectUrl, setRedirectUrl] = useState('');
  const getFieldName = (name: keyof BtcDocument) => name;

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };


  const downloadDocument = (contentBase64: string, filename: string) => {
    // const linkSource = `data:application/pdf;base64,${contentBase64}`;
    const linkSource = `${contentBase64}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = filename;
    downloadLink.click();
  };

  const actionsFormatter = (cell: any, document: BtcDocument) => {
    return (
      <>
        <div className={gridStyles['table-actions-wrapper']}>
          <div>
            <IconButton
              icon='edit'
              size={'2x'}
              styles={{marginLeft: '.5rem'}}
              onClick={() => {
                setEditingBtcDocument(getBtcDocumentById(document.id));
                setViewBtcDocumentModal(true);
              }}
              color={'#005A9C'}
            />
          </div>
          <div>
            <IconButton
              icon='download'
              size={'2x'}
              styles={{marginLeft: '.5rem'}}
              onClick={() => {
                getBtcDocumentDataById(document.id);
                const documentData = getBtcDocumentById(document.id);
                downloadDocument(documentData.data, documentData.name);
              }}
              color={'#005A9C'}
            />
          </div>
        </div>
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      searchable: false,
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'actionsColumn',
      text: '',
      isDummyField: true,
      searchable: false,
      formatter: actionsFormatter,
      headerStyle: () => {
        return { width: '7rem'};
      },
      style: () => {
        return { width: '7rem'};
      }
    }
  ];

  return (
    <ToolkitProvider
      keyField='id'
      data={btcDocuments}
      columns={columns}
      search={{
        searchFormatted: true
      }}
    >
      {(tableProps: {
        baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<User, number>> & Readonly<BootstrapTableProps>;
        searchProps: SearchProps<BtcDocument>;
      }) => (
        <>
          <div className={gridStyles['button-groups-container']}>
            <div className={gridStyles['button-group']}>
              <Button
                onClick={() => {
                  setEditingBtcDocument(makeBtcDocument(null));
                  setViewBtcDocumentModal(true);
                }}
                style={{backgroundColor: '#005A9C'}}
              >
                Add Document
              </Button>
            </div>
          </div>
          <SearchBar {...tableProps.searchProps} placeholder={'Search'} />
          {renderRedirect()}
          <BootstrapTable
            wrapperClasses={gridStyles['responsive-table-wrapper']}
            classes={styles['table-auto']}
            rowStyle={{height: '100%'}}
            {...tableProps.baseProps}
          />
          {viewBtcDocumentModal ?
            <DocumentModal
              editable={true}
              onSubmit={() => {
                setViewBtcDocumentModal(false);
              }}
              onCancel={() => setViewBtcDocumentModal(false)}
              existingBtcDocument={editingBtcDocument}
            />
            : null}
        </>
      )}
    </ToolkitProvider>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertBtcDocument: btcDocumentStore.actions.upsert,
    getBtcDocumentDataById: btcDocumentStore.actions.getDocumentDataById
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  btcDocuments: btcDocumentStore.selectors.getAsArray(state),
  getBtcDocumentById: btcDocumentStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentGrid);
