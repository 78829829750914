import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../../utils';
import {createStandardSelectors, getEntities, selector} from '../../selectors';
import {combineReducers} from 'redux';
import {CommonDispatch} from '../../index';
import {getGoalSheet, upsertGoalSheet} from '../../../../api/forms/formApi';
import {
  TGoalSheetForm
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/GoalSheetModal/GoalSheetModal';

export interface Goal {
  goal: string;
}

export interface GoalSheet {
  id: string;
  userId: string;
  sixMonths: Goal[];
  oneYear: Goal[];
  fiveYears: Goal[];
  updatedOn: string;
}

const actions = createStandardActions(placeholder<GoalSheet>(), 'GOAL_SHEET/SET', 'GOAL_SHEET/SAVE');
const selectors = createStandardSelectors(placeholder<GoalSheet>(), s => getEntities(s).goalSheets);
export type GoalSheetActions = GetActions<typeof actions>;
export const goalSheets = combineReducers({items: standardItemsReducer<GoalSheet, GoalSheetActions>(actions)});
export const goalSheetStore = readonly({
  selectors: {
    ...selectors,
    getByUserId: selector(s => (userId: string) => selectors.getAsArray(s).find(goalSheet => goalSheet.userId === userId))
  },
  actions: {
    ...actions,
    upsert: (form: TGoalSheetForm) => async (dispatch: CommonDispatch) => {
      const response = await upsertGoalSheet(form);
      await dispatch(actions.save(response));
    },
    getByUserId: (userId: string) => async (dispatch: CommonDispatch) => {
      const response = await getGoalSheet(userId);
      if (response && response.id !== '')
        await dispatch(actions.save(response));
    }
  }
});
