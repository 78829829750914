import React from 'react';
import {Alert, Row} from 'react-bootstrap';
import {
  SecondaryIntake as SI,
  SecondaryIntakeFamilyAndMedicalHistory as SIFamilyMedical
} from '../../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {useFormikContext} from 'formik';
import Input from '../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {
  ESecondaryIntakeBirthStatus as EBirthStatus,
  ESecondaryIntakeCurrentHealthStatus as ECurrentHealthStatus
} from '../../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntakeHelperModels';
import {
  StandardCheckboxInput,
  StandardInputColumn,
  StandardParentInputRow,
  StandardRadioInput,
  StandardTopPaddedRow
} from '../../../../../../../components/util/form-components/standardLayout';
import DatepickerInput
  from '../../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {
  DeletableFieldArray
} from '../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/DeletableFieldArray';
import {
  FieldArrayRow
} from '../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/FieldArrayRow';
import {makeSecondaryIntakeMedication} from '../../../../../../../redux/web/factory/forms/intakeForms';
import {RedErrorMessage} from '../../../../../../../components/util/form-components/RedErrorMessage/RedErrorMessage';

// TODO add ethnicity
export const FamilyAndMedicalHistoryForm = () => {
  const {values, setFieldValue} = useFormikContext<SI>();
  const formValues = values.familyAndMedicalHistory;
  // return type not really needed, but it's cool you can do this
  const getFieldName = (name: keyof SIFamilyMedical): `${keyof SI}.${keyof SIFamilyMedical}` =>
    `familyAndMedicalHistory.${name}`;
  return (
    <>
      <StandardParentInputRow label={'Family History'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={`Mother's Name`} columnSize={6}>
            <Input name={getFieldName('mothersName')}/>
          </StandardInputColumn>
          <StandardInputColumn label={`Father's Name`} columnSize={6}>
            <Input name={getFieldName('fathersName')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Are these your biological parents?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('areBiologicalParents')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If no, please explain:'} columnSize={8}>
            <Input
              name={getFieldName('areNotBiologicalParentsDescription')}
              type={'textarea'}
              disabled={formValues.areBiologicalParents}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you have any siblings?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('hasAnySiblings')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, how many?'} columnSize={2} style={{marginRight: 'auto'}}>
            <Input
              name={getFieldName('siblingsCount')}
              disabled={!formValues.hasAnySiblings}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'What is the birth status in your family?'} columnSize={6}>
            <Row>
              <StandardRadioInput
                name={getFieldName('birthStatus')}
                label={'Only Child'}
                value={EBirthStatus.onlyChild}
                checked={formValues.birthStatus === EBirthStatus.onlyChild}
              />
              <StandardRadioInput
                name={getFieldName('birthStatus')}
                label={'First Child'}
                value={EBirthStatus.firstChild}
                checked={formValues.birthStatus === EBirthStatus.firstChild}
              />
              <StandardRadioInput
                name={getFieldName('birthStatus')}
                label={'Middle Child'}
                value={EBirthStatus.middleChild}
                checked={formValues.birthStatus === EBirthStatus.middleChild}
              />
              <StandardRadioInput
                name={getFieldName('birthStatus')}
                label={'Last Child'}
                value={EBirthStatus.lastChild}
                checked={formValues.birthStatus === EBirthStatus.lastChild}
              />
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you still have contact with your family?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasContactWithFamily')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, who?:'} columnSize={8}>
            <Input
              name={getFieldName('hasContactWithWhoInFamily')}
              type={'textarea'}
              disabled={!formValues.hasContactWithFamily}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Is there a history of abuse in your family?'} columnSize={4}>
            <StandardCheckboxInput
              name={getFieldName('hasHistoryOfAbuseInFamily')}
              label={'Yes'}
              onChange={(val: boolean) => {
                setFieldValue(getFieldName('hasHistoryOfAbuseInFamily'), val);
                if (!val) {
                  setFieldValue(getFieldName('familyHistoryPhysicalAbuse'), val);
                  setFieldValue(getFieldName('familyHistoryVerbalAbuse'), val);
                  setFieldValue(getFieldName('familyHistoryEmotionalAbuse'), val);
                  setFieldValue(getFieldName('familyHistorySexualAbuse'), val);
                }
              }}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, please check below:'} columnSize={8}>
            <Row>
              <StandardCheckboxInput
                name={getFieldName('familyHistoryEmotionalAbuse')}
                label={'Emotional'}
                style={{marginRight: '1rem'}}
                disabled={!formValues.hasHistoryOfAbuseInFamily}
              />
              <StandardCheckboxInput
                name={getFieldName('familyHistoryPhysicalAbuse')}
                label={'Physical'}
                style={{marginRight: '1rem'}}
                disabled={!formValues.hasHistoryOfAbuseInFamily}
              />
              <StandardCheckboxInput
                name={getFieldName('familyHistoryVerbalAbuse')}
                label={'Verbal'}
                style={{marginRight: '1rem'}}
                disabled={!formValues.hasHistoryOfAbuseInFamily}
              />
              <StandardCheckboxInput
                name={getFieldName('familyHistorySexualAbuse')}
                label={'Sexual'}
                disabled={!formValues.hasHistoryOfAbuseInFamily}
              />
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Were you the victim of family abuse?'} columnSize={4}>
            <StandardCheckboxInput
              name={getFieldName('wasVictimOfFamilyAbuse')}
              label={'Yes'}
              onChange={(val: boolean) => {
                setFieldValue(getFieldName('wasVictimOfFamilyAbuse'), val);
                if (!val) {
                  setFieldValue(getFieldName('familyVictimPhysicalAbuse'), val);
                  setFieldValue(getFieldName('familyVictimVerbalAbuse'), val);
                  setFieldValue(getFieldName('familyVictimEmotionalAbuse'), val);
                  setFieldValue(getFieldName('familyVictimSexualAbuse'), val);
                }
              }}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, please check below:'} columnSize={8}>
            <Row>
              <StandardCheckboxInput
                name={getFieldName('familyVictimEmotionalAbuse')}
                label={'Emotional'}
                style={{marginRight: '1rem'}}
                disabled={!formValues.wasVictimOfFamilyAbuse}
              />
              <StandardCheckboxInput
                name={getFieldName('familyVictimPhysicalAbuse')}
                label={'Physical'}
                style={{marginRight: '1rem'}}
                disabled={!formValues.wasVictimOfFamilyAbuse}
              />
              <StandardCheckboxInput
                name={getFieldName('familyVictimVerbalAbuse')}
                label={'Verbal'}
                style={{marginRight: '1rem'}}
                disabled={!formValues.wasVictimOfFamilyAbuse}
              />
              <StandardCheckboxInput
                name={getFieldName('familyVictimSexualAbuse')}
                label={'Sexual'}
                disabled={!formValues.wasVictimOfFamilyAbuse}
              />
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If there is anything else about your abuse or childhood that you think we should know, please use the space below:'}>
            <Input name={getFieldName('familyHistoryOtherDescription')} type={'textarea'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Medical'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Height'} columnSize={4}>
            <Input name={getFieldName('height')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Weight'} columnSize={4}>
            <Input name={getFieldName('weight')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Do you wear glasses?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('wearsGlasses')} label={'Yes'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you had a TB test?'} columnSize={2}>
            <StandardCheckboxInput name={getFieldName('hasHadTbTest')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, when and where:'} columnSize={6}>
            <Input name={getFieldName('hasHadTbTestWhenAndWhere')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Results'} columnSize={4}>
            <Input name={getFieldName('tbTestResults')} disabled={!formValues.hasHadTbTest} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Are you pregnant?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('isPregnant')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, due date:'} columnSize={3} style={{marginRight: 'auto'}}>
            <DatepickerInput
              name={getFieldName('isPregnantDueDate')}
              disabled={!formValues.isPregnant}
              valueOnDisabled={null}
              onChangeUtcHour={12}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'What is your current health status?'} columnSize={5}>
            <Row>
              <StandardRadioInput
                name={getFieldName('currentHealthStatus')}
                label={'Excellent'}
                value={ECurrentHealthStatus.excellent}
                checked={formValues.currentHealthStatus === ECurrentHealthStatus.excellent}
              />
              <StandardRadioInput
                name={getFieldName('currentHealthStatus')}
                label={'Good'}
                value={ECurrentHealthStatus.good}
                checked={formValues.currentHealthStatus === ECurrentHealthStatus.good}
              />
              <StandardRadioInput
                name={getFieldName('currentHealthStatus')}
                label={'Fair'}
                value={ECurrentHealthStatus.fair}
                checked={formValues.currentHealthStatus === ECurrentHealthStatus.fair}
              />
              <StandardRadioInput
                name={getFieldName('currentHealthStatus')}
                label={'Poor'}
                value={ECurrentHealthStatus.poor}
                checked={formValues.currentHealthStatus === ECurrentHealthStatus.poor}
              />
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you have any current medical issues?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasCurrentMedicalIssues')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, please describe:'} columnSize={8}>
            <Input
              name={getFieldName('hasCurrentMedicalIssuesDescription')}
              type={'textarea'}
              disabled={!formValues.hasCurrentMedicalIssues}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever had cancer?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('hasHadCancer')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, what kind and when?'} columnSize={5}>
            <Input
              name={getFieldName('hasHadCancerKindAndWhen')}
              type={'textarea'}
              disabled={!formValues.hasHadCancer}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'How long has it been in remission?'} columnSize={4}>
            <Input
              name={getFieldName('cancerRemissionLength')}
              type={'textarea'}
              disabled={!formValues.hasHadCancer}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you have any physical limitations?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('hasPhysicalLimitations')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, please describe:'} columnSize={5}>
            <Input name={getFieldName('hasPhysicalLimitationsDescription')} type={'textarea'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Are you currently on any medications?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('isOnMedications')} label={'Yes'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If you are on medications, please list below:'}>
            <DeletableFieldArray
              name={getFieldName('medications')}
              values={formValues.medications}
              factoryFn={makeSecondaryIntakeMedication}
              disabled={!formValues.isOnMedications}
              noItemsLabel={'No medications added.'}
              renderRow={(item, rowName, getItemName, disabled) => (
                <FieldArrayRow style={{flex: 'auto', minWidth: '13rem', marginRight: '1.5rem'}}>
                  <StandardInputColumn label={'Name'} columnSize={4} className='pl-2'>
                    <Input name={`${rowName}.${getItemName('name')}`} disabled={disabled}/>
                  </StandardInputColumn>
                  <StandardInputColumn label={'Dosage'} columnSize={4} className='pl-2'>
                    <Input name={`${rowName}.${getItemName('dose')}`} disabled={disabled}/>
                  </StandardInputColumn>
                  <StandardInputColumn label={'Frequency'} columnSize={4} className='pl-2'>
                    <Input name={`${rowName}.${getItemName('frequency')}`} disabled={disabled}/>
                  </StandardInputColumn>
                </FieldArrayRow>
              )}
              renderFooter={() => (
                <>
                  {!formValues.isOnMedications && formValues.medications.length ?
                    <Alert variant='warning' style={{margin: '15px'}}>
                      Arrests will not be submitted if the field is disabled.
                    </Alert> : null}
                  <RedErrorMessage name={getFieldName('medications')}/>
                </>
              )}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Should you be on any medications?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('shouldBeOnMedications')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, please describe:'} columnSize={8}>
            <Input
              name={getFieldName('shouldBeOnMedicationsDescription')}
              type={'textarea'}
              disabled={!formValues.shouldBeOnMedications}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you have any mental health or behavioral health issues?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasMentalOrBehavioralHealthIssues')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, please describe:'} columnSize={8}>
            <Input
              name={getFieldName('hasMentalOrBehavioralHealthIssuesDescription')}
              type={'textarea'}
              disabled={!formValues.hasMentalOrBehavioralHealthIssues}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever been hospitalized?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasBeenHospitalized')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, please describe:'} columnSize={8}>
            <Input
              name={getFieldName('hasBeenHospitalizedDescription')}
              type={'textarea'}
              disabled={!formValues.hasBeenHospitalized}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever been placed on a 51/50 hold?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasBeenOnFiftyOneFiftyHold')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, please describe:'} columnSize={8}>
            <Input
              name={getFieldName('hasBeenOnFiftyOneFiftyHoldDescription')}
              type={'textarea'}
              disabled={!formValues.hasBeenOnFiftyOneFiftyHold}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever had an STD, (Sexually Transmitted Disease)?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasHadStd')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, please describe:'} columnSize={8}>
            <Input
              name={getFieldName('hasHadStdDescription')}
              type={'textarea'}
              disabled={!formValues.hasHadStd}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={`Physician's Name and Number`} columnSize={6}>
            <Input name={getFieldName('physicianNameAndNumber')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Name(s) of physician(s) that have treated you:'} columnSize={6}>
            <Input name={getFieldName('physiciansThatHaveTreatedNames')} type={'textarea'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Please check any of the following issues that you may have experienced in the last 30 days:'}>
            <Row>
              <StandardCheckboxInput name={getFieldName('heartProblems')} label={'Heart Problems'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('staph')} label={'Staph'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('lice')} label={'Lice'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('seizures')} label={'Seizures'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('headInjury')} label={'Head Injury'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('bedBugs')} label={'Bed Bugs'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('ringWorm')} label={'Ring Worm'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('rash')} label={'Rash'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('backProblems')} label={'Back Problems'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('dts')} label={`DT's`} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('depression')} label={'Depression'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('anxiety')} label={'Anxiety'} style={{marginRight: '1.5rem'}}/>
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
    </>
  );
};
