import {
  number,
  object, string, mixed,
} from 'yup';
import {User} from '../../../../../redux/web/entities/user';
import {BtcDocument} from '../../../../../redux/web/entities/btcDocument';



// @ts-ignore
export const DocumentModalSchema = object<BtcDocument>({
  id: string().nullable(),
  name: string().required('Name is required')
});
