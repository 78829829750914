import {
  TPrimaryIntakeUpsertForm
} from '../../../../pages/Configuration/UserManagement/components/UserGrid/PrimaryIntakeModal/PrimaryIntakeModal';
import {
  EPrimaryIntakeEthnicity,
  EPrimaryIntakeGenderIdentification,
  EPrimaryIntakeHelpType,
  EPrimaryIntakeHumanTraffickingType,
  EPrimaryIntakeLanguage,
  EPrimaryIntakeRelocation,
  EPrimaryIntakeResidencyType
} from '../../entities/forms/intake/primaryIntake/primaryIntakeHelperModels';
import {
  TAssessmentQuestionsForm
} from '../../../../pages/Configuration/UserManagement/components/UserGrid/PrimaryIntakeModal/components/forms/AssessmentQuestionsForm';
import {
  TPersonalInformationForm
} from '../../../../pages/Configuration/UserManagement/components/UserGrid/PrimaryIntakeModal/components/forms/PersonalInformationForm';
import {
  THumanTraffickingIdentificationQuestionsForm,
  TParProgramQuestionsForm
} from '../../../../pages/Configuration/UserManagement/components/UserGrid/PrimaryIntakeModal/components/forms/HumanTraffickingIdentificationAndParQuestionsForm';
import {
  THumanTraffickingInformationForm
} from '../../../../pages/Configuration/UserManagement/components/UserGrid/PrimaryIntakeModal/components/forms/HumanTraffickingInformationForm';
import {
  TClosingInformationForm
} from '../../../../pages/Configuration/UserManagement/components/UserGrid/PrimaryIntakeModal/components/forms/ClosingInformationForm';
import {PrimaryIntake, PrimaryIntakeChildInformation} from '../../entities/forms/intake/primaryIntake/PrimaryIntake';
import {
  SecondaryIntake,
  SecondaryIntakeArrest,
  SecondaryIntakeChildNameAndAge,
  SecondaryIntakeCitizenshipAndIncomeInformation,
  SecondaryIntakeCommentsAndConcerns,
  SecondaryIntakeCriminalAndHousing,
  SecondaryIntakeEducationAndEmployment,
  SecondaryIntakeFamilyAndMedicalHistory,
  SecondaryIntakeHouseholdResident,
  SecondaryIntakeMedication,
  SecondaryIntakePersonalEmergencyInformationAndLifeIssues,
  SecondaryIntakePreviousEmployer,
  SecondaryIntakeSubstanceAbuseAndTraffickingInformation
} from '../../entities/forms/intake/secondaryIntake/secondaryIntake';
import {
  ESecondaryIntakeBirthStatus,
  ESecondaryIntakeCurrentHealthStatus,
  ESecondaryIntakeEthnicity,
  ESecondaryIntakeGenderIdentification,
  ESecondaryIntakeHomelessStatus,
} from '../../entities/forms/intake/secondaryIntake/secondaryIntakeHelperModels';
import {AdmissionCoverSheet} from '../../entities/forms/admissionCoverSheet';

export function makeChildInformation(): PrimaryIntakeChildInformation {
  return {
    id: '',
    name: '',
    birthDate: '',
    livesWith: ''
  };
}

export function makePersonalInformation(): TPersonalInformationForm {
  return {
    city: '',
    state: '',
    email: '',
    apartmentOrUnit: '',
    birthDate: '',
    childrenInformation: [],
    firstName: '',
    lastName: '',
    maritalStatus: '',
    middleInitial: '',
    primaryPhone: '+1',
    secondaryPhone: '+1',
    zipCode: '',
    streetAddress: '',
    identifyAs: EPrimaryIntakeGenderIdentification.preferNotToAnswer,
    ethnicity: EPrimaryIntakeEthnicity.other,
    primaryLanguage: EPrimaryIntakeLanguage.english,
    primaryLanguageOther: '',
    interpreterRequired: false
  };
}

export function makeHumanTraffickingIdentification(): THumanTraffickingIdentificationQuestionsForm {
  return {
    experiencedHumanTrafficking: false,
    illegalFinancialCompensation: false,
    otherInformation: '',
    tradedSexualFavors: false,
    tradedSexualFavorsDescription: '',
    unfairLaborExpectations: false
  };
}

export function makeParProgramQuestions(): TParProgramQuestionsForm {
  return {
    arrestedForSex: false,
    arrestedCounty: '',
    caseNumber: ''
  };
}

export function makeAssessmentQuestions(): TAssessmentQuestionsForm {
  return {
    type: '',
    county: '',
    cpsOrCustodialCases: '',
    crimeOccurrence: false,
    crimeOccurrenceDescription: '',
    depression: false,
    drugsUsed: '',
    existingMedicalConditions: false,
    existingMedicalConditionsDescriptions: '',
    fiftyOneFiftyHold: false,
    fiftyOneFiftyHoldDescription: '',
    hearsVoices: false,
    helpSeeking: '',
    hospitalization: false,
    hospitalizationDescription: '',
    income: '',
    insurance: false,
    licensePlateNumber: '',
    make: '',
    physicalDisability: false,
    developmentDisability: false,
    learningDisability: false,
    hearingDisability: false,
    visionDisability: false,
    otherDisability: false,
    otherDisabilityDescription: '',
    medicalIdNumber: '',
    medicationNamesAndDescriptions: '',
    medications: false,
    mentalHealthIssues: false,
    mentalHealthIssuesDescription: '',
    mentalHealthIssuesNames: '',
    model: '',
    officerName: '',
    onProbationOrParole: false,
    helpOther: '',
    otherResources: '',
    outpatientTreatmentProgram: false,
    outpatientTreatmentProgramDescription: '',
    pendingLegalMatters: false,
    questions: '',
    pendingLegalMattersDates: '',
    pendingLegalMattersDescription: '',
    probationOrParoleDescription: '',
    psychiatricHold: false,
    psychiatricHoldDescription: '',
    helpReason: '',
    residentialTreatmentProgram: false,
    smokes: false,
    residentialTreatmentProgramDescription: '',
    smokingLength: '',
    sobrietyDate: '',
    vehicle: false,
    voicesOrDepressionExplanation: '',
    willingToQuit: false,
    seekingHousing: false,
    seekingFood: false,
    seekingClothing: false,
    seekingCounseling: false,
    seekingRelocation: false,
    seekingLegalAdvice: false,
    seekingFinancialAssistance: false,
    seekingOtherHelp: false
  };
}

export function makeHumanTraffickingInformation(): THumanTraffickingInformationForm {
  return {
    ageOfEntry: '',
    trafficker: '',
    lengthOfTime: '',
    nickname: '',
    safeLocation: '',
    safetyConcerns: '',
    city: '',
    when: '',
    typeOfExploitation:  '',
    relocation: EPrimaryIntakeRelocation.yes
  };
}

export function makeClosingInformation(): TClosingInformationForm {
  return {
    commentsAndConcerns: '',
    residencyType: EPrimaryIntakeResidencyType.stillToBeDetermined,
    referredTo: '',
    personWhoDidAssessment: ''
  };
}

export function makePrimaryIntakeForm(existingIntake?: PrimaryIntake): TPrimaryIntakeUpsertForm {
  return existingIntake ? {
    ...existingIntake,
    parProgramQuestions: existingIntake.parProgramQuestions ?? makeParProgramQuestions(),
    assessmentQuestions: existingIntake.assessmentQuestions ?? makeAssessmentQuestions(),
    humanTraffickingInformation: existingIntake.humanTraffickingInformation ?? makeHumanTraffickingInformation(),
    closingInformation: existingIntake.closingInformation ?? makeClosingInformation()
  } : {
    employeeName: '',
    referredBy: '',
    reasonForCall: '',
    fpdReferral: false,
    personalInformation: makePersonalInformation(),
    humanTraffickingIdentificationQuestions: makeHumanTraffickingIdentification(),
    parProgramQuestions: makeParProgramQuestions(),
    assessmentQuestions: makeAssessmentQuestions(),
    humanTraffickingInformation: makeHumanTraffickingInformation(),
    closingInformation: makeClosingInformation(),
    updatedOn: ''
  };
}

export function makePrefilledIntakeForm(): TPrimaryIntakeUpsertForm {
  const recursiveMap:
    (object: object) => (any[] | { [p: string]: string } | { [p: string]: any })[] | { [p: string]: string } | { [p: string]: any } = (object: object) => {
    return Object.entries(object).reduce((acc, entry) => {
      const [key, value] = entry;
      if (Array.isArray(value) || key === 'id' || key === 'userId' || key === 'updatedOn')
        return {...acc, [key]: value};
      if (typeof value === 'object')
        return {...acc, [key]: recursiveMap(value)};
      if (key === 'updatedAt' || key === 'birthDate')
        return {...acc, [key]: new Date() as any};
      if (key === 'primaryPhone' || key === 'secondaryPhone')
        return {...acc, [key]: '+1'};
      if (typeof value === 'string') {
        return {...acc, [key]: 'pre-filled'};
      }
      return {...acc, [key]: value};
    }, {});
  };
  return recursiveMap(makePrimaryIntakeForm()) as any as TPrimaryIntakeUpsertForm;
}

export function makeSecondaryIntakeChildNameAndAge(): SecondaryIntakeChildNameAndAge {
  return {
    id: '',
    name: '',
    birthDate: ''
  };
}

export function makeSecondaryIntakeArrest(): SecondaryIntakeArrest {
  return {
    id: '',
    when: '',
    description: ''
  };
}

// tslint:disable-next-line:max-line-length
export function makeSecondaryIntakePersonalEmergencyInformationAndLifeIssues(
  primary?: PrimaryIntake, admission?: AdmissionCoverSheet): SecondaryIntakePersonalEmergencyInformationAndLifeIssues {
    const convertPrimaryIntakeChild = (child: PrimaryIntakeChildInformation): SecondaryIntakeChildNameAndAge => {
      return {
        id: '',
        name: child.name,
        birthDate: child.birthDate
      };
    };
    return {
      id: '',
      firstName: primary?.personalInformation?.firstName ?? '',
      lastName: primary?.personalInformation?.lastName ?? '',
      middleInitial: primary?.personalInformation?.middleInitial ?? '',
      otherNames: '',
      streetAddress: primary?.personalInformation?.streetAddress ?? '',
      apartmentOrUnit: primary?.personalInformation?.apartmentOrUnit ?? '',
      city: primary?.personalInformation?.city ?? '',
      state: primary?.personalInformation?.state ?? '',
      zipCode: primary?.personalInformation?.zipCode ?? '',
      primaryPhone: primary?.personalInformation?.primaryPhone ?? '+1',
      secondaryPhone: primary?.personalInformation?.secondaryPhone ?? '+1',
      // TODO
      identifyAs: ESecondaryIntakeGenderIdentification.female,
      email: primary?.personalInformation?.email ?? '',
      ssnOrGovtId: '',
      birthDate: primary?.personalInformation?.birthDate ?? '',
      maritalStatus: primary?.personalInformation?.maritalStatus ?? '',
      // TODO
      ethnicity: ESecondaryIntakeEthnicity.asian,
      otherRaceEthnicity: '',
      hasChildren: !!primary?.personalInformation?.childrenInformation?.length,
      // TODO
      childrenNamesAndAges: primary?.personalInformation?.childrenInformation.map((child) => convertPrimaryIntakeChild(child)) ?? [],
      hasParticipatedInBtc: false,
      hasBeenInShelterOrProgram: false,
      hasUsedDrugsOrAlcohol: false,
      drugsUsed: '',
      drugsUsedDate: '',
      hasBeenArrested: false,
      // TODO
      arrestDatesAndDescriptions: [],
      ecFirstName: admission?.emergencyContactFirstName ?? '',
      ecLastName: admission?.emergencyContactLastName ?? '',
      ecRelationship: admission?.emergencyContactRelationshipToClient ?? '',
      ecOtherNames: '',
      ecStreetAddress: admission?.emergencyContactStreetAddress ?? '',
      ecApartmentOrUnit: admission?.emergencyContactApartmentOrUnit ?? '',
      ecCity: admission?.emergencyContactCity ?? '',
      ecState: admission?.emergencyContactState ?? '',
      ecZipCode: admission?.emergencyContactZipCode ?? '',
      ecPrimaryPhone: admission?.emergencyContactPrimaryPhone ?? '+1',
      ecSecondaryPhone: admission?.emergencyContactSecondaryPhone ?? '+1'
    };
}

export function makeSecondaryIntakeCitizenshipAndIncomeInformation(): SecondaryIntakeCitizenshipAndIncomeInformation {
  return {
    id: '',
    bornAt: '',
    usCitizen: false,
    placeOfCitizenship: '',
    lengthOfUsLocation: '',
    hasUsedOtherNames: false,
    otherNames: '',
    sourceOfIncome: '',
    grossAnnual: '',
    grossMonth: '',
    afdc: false,
    calFresh: false,
    generalRelief: false,
    disability: false,
    ssi: false,
    ssa: false,
    ss: false,
    veteransBenefits: false,
    nativeAmerican: false,
    medicaid: false,
    medical: false,
    earnedEmployment: false,
    employerName: '',
    otherExplanation: '',
    socialWorkerName: '',
    socialWorkerPhone: '',
    checkingOrSavingsAccount: false,
    isAnythingWouldPreventAccount: false,
    isAnythingWouldPreventAccountDescription: ''
  };
}

export function makeSecondaryIntakeSubstanceAbuseAndTraffickingInformation(primary?: PrimaryIntake): SecondaryIntakeSubstanceAbuseAndTraffickingInformation {
  return {
    id: '',
    drugsAndAlcoholFrequency: '',
    blackoutOrSeizure: false,
    blackoutOrSeizureSubstance: '',
    blackoutOrSeizureDescription: '',
    parentsUsedDrugs: false,
    parentsUsedDrugsDescription: '',
    usedSubstancesDuringProstitution: false,
    receivedSubstanceAbuseTreatment: false,
    receivedSubstanceAbuseTreatmentDescription: '',
    usedSubstancesPriorToSexTrafficking: false,
    ageOfFirstSubstanceUse: '',
    hasEngagedInSexOrLaborTrade: primary?.humanTraffickingIdentificationQuestions?.experiencedHumanTrafficking ?? false,
    ageOfFirstTrafficking: primary?.humanTraffickingInformation?.ageOfEntry  ?? '',
    wasInRelationshipDuringTrafficking: false,
    hasBeenArrested: primary?.parProgramQuestions?.arrestedForSex ?? false,
    hadTrafficker: (primary?.humanTraffickingInformation?.trafficker !== ('N/A' || 'NA')),
    traffickerWasRelatedToProstitution: false,
    wasInFosterCare: false,
    wasInFosterCaseLength: '',
    hasHadPoliceContact: false,
    hasRunawayAsChild: false,
    freedomWasInJeopardy: false,
    freedomWasInJeopardyDueToSexTrafficking: false,
    freedomWasInJeopardyDueToLaborTrafficking: false,
    freedomWasInJeopardyOtherDescription: '',
    freedomWasInJeopardySexOrLaborAge: '',
    freedomWasInJeopardySexOrLaborDescription: ''
  };
}

export function makeSecondaryIntakeMedication(): SecondaryIntakeMedication {
  return {
    id: '',
    name: '',
    dose: '',
    frequency: ''
  };
}

export function makeSecondaryIntakeFamilyAndMedicalHistory(primary?: PrimaryIntake): SecondaryIntakeFamilyAndMedicalHistory {
  return {
    id: '',
    mothersName: '',
    fathersName: '',
    areBiologicalParents: false,
    areNotBiologicalParentsDescription: '',
    hasAnySiblings: false,
    siblingsCount: '',
    birthStatus: ESecondaryIntakeBirthStatus.firstChild,
    hasContactWithFamily: false,
    hasContactWithWhoInFamily: '',
    hasHistoryOfAbuseInFamily: false,
    familyHistoryEmotionalAbuse: false,
    familyHistoryPhysicalAbuse: false,
    familyHistoryVerbalAbuse: false,
    familyHistorySexualAbuse: false,
    wasVictimOfFamilyAbuse: false,
    familyVictimEmotionalAbuse: false,
    familyVictimPhysicalAbuse: false,
    familyVictimVerbalAbuse: false,
    familyVictimSexualAbuse: false,
    familyHistoryOtherDescription: '',
    height: '',
    weight: '',
    wearsGlasses: false,
    hasHadTbTest: false,
    hasHadTbTestWhenAndWhere: '',
    tbTestResults: false,
    isPregnant: false,
    isPregnantDueDate: '',
    currentHealthStatus: ESecondaryIntakeCurrentHealthStatus.excellent,
    hasCurrentMedicalIssues: false,
    hasCurrentMedicalIssuesDescription: '',
    hasHadCancer: false,
    hasHadCancerKindAndWhen: '',
    cancerRemissionLength: '',
    hasPhysicalLimitations: primary?.assessmentQuestions?.physicalDisability ?? false,
    hasPhysicalLimitationsDescription: '',
    medications: [],
    isOnMedications: primary?.assessmentQuestions?.medications ?? false,
    shouldBeOnMedications: false,
    shouldBeOnMedicationsDescription: '',
    hasMentalOrBehavioralHealthIssues: primary?.assessmentQuestions?.mentalHealthIssues ?? false,
    hasMentalOrBehavioralHealthIssuesDescription: primary?.assessmentQuestions?.mentalHealthIssuesDescription ?? '',
    hasBeenHospitalized: primary?.assessmentQuestions?.hospitalization ?? false,
    hasBeenHospitalizedDescription: primary?.assessmentQuestions?.hospitalizationDescription ?? '',
    hasBeenOnFiftyOneFiftyHold: primary?.assessmentQuestions?.fiftyOneFiftyHold ?? false,
    hasBeenOnFiftyOneFiftyHoldDescription: primary?.assessmentQuestions?.fiftyOneFiftyHoldDescription ?? '',
    hasHadStd: false,
    hasHadStdDescription: '',
    physicianNameAndNumber: '',
    physiciansThatHaveTreatedNames: '',
    heartProblems: false,
    staph: false,
    lice: false,
    seizures: false,
    headInjury: false,
    bedBugs: false,
    ringWorm: false,
    rash: false,
    backProblems: false,
    dts: false,
    depression: false,
    anxiety: false
  };
}

export function makeSecondaryIntakePreviousEmployer(): SecondaryIntakePreviousEmployer {
  return {
    id: '',
    name: '',
    title: '',
    supervisorName: '',
    contactNumber: ''
  };
}

export function makeSecondaryIntakeEducationAndEmployment(): SecondaryIntakeEducationAndEmployment {
  return {
    id: '',
    schoolAttending: '',
    hasGraduatedHighSchool: false,
    highSchoolGraduatedFrom: '',
    hasGed: false,
    hasGedFrom: '',
    hasAttendedCollege: false,
    highestLevelOfEducationCompleted: '',
    hasAttendedTradeSchool: false,
    tradeSchoolType: '',
    hasGraduatedTradeSchool: false,
    dateGraduatedFromTradeSchool: '',
    wouldLikeToFurtherEducation: false,
    isCurrentlyEmployed: false,
    currentEmployer: '',
    previousEmployers: []
  };
}

export function makeSecondaryIntakeHouseholdResident(): SecondaryIntakeHouseholdResident {
  return {
    id: '',
    name: '',
    birthDate: '',
    relation: ''
  };
}

export function makeSecondaryIntakeCriminalAndHousing(primary?: PrimaryIntake): SecondaryIntakeCriminalAndHousing {
  return {
    id: '',
    isOnProbationOrParole: primary?.assessmentQuestions?.onProbationOrParole ?? false,
    probationOrParoleDescription: primary?.assessmentQuestions?.probationOrParoleDescription ?? '',
    poNameAndNumber: '',
    pendingCourtMatters: false,
    pendingCourtMattersDescription: '',
    hasWarrants: false,
    warrantsDescription: '',
    homelessStatus: ESecondaryIntakeHomelessStatus.notHomeless,
    homelessLength: '',
    firstTimeBeingHomeless: false,
    currentResidence: '',
    currentResidenceLength: '',
    householdResidents: []
  };
}

export function makeSecondaryIntakeCommentsAndConcerns(): SecondaryIntakeCommentsAndConcerns {
  return {
    id: '',
    commentsOrConcerns: ''
  };
}

export function makeSecondaryIntake(
  userId: string, existing?: SecondaryIntake, primaryIntake?: PrimaryIntake, admissionCoverSheet?: AdmissionCoverSheet): SecondaryIntake {
    return existing ? {...existing} : {
      id: '',
      userId: userId,
      personalEmergencyInformationAndLifeIssues: makeSecondaryIntakePersonalEmergencyInformationAndLifeIssues(primaryIntake, admissionCoverSheet),
      citizenshipAndIncomeInformation: makeSecondaryIntakeCitizenshipAndIncomeInformation(),
      substanceAbuseAndTraffickingInformation: makeSecondaryIntakeSubstanceAbuseAndTraffickingInformation(primaryIntake),
      familyAndMedicalHistory: makeSecondaryIntakeFamilyAndMedicalHistory(primaryIntake),
      educationAndEmployment: makeSecondaryIntakeEducationAndEmployment(),
      criminalAndHousing: makeSecondaryIntakeCriminalAndHousing(primaryIntake),
      commentsAndConcerns: makeSecondaryIntakeCommentsAndConcerns(),
      updatedOn: ''
    };
}

export function makePrefilledSecondaryIntakeForm(userId: string): SecondaryIntake {
  const recursiveMap:
    (object: object) => (any[] | { [p: string]: string } | { [p: string]: any })[] | { [p: string]: string } | { [p: string]: any } = (object: object) => {
    return Object.entries(object).reduce((acc, entry) => {
      const [key, value] = entry;
      if (Array.isArray(value) || key === 'id' || key === 'userId' || key === 'updatedOn')
        return {...acc, [key]: value};
      if (key === 'updatedAt' || key === 'birthDate' || key === 'drugsUsedDate' || key === 'isPregnantDueDate' || key === 'dateGraduatedFromTradeSchool')
        return {...acc, [key]: new Date() as any};
      if (typeof value === 'object')
        return {...acc, [key]: recursiveMap(value)};
      if (key === 'primaryPhone' || key === 'secondaryPhone' || key === 'ecPrimaryPhone' || key === 'ecSecondaryPhone')
        return {...acc, [key]: ''};
      if (typeof value === 'string') {
        return {...acc, [key]: 'pre-filled'};
      }
      return {...acc, [key]: value};
    }, {});
  };
  return recursiveMap(makeSecondaryIntake(userId)) as SecondaryIntake;
}
