import {Dictionary} from '../../util';
import {User, userStore} from '../entities/user';
import {Dispatch} from 'redux';
import {batchActions} from 'redux-batched-actions';
import {ParticipantClass, participantClassStore} from '../entities/participantClass';
import {getCurrentUserParticipantClasses, getParticipantClassManagementState} from '../../../api/participantClassApi';

export interface ParticipantClassManagementState {
  users: Dictionary<User>;
  participantClasses: Dictionary<ParticipantClass>;
}

export const loadParticipantClassManagementData = () => async (dispatch: Dispatch) => {
  const response: ParticipantClassManagementState = await getParticipantClassManagementState();
  await dispatch(batchActions([
    dispatch(userStore.actions.set(response.users)),
    dispatch(participantClassStore.actions.set(response.participantClasses))
  ], 'LOAD_PARTICIPANT_CLASS_MANAGEMENT_STATE'));
};
