import {
  EPrimaryIntakeEthnicity,
  EPrimaryIntakeGenderIdentification,
  EPrimaryIntakeHumanTraffickingType, EPrimaryIntakeLanguage,
  EPrimaryIntakeRelocation,
  EPrimaryIntakeResidencyType
} from './primaryIntakeHelperModels';
import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../../../../utils';
import {createStandardSelectors, getEntities, selector} from '../../../../selectors';
import {combineReducers} from 'redux';
import {CommonDispatch} from '../../../../index';
import {getPrimaryIntake} from '../../../../../../api/forms/formApi';

export interface PrimaryIntakeChildInformation {
  id: string;
  name: string;
  birthDate: string;
  livesWith: string;
}

export interface PrimaryIntakePersonalInformation {
  id: string;
  firstName: string;
  lastName: string;
  middleInitial: string;
  streetAddress: string;
  apartmentOrUnit: string;
  city: string;
  state: string;
  zipCode: string;
  primaryPhone: string;
  secondaryPhone: string;
  identifyAs: EPrimaryIntakeGenderIdentification;
  email: string;
  birthDate: string;
  maritalStatus: string;
  ethnicity: EPrimaryIntakeEthnicity;
  primaryLanguage: EPrimaryIntakeLanguage;
  primaryLanguageOther: string;
  interpreterRequired: boolean;
  childrenInformation: PrimaryIntakeChildInformation[];
}

export interface PrimaryIntakeHumanTraffickingIdentificationQuestions {
  id: string;
  experiencedHumanTrafficking: boolean;
  tradedSexualFavors: boolean;
  tradedSexualFavorsDescription: string;
  unfairLaborExpectations: boolean;
  illegalFinancialCompensation: boolean;
  otherInformation: string;
}

export interface PrimaryIntakeParProgramQuestions {
  id: string;
  arrestedForSex: boolean;
  arrestedCounty: string;
  caseNumber: string;
}

export interface PrimaryIntakeAssessmentQuestions {
  id: string;
  // Help Description
  helpSeeking: string;
  seekingHousing: boolean;
  seekingFood: boolean;
  seekingClothing: boolean;
  seekingCounseling: boolean;
  seekingRelocation: boolean;
  seekingLegalAdvice: boolean;
  seekingFinancialAssistance: boolean;
  seekingOtherHelp: boolean;
  helpReason: string;
  helpOther: string;
  // Income / Insurance
  income: string;
  otherResources: string;
  insurance: boolean;
  type: string;
  medicalIdNumber: string;
  // Vehicle Information
  vehicle: boolean;
  make: string;
  model: string;
  licensePlateNumber: string;
  // Health History
  physicalDisability: boolean;
  developmentDisability: boolean;
  learningDisability: boolean;
  hearingDisability: boolean;
  visionDisability: boolean;
  otherDisability: boolean;
  otherDisabilityDescription: string;
  mentalHealthIssues: boolean;
  mentalHealthIssuesNames: string;
  mentalHealthIssuesDescription: string;
  medications: boolean;
  medicationNamesAndDescriptions: string;
  existingMedicalConditions: boolean;
  existingMedicalConditionsDescriptions: string;
  hospitalization: boolean;
  hospitalizationDescription: string;
  psychiatricHold: boolean;
  psychiatricHoldDescription: string;
  fiftyOneFiftyHold: boolean;
  fiftyOneFiftyHoldDescription: string;
  hearsVoices: boolean;
  depression: boolean;
  voicesOrDepressionExplanation: string;
  outpatientTreatmentProgram: boolean;
  outpatientTreatmentProgramDescription: string;
  // Legal History
  crimeOccurrence: boolean;
  crimeOccurrenceDescription: string;
  residentialTreatmentProgram: boolean;
  residentialTreatmentProgramDescription: string;
  onProbationOrParole: boolean;
  probationOrParoleDescription: string;
  pendingLegalMatters: boolean;
  pendingLegalMattersDescription: string;
  pendingLegalMattersDates: string;
  officerName: string;
  county: string;
  cpsOrCustodialCases: string;
  smokes: boolean;
  smokingLength: string;
  willingToQuit: boolean;
  drugsUsed: string;
  sobrietyDate: string;
  questions: string;
}

export interface PrimaryIntakeHumanTraffickingInformation {
  id: string;
  trafficker: string;
  when: string;
  safetyConcerns: string;
  city: string;
  typeOfExploitation: string;
  lengthOfTime: string;
  ageOfEntry: string;
  nickname: string;
  safeLocation: string;
  relocation: EPrimaryIntakeRelocation;
}

export interface PrimaryIntakeClosingInformation {
  id: string;
  commentsAndConcerns: string;
  residencyType: EPrimaryIntakeResidencyType;
  referredTo: string;
  personWhoDidAssessment: string;
}

export interface PrimaryIntake {
  id: string;
  userId: string;
  employeeName: string;
  referredBy: string;
  reasonForCall: string;
  fpdReferral: boolean;
  personalInformation: PrimaryIntakePersonalInformation;
  humanTraffickingIdentificationQuestions: PrimaryIntakeHumanTraffickingIdentificationQuestions;
  parProgramQuestions: PrimaryIntakeParProgramQuestions;
  assessmentQuestions: PrimaryIntakeAssessmentQuestions;
  humanTraffickingInformation: PrimaryIntakeHumanTraffickingInformation;
  closingInformation: PrimaryIntakeClosingInformation;
  updatedOn: string;
}

export type FlattenedPrimaryIntake = {
} & PrimaryIntakePersonalInformation & PrimaryIntakeHumanTraffickingIdentificationQuestions & PrimaryIntakeParProgramQuestions
  & PrimaryIntakeAssessmentQuestions & PrimaryIntakeHumanTraffickingInformation & PrimaryIntakeClosingInformation;

const actions = createStandardActions(placeholder<PrimaryIntake>(), 'PRIMARY_INTAKE/SET', 'PRIMARY_INTAKE/SAVE');
const selectors = createStandardSelectors(placeholder<PrimaryIntake>(), s => getEntities(s).primaryIntakes);
export type PrimaryIntakeActions = GetActions<typeof actions>;
export const primaryIntakes = combineReducers({items: standardItemsReducer<PrimaryIntake, PrimaryIntakeActions>(actions)});
export const primaryIntakeStore = readonly({
  selectors: {
    ...selectors,
    getByUserId: selector(s => (userId: string) => selectors.getAsArray(s).find(intake => intake.userId === userId)),
    getByUserIds: selector(s => (userIds: string[]) => selectors.getAsArray(s).filter(intake => userIds.includes(intake.userId)))
  },
  actions: {
    ...actions,
    getByUserId: (userId: string) => async (dispatch: CommonDispatch) => {
      const response = await getPrimaryIntake(userId);
      if (response && response.id !== '')
        await dispatch(actions.save(response));
    }
  }
});
