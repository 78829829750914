import styles from './PageFooter.module.scss';
import {RoutePaths} from '../../../router/RoutePaths';
import {getLogoLink, rbswLogo} from '../../../appTheme';
import React from 'react';


type Props = {};


export const PageFooter = (props: Props) => {
  return (
    <div className={styles['footer']}>
      <div className={styles['footer-text']}>
        Site Created By RavenBridge Software LLC
      </div>
      <a href={RoutePaths.rbswLink} style={{display: 'flex', maxWidth: '4%', minWidth: '4rem'}} target={'_blank'}>
        <img src={rbswLogo()} alt='RavenBridge Software Logo' className={styles['logo']}/>
      </a>
    </div>
  );
};
