import {Dispatch} from 'redux';
import {Dictionary} from '../../util';
import {getUserManagementState} from '../../../api/userManagementApi';
import {User, userStore} from '../entities/user';
import {batchActions} from 'redux-batched-actions';
import {Role, roleStore} from '../entities/role';
import {getDocumentManagementState} from '../../../api/documentManagementApi';
import {BtcDocument, btcDocumentStore} from '../entities/btcDocument';
import {getCaseFileDocumentManagementState, getCaseFileState} from '../../../api/caseFileManagementApi';
import {CaseFileDocument, caseFileDocumentStore} from '../entities/caseFileDocument';
import {CaseFile, caseFileStore} from '../entities/caseFile';
import {getPrimaryIntakes} from '../../../api/forms/formApi';
import {PrimaryIntake, primaryIntakeStore} from '../entities/forms/intake/primaryIntake/PrimaryIntake';
import {SecondaryIntake, secondaryIntakeStore} from '../entities/forms/intake/secondaryIntake/secondaryIntake';

export interface UserManagementState {
  users: Dictionary<User>;
  roles: Dictionary<Role>;
}

export interface CaseFileDocumentState {
  btcDocuments: Dictionary<BtcDocument>;
  caseFileDocuments: Dictionary<CaseFileDocument>;
}

export interface IntakesState {
  primaryIntakes: Dictionary<PrimaryIntake>;
  secondaryIntakes: Dictionary<SecondaryIntake>;
}


export const loadUserManagementData = () => async (dispatch: Dispatch) => {
  const response: UserManagementState = await getUserManagementState();
  await dispatch(batchActions([
    dispatch(userStore.actions.set(response.users)),
    dispatch(roleStore.actions.set(response.roles))
  ], 'LOAD_USER_MANAGEMENT_STATE'));
};


export const loadDocumentManagementData = () => async (dispatch: Dispatch) => {
  const response: Dictionary<BtcDocument> = await getDocumentManagementState();
  await dispatch(batchActions([
    dispatch(btcDocumentStore.actions.set(response))
  ], 'LOAD_DOCUMENT_MANAGEMENT_STATE'));
};

export const loadCaseFileState = (userId: string) => async (dispatch: Dispatch) => {
  const response: Dictionary<CaseFile> = await getCaseFileState(userId);
  await dispatch(batchActions([
    dispatch(caseFileStore.actions.set(response))
  ], 'LOAD_CASE_FILE_STATE'));
};


export const loadCaseFileDocumentState = (userId: string) => async (dispatch: Dispatch) => {
  const response: CaseFileDocumentState = await getCaseFileDocumentManagementState(userId);
  await dispatch(batchActions([
    dispatch(btcDocumentStore.actions.set(response.btcDocuments)),
    dispatch(caseFileDocumentStore.actions.set(response.caseFileDocuments))
  ], 'LOAD_CASE_FILE_DOCUMENT_MANAGEMENT_STATE'));
};

export const loadPrimaryIntakeData = () => async (dispatch: Dispatch) => {
  const response: IntakesState = await getPrimaryIntakes();
  await dispatch(batchActions([
    dispatch(primaryIntakeStore.actions.set(response.primaryIntakes)),
    dispatch(secondaryIntakeStore.actions.set(response.secondaryIntakes))
  ], 'LOAD_PRIMARY_INTAKES'));
};
