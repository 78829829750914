import {Card, Col, Container, Row} from 'react-bootstrap';
import styles from './ParticipantClasses.module.scss';
import PageHeader from '../../components/layout/PageHeader/PageHeader';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../redux/core/types/WebState';
import {connect} from 'react-redux';
import React, {useState} from 'react';
import {ParticipantClass, participantClassStore} from '../../redux/web/entities/participantClass';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../redux/util/http';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {useMount} from '../../hooks/useMount';
import {ListTable} from '../../components/util/lists/ListTable/ListTable';
import {ListRow} from '../../components/util/lists/ListRow/ListRow';
import {format, parseISO} from 'date-fns';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function ParticipantClasses(props: Props) {
  const {pastParticipantClasses, futureParticipantClasses, actions: {loadCurrentUserClasses}} = props;
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);

  useMount(async () => {
      try {
        await loadCurrentUserClasses();
      } catch (e: AxiosError | any) {
        setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load classes'}));
      }
      setLoading(false);
  });

  const dateFormatter = (isoDateTime: string) => {
    return parseISO(isoDateTime).toLocaleDateString();
  };

  const timeFormatter = (isoDateTime: string) => {
    return format(parseISO(isoDateTime), 'p');
  };

  const renderVideoItem = (pc: ParticipantClass, watched: boolean) => {
    return (
      <div className={styles['list-item']} onClick={() => null}>
        <div style={{width: '50%', display: 'flex', flexGrow: '1', marginRight: '2rem', flexDirection: 'column', paddingLeft: '1rem'}}>
          <h5 style={{textOverflow: 'ellipsis', overflow: 'hidden', lineHeight: '2rem', wordWrap: 'unset'}}>{pc.name}</h5>
          <div style={{textOverflow: 'ellipsis', overflow: 'hidden',  lineHeight: '1.5rem'}}>{pc.description}</div>
        </div>
        <div style={{marginTop: 'auto', flexShrink: '1', fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden'}}>
          <Row style={{justifyContent: 'flex-end'}}>
            {dateFormatter(pc.startDateTime)}
          </Row>
          <Row style={{justifyContent: 'flex-end'}}>
            {`${timeFormatter(pc.startDateTime)} - ${timeFormatter(pc.endDateTime)}`}
          </Row>
        </div>
      </div>
    );
  };

  const renderListItem = (pc: ParticipantClass, watched: boolean) => {
    return (
      <ListRow key={pc.id} children={renderVideoItem(pc, watched)} />
    );
  };

  // TODO sort participant classes by date, display in local time
  const renderParticipantClassList = (pcs: ParticipantClass[], upcoming: boolean, displayEmpty: boolean) => {
    if(pcs !== undefined && !(!displayEmpty && pcs.length === 0)) {
      return (
        <>
          <ListTable
            items={pcs}
            noItemsLabel={'No applicable classes'}
            renderItem={(participantClass: ParticipantClass) => renderListItem(participantClass, upcoming)}
            style={{display: 'flex', flexDirection: 'row', flex: '1'}}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const renderContent = () => {
    return (
      <Card className={styles['page-body']} style={{borderLeftWidth: 0, borderRightWidth: 0}}>
        <Card.Body>
          <Row className={'lists-container'}>
            <Col>
              <h3 style={{paddingLeft: '15px', paddingBottom: '10px'}}>
                Upcoming classes
              </h3>
              {renderParticipantClassList(futureParticipantClasses, false, true)}
            </Col>
            <br/>
            <Col>
              <h3 style={{paddingLeft: '15px', paddingBottom: '10px'}}>
                Past classes
              </h3>
              {renderParticipantClassList(pastParticipantClasses, true, true)}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <PageHeader label='Participant Classes' removeMargin={true}>
      <Container fluid={true} className={styles['config-container']}>
        {loading ?  <CenteredSpinner/> : (
          errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
            renderContent()
        )}
      </Container>
    </PageHeader>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadCurrentUserClasses: participantClassStore.actions.loadCurrentUser
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  pastParticipantClasses: participantClassStore.currentUserSelectors.currentUserPastParticipantClasses(state),
  futureParticipantClasses: participantClassStore.currentUserSelectors.currentUserFutureParticipantClasses(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantClasses);
