import {
  TNewParticipantChecklistForm
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/NewParticipantChecklistModal/NewParticipantChecklistModal';
import {ENewParticipantChecklistEducation, NewParticipantChecklist} from '../../entities/forms/newParticipantChecklist';
import {PrimaryIntake} from '../../entities/forms/intake/primaryIntake/PrimaryIntake';

export function makeNewParticipantChecklist(
  userId: string, existingChecklist?: NewParticipantChecklist, primary?: PrimaryIntake): TNewParticipantChecklistForm {
    const personal = primary?.personalInformation;
    return existingChecklist ? {...existingChecklist, userId: userId} : {
      userId: userId,
      name: personal ? `${personal.firstName} ${personal.middleInitial ? `${personal.middleInitial} ` : ''}${personal.lastName}` : '',
      startDate: '',
      dateOfBirth: personal ? personal.birthDate : '',
      caId: false,
      ssCard: false,
      birthCertificate: false,
      mediCal: false,
      calFresh: false,
      probationOrParole: false,
      diplomaOrGed: false,
      cps: false,
      victimCompApp: false,
      outcomeOfInitialNeeds: '',
      education: ENewParticipantChecklistEducation.needsDiplomaOrGed,
      updatedOn: ''
    };
}
