import React, {SetStateAction, useEffect, useMemo, useRef, useState} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../redux/core/types/WebState';
import {connect} from 'react-redux';
import styles from './StaffChecklistCalendar.module.scss';
import {
  addDays,
  addWeeks,
  format,
  getDate,
  getDay,
  getWeek,
  getYear,
  startOfWeek,
  subWeeks
} from 'date-fns';
import {Alert, Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {AppTheme} from '../../../appTheme';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
import {useStandardEditor} from '../../../components/util/form-components/EditorForm/hooks';
import {
  makeChecklistWeek,
} from '../../../redux/web/factory';
import {RoutePaths} from '../../../router/RoutePaths';
import {EditorForm} from '../../../components/util/form-components/EditorForm/EditorForm';
import StaffChecklistCalendarEditableFields from './StaffChecklistCalendar';
import {userStore} from '../../../redux/web/entities/user';
import {FieldArray} from 'formik';
import {loadChoreChartData} from '../../../redux/web/stateResponses/choreChartData';
import ReactDatePicker from 'react-datepicker';
import {Form as BSForm} from 'react-bootstrap';
import {StandardInputRow, StandardTopPaddedRow} from '../../../components/util/form-components/standardLayout';
import {ChecklistWeek, checklistWeekStore} from '../../../redux/web/entities/checklistWeek';
import {useReactToPrint} from 'react-to-print';

export interface ChecklistDay {
  date: Date;
  formattedDate: string;
  dayOfTheWeek: number;
  dayOfTheMonth: number;
}

type Props = {  initialDate: Date } & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function StaffChecklistCalendarController(props: Props) {
  const {checklistWeeks, getChecklistWeekByDate, isShelterStaff, initialDate, actions: {upsertWeek, loadAdditionalChecklistData}} = props;

  const [currentDate, setCurrentDate] = useState(initialDate);
  const currentWeek = () => getWeek(currentDate);
  const currentYear = () => getYear(currentDate);
  const getWeekFieldName = (name: keyof ChecklistWeek) => name;
  const makeCurrentChecklistWeek = () => makeChecklistWeek(currentYear(), currentWeek(), getChecklistWeekByDate(currentYear(), currentWeek()));
  const [loadedYears, setLoadedYears] = useState<number[]>([getYear(initialDate)]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showUpdatedAt, setShowUpdatedAt] = useState(false);
  const [copyDate, setCopyDate] = useState(subWeeks(currentDate, 1));
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '@page { size: auto;  margin: 4rem 2rem; } }'
  });

  useEffect(() => {
    const loadAdditional = async () => {
      if (!loadedYears.includes(currentYear())) {
        await loadAdditionalChecklistData(currentDate.toISOString());
        setLoadedYears((prevState) => [...prevState, getYear(currentDate)]);
      }
    };

    loadAdditional();
  }, [loadedYears, currentYear()]);

  const getViewParamsUrl = () => RoutePaths.checklistPaths.ViewParams
    .replace(':date', format(currentDate, 'MM-dd-yyyy'));

  const getEditParamsUrl = () => RoutePaths.checklistPaths.EditParams
    .replace(':date', format(currentDate, 'MM-dd-yyyy'));

  const standardEditor = useStandardEditor<ChecklistWeek>(
    'Shelter Staff Checklist',
    makeCurrentChecklistWeek(),
    props,
    s => ({
      getEditUrl: () => getEditParamsUrl(),
      getCancelUrl: () => getViewParamsUrl(),
      onLoadForm: async () => makeCurrentChecklistWeek(),
      onNewForm: async () => makeCurrentChecklistWeek(),
      saveAndRedirect: async (form) => {
        await upsertWeek(form);
        return getViewParamsUrl();
      },
      header: true,
      editButtonText: 'Edit',
      saveButtonText: 'Submit',
      submitButtonColor: AppTheme.colors.oceanBlue,
      buttonWrapperStyle: {flexWrap: 'nowrap'},
      additionalButtonsProps: {
        buttons: [
          {style: {backgroundColor: AppTheme.colors.oceanBlue}, showView: true, showEdit: true, onClick: (async () => setShowUpdatedAt(!showUpdatedAt)),
            children: `${showUpdatedAt ? 'Hide' : 'Show'} Updates`},
          {style: {backgroundColor: AppTheme.colors.oceanBlue}, showEdit: true, onClick: (async () => setShowCopyModal(true)), children: 'Copy'},
          {style: {backgroundColor: AppTheme.colors.oceanBlue}, showView: true, children: 'Print', onClick: handlePrint}
        ],
        position: 'left'
      }
    }));
  const {editable} = standardEditor;

  const handleDateChange = (action: 'back' | 'forward') => {
    if (action === 'back') {
      setCopyDate(() => subWeeks(currentDate, 2));
      setCurrentDate(() => subWeeks(currentDate, 1));
    } else {
      setCopyDate(() => currentDate);
      setCurrentDate(() => addWeeks(currentDate, 1));
    }
  };

  const renderCopyModal =
    (currentWeekId: string, setValues: (values: SetStateAction<ChecklistWeek>, shouldValidate?: (boolean | undefined)) => void) => (
      <Modal show={showCopyModal} centered={true}>
        <Modal.Body>
          <Modal.Title>Copy a Week</Modal.Title>
          <BSForm>
            <StandardTopPaddedRow>
              <StandardInputRow label={'Date:'} labelSize={2} columnSize={8}>
                <ReactDatePicker
                  customInput={<Form.Control/>}
                  value={format(copyDate, 'MM/dd/yyyy')}
                  onChange={(date: Date | null) => date && setCopyDate(date)}
                />
              </StandardInputRow>
            </StandardTopPaddedRow>
            <StandardTopPaddedRow>
              <BSForm.Group className={styles['form-buttons']}>
                <Button className={styles['close-button']} variant={'danger'} onClick={() => setShowCopyModal(false)}>
                  Cancel
                </Button>
                <Button
                  variant={'success'}
                  onClick={() => {
                    const copyingWeek = checklistWeeks.find(w => (w.year === getYear(copyDate) && w.weekOfTheYear === getWeek(copyDate)));
                    if (copyingWeek) {
                      const newWeek: ChecklistWeek = {
                        ...copyingWeek,
                        id: currentWeekId,
                        year: getYear(currentDate),
                        weekOfTheYear: getWeek(currentDate),
                        rows: copyingWeek.rows.map((row) => {
                          return {...row, id: '', items: row.items.map((item) => ({...item, id: '', done: false}))};
                        })};
                      setValues(newWeek);
                      setErrorMessage('');
                    }
                    else setErrorMessage('No values could be copied from the specified date.');
                    setShowCopyModal(false);
                    setCopyDate(currentDate);
                  }}
                >
                  Copy
                </Button>
              </BSForm.Group>
            </StandardTopPaddedRow>
          </BSForm>
        </Modal.Body>
      </Modal>
  );

  const renderCalendar = () => (
    <Col style={{width: '100%', overflowX: 'auto', padding: '0'}}>
      <EditorForm standardEditor={standardEditor}>
        {({values, setFieldValue, setValues}) =>
          <>
            <FieldArray
              name={getWeekFieldName('rows')}
              validateOnChange={false}
              render={(helpers) =>
                <StaffChecklistCalendarEditableFields
                  ref={printRef}
                  currentDate={currentDate}
                  editProps={editable ? {setFieldValue: setFieldValue, helpers: helpers} : undefined}
                  renderedValues={editable ? values : makeCurrentChecklistWeek()}
                  setErrorMessage={setErrorMessage}
                  showUpdatedAt={showUpdatedAt}
                  showShelterStaffProps={isShelterStaff}
                />}
            />
            {renderCopyModal(values.id, setValues)}
          </>
        }
      </EditorForm>
    </Col>
  );

  return (
    <Col style={{padding: '0'}}>
      <Row style={{ flexWrap: 'nowrap'}}>
        {!editable ?
          <Col style={{maxWidth: '2rem', justifyContent: 'center', alignItems: 'center', padding: '0'}}>
            <IconButton icon={'caret-left'} size={'3x'} onClick={() => handleDateChange('back')}/>
          </Col>
          : null}
        {renderCalendar()}
        {!editable ?
          <Col style={{maxWidth: '2rem', justifyContent: 'center', alignItems: 'center', padding: '0'}}>
            <IconButton icon={'caret-right'} size={'3x'} onClick={() => handleDateChange('forward')}/>
          </Col>
          : null}
      </Row>
      {errorMessage !== '' ?
        <div style={{marginTop: '1rem'}}>
          <Alert variant='danger'>{errorMessage}</Alert>
        </div>
        : null}
    </Col>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertWeek: checklistWeekStore.actions.upsertWeek,
    loadAdditionalChecklistData: loadChoreChartData
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  isAdministrator: userStore.selectors.isAdministrator(state),
  isShelterStaff: userStore.selectors.isShelterStaff(state),
  checklistWeeks: checklistWeekStore.selectors.getAsArray(state),
  getChecklistWeekByDate: checklistWeekStore.selectors.getWeek(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(StaffChecklistCalendarController);
