import {useFormikContext} from 'formik';
import {
  SecondaryIntake as SI,
  SecondaryIntakeEducationAndEmployment as SIEducationEmployment
} from '../../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {
  StandardCheckboxInput,
  StandardInputColumn,
  StandardParentInputRow,
  StandardTopPaddedRow
} from '../../../../../../../components/util/form-components/standardLayout';
import Input from '../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import React from 'react';
import DatepickerInput
  from '../../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {
  makeSecondaryIntakePreviousEmployer
} from '../../../../../../../redux/web/factory/forms/intakeForms';
import {
  FieldArrayRow
} from '../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/FieldArrayRow';
import {
  DeletableFieldArray
} from '../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/DeletableFieldArray';

export const EducationAndEmploymentForm = () => {
  const {values, setFieldValue} = useFormikContext<SI>();
  const formValues = values.educationAndEmployment;
  // return type not really needed, but it's cool you can do this
  const getFieldName = (name: keyof SIEducationEmployment): `${keyof SI}.${keyof SIEducationEmployment}` =>
    `educationAndEmployment.${name}`;
  return (
    <>
      <StandardParentInputRow label={'Education'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'What school do you currently attend?'} columnSize={8}>
            <Input name={getFieldName('schoolAttending')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Did you graduate high school?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasGraduatedHighSchool')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, name:'} columnSize={8}>
            <Input name={getFieldName('highSchoolGraduatedFrom')} disabled={!formValues.hasGraduatedHighSchool} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you have a GED?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasGed')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, from where?'} columnSize={8}>
            <Input name={getFieldName('hasGedFrom')} disabled={!formValues.hasGed} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Did you attend college?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasAttendedCollege')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'What is the highest level of school completed?'} columnSize={6}>
            <Input name={getFieldName('highestLevelOfEducationCompleted')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you attended any trade schools?'} columnSize={4}>
            <StandardCheckboxInput
              name={getFieldName('hasAttendedTradeSchool')}
              label={'Yes'}
              onChange={(val: boolean) => {
                setFieldValue(getFieldName('hasAttendedTradeSchool'), val);
                if (!val)
                  setFieldValue(getFieldName('hasGraduatedTradeSchool'), val);
              }}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'What type?'} columnSize={6}>
            <Input name={getFieldName('tradeSchoolType')} disabled={!formValues.hasAttendedTradeSchool} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Did you graduate from a trade school?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasGraduatedTradeSchool')} label={'Yes'} disabled={!formValues.hasAttendedTradeSchool}/>
          </StandardInputColumn>
          <StandardInputColumn label={'When?'} columnSize={4}>
            <DatepickerInput
              name={getFieldName('dateGraduatedFromTradeSchool')}
              disabled={!formValues.hasGraduatedTradeSchool}
              valueOnDisabled={null}
              onChangeUtcHour={12}
              showYearDropdown={true}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'Would you like to further your education?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('wouldLikeToFurtherEducation')} label={'Yes'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Employment'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Are you currently employed?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('isCurrentlyEmployed')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, where?'} columnSize={8}>
            <Input name={getFieldName('currentEmployer')} disabled={!formValues.isCurrentlyEmployed} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Previous Employers'}>
            <DeletableFieldArray
              name={getFieldName('previousEmployers')}
              values={formValues.previousEmployers}
              factoryFn={makeSecondaryIntakePreviousEmployer}
              noItemsLabel={'No employers added.'}
              renderRow={(item, rowName, getItemName) => (
                <FieldArrayRow style={{flex: 'auto', minWidth: '13rem', marginRight: '1.5rem'}}>
                  <StandardInputColumn label={'Name'} columnSize={3} className='pl-2'>
                    <Input name={`${rowName}.${getItemName('name')}`}/>
                  </StandardInputColumn>
                  <StandardInputColumn label={'Title'} columnSize={3} className='pl-2'>
                    <Input name={`${rowName}.${getItemName('title')}`}/>
                  </StandardInputColumn>
                  <StandardInputColumn label={`Supervisor's Name`} columnSize={3} className='pl-2'>
                    <Input name={`${rowName}.${getItemName('supervisorName')}`}/>
                  </StandardInputColumn>
                  <StandardInputColumn label={'Contact Number'} columnSize={3} className='pl-2'>
                    <Input name={`${rowName}.${getItemName('contactNumber')}`}/>
                  </StandardInputColumn>
                </FieldArrayRow>
              )}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
    </>
  );
};
