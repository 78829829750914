import React from 'react';
import {StandardTopPaddedRow} from '../../form-components/standardLayout';

const PaddedRowsListFormatter = (
  props: {type:'ordered' | 'unordered'; orderedFormat?: 'a' | 'A' | 'i' | 'I' | '1'; children: React.ReactElement[]; padding?: string}) => {
  const mapChildren = () => React.Children.map(props.children, (child: React.ReactElement) => (
    <StandardTopPaddedRow style={props.padding ? {padding: props.padding} : undefined}>
      <li>{child}</li>
    </StandardTopPaddedRow>
  ));

  if (props.type === 'ordered')
    return <ol type={props.orderedFormat}>{mapChildren()}</ol>;
  return <ul>{mapChildren()}</ul>;
};

export default PaddedRowsListFormatter;
