import {CaseFileDocument, caseFileDocumentStore} from '../../../../redux/web/entities/caseFileDocument';
import React, {FormEvent, useState} from 'react';
import {Form, Formik, FormikErrors} from 'formik';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../../redux/util/http';
import {Alert, Button, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import styles from '../../CaseFile.module.scss';
import {makeCaseFileDocumentMergeRequest} from '../../../../redux/web/factory';
import Input from '../../../../components/util/form-components/formik-inputs/Input/Input';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../redux/core/types/WebState';
import {convertToDropDownOptions} from '../../../../redux/util';
import {connect} from 'react-redux';
import CaseFileDocumentMergeDraggableFieldArray from './CaseFileDocumentMergeDraggableFieldArray';
import {StandardInputRow} from '../../../../components/util/form-components/standardLayout';

export interface MergeCaseFileDocumentsRequest {
  orderedBtcDocumentIds: string[];
  name: string;
}

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
  editable: boolean;
  onTop?: boolean;
  userId: string;
  existingCaseFileDocuments: CaseFileDocument[];
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function CaseFileDocumentMergeModal(props: Props) {
  const {onTop, onSubmit, editable, onCancel, userId,
    existingCaseFileDocuments, actions: {mergeCaseFileDocuments}} = props;
  const getFieldName = (name: keyof MergeCaseFileDocumentsRequest) => name;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: MergeCaseFileDocumentsRequest,
    validate: (values: MergeCaseFileDocumentsRequest) => Promise<FormikErrors<MergeCaseFileDocumentsRequest>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
    } else {
      try {
        await mergeCaseFileDocuments(userId, association);
        onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(handleAxiosError(e));
      }
    }
    setIsSubmitting(false);
  };


  const renderButtons = (values: any, validateForm: any, handleSubmit: any, disableSubmit: boolean) => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={editable ? 'danger' : 'info'} style={{marginRight: '5px'}}>
            {editable ? 'Cancel' : 'Close'}
          </Button>
        }
        {!isSubmitting ? editable && <Button variant={'success'} type='button' disabled={disableSubmit}
                                             onClick={(e: any) => processSubmit(e, values, validateForm, handleSubmit)}>Merge</Button> : null}
      </>
    );
  };


  return (
    <Modal backdropClassName={onTop ? styles['on-top'] : ''} show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Formik<MergeCaseFileDocumentsRequest> initialValues={makeCaseFileDocumentMergeRequest(existingCaseFileDocuments
          .filter(doc => doc.data.includes('data:application/pdf')))} onSubmit={() => undefined}>
          {({values, validateForm, handleSubmit, setValues, errors}) => {
            return (
              <Form noValidate={false}
                    onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Modal.Title>{'Merge Case File Documents'}</Modal.Title>
                <StandardInputRow label={'Name'} columnSize={10} labelSize={2} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('name')}/>
                </StandardInputRow>
                <CaseFileDocumentMergeDraggableFieldArray
                  documentIds={values.orderedBtcDocumentIds}
                  fieldPrefix={getFieldName('orderedBtcDocumentIds')}
                  documentDropDownOptions={convertToDropDownOptions(existingCaseFileDocuments.filter(doc => doc.data.includes('data:application/pdf')))}
                />
                {errorMessage !== '' ?
                  <div style={{marginTop: '1rem'}}>
                    <Alert variant='danger'>{errorMessage}</Alert>
                  </div>
                  : null}
                <Row>
                  <BSForm.Group style={{marginLeft: 'auto', marginRight: '10px', marginTop: '10px', borderRadius: '10px', minWidth: '80px'}}>
                    {renderButtons(values, validateForm, handleSubmit, !values.orderedBtcDocumentIds.length)}
                  </BSForm.Group>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    mergeCaseFileDocuments: caseFileDocumentStore.actions.merge
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getCaseFileDocumentById: caseFileDocumentStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(CaseFileDocumentMergeModal);
