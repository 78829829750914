import React, {CSSProperties, memo} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core';
import styles from './IconButton.module.scss';
import {Link} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

interface IconButtonProps {
  icon: IconProp;
  hideIcon?: boolean;
  size?: SizeProp;
  customSize?: number;
  styles?: CSSProperties;
  linkStyle?: CSSProperties;
  iconToolTipText?: string;
  onClick?: (e: any) => void;
  children?: any;
  link?: string;
  disableHover?: boolean;
  disabled?: boolean;
  className?: string;
  color?: string;
}

export interface LabeledIconButtonProps extends IconButtonProps {
  label: string;
  labelStyle?: CSSProperties;
  wrapperDivStyle?: CSSProperties;
}

export const LabeledIconButton = (props: LabeledIconButtonProps) => {
  const {label, labelStyle, wrapperDivStyle, ...iconButtonProps} = props;
  return (
    <div style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', ...wrapperDivStyle}}>
      <IconButton {...iconButtonProps}/>
      <div style={labelStyle}>
        {label}
      </div>
    </div>
  );
};

const IconButton = memo((props: IconButtonProps) => {
  let style = {};
  if(props.disabled) {
    style = {cursor: 'default', ...props.styles, ...(props.color ? {color: props.color} : {}) };
  } else {
    style = { cursor: 'pointer', ...props.styles, ...(props.color ? {color: props.color} : {}) };
  }
  let button = null;
  const commonProps = {
    'data-tip': props.iconToolTipText,
    'className': styles['icon-button'],
    'style': {display: 'flex', alignItems: 'center'},
    'hidden': props.hideIcon
  };
  if (props.link) {
    button = (
      <Link {...commonProps} to={props.link} style={props.linkStyle}>
        <IconButtonIcon {...props}/>
      </Link>
    );
  } else {
    button =  (
      <span {...commonProps} onClick={!props.disabled ? props.onClick : undefined} style={props.linkStyle}>
        <IconButtonIcon {...props} />
      </span>
    );
  }
  return (
    <span style={{display: 'inline-block', ...style}} className={props.className}>{button}</span>
  );
});

const getIconStyles = (props: IconButtonProps) => {
  if(props.disabled) {
    return {cursor: 'default' ,...(props.customSize ? {fontSize: props.customSize} : {})};
  }
  return {cursor: 'pointer', ...(props.customSize ? {fontSize: props.customSize} : {})};
};

function IconButtonIcon(props: IconButtonProps) {
  return (
    <React.Fragment>
      <ReactTooltip effect={'float'}/>
      <FontAwesomeIcon
        icon={props.icon}
        size={props.size}
        className={!props.disabled ? styles['icon'] : styles['disabled-icon']}
        style={getIconStyles(props)}
        color={props.color}
      />
      {props.children ? <span style={{marginLeft: '1rem'}}>{props.children}</span> : null}
    </React.Fragment>
  );
}

export default IconButton;
