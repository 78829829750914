import {api} from './index';
import {User, UserPreferences, UserRegistrationForm} from '../redux/web/entities/user';
import {UserManagementState} from '../redux/web/stateResponses/stateManagement';
import {v4 as uuidv4} from 'uuid';
import {BtcDocument} from '../redux/web/entities/btcDocument';
import {UpdateProfilePictureRequest} from '../pages/Profile/components/ProfilePicture';
import {UserUpsert} from '../pages/Configuration/UserManagement/components/UserModal/UserModal';

export async function getUser(userId: string) {
  return (await api.get<User>(`/User/${userId}`)).data;
}

export async function getUsers() {
  return (await api.get<User[]>('/User')).data;
}

export async function getUserManagementState() {
  return (await api.get<UserManagementState>('/User/management')).data;
}

export async function archiveUser(id: string) {
  return (await api.post<User>(`/User/${id}/archive`)).data;
}

export async function restoreUser(id: string) {
  return (await api.post<User>(`/User/${id}/restore`)).data;
}


export async function upsertUser(form: UserUpsert) {
  return (await api.post<User>('/User', form)).data;
}

export async function createUser(form: UserUpsert) {
  const request = form as UserRegistrationForm;
  const tempPass = uuidv4();
  request.password = tempPass;
  request.confirmPassword = tempPass;
  return (await api.post<User>('/User/create', request)).data;
}

export async function updateUserPreferences(form: UserPreferences) {
  return (await api.post<User>('/User/preferences', form)).data;
}

export async function updateProfilePicture(request: UpdateProfilePictureRequest) {
  return (await api.post<User>('/User/profilePicture', request)).data;
}
