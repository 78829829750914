export enum EPrimaryIntakeEthnicity {
  asian,
  americanIndianOrAlaskaNative,
  blackOrAfricanAmerican,
  hispanicOrLatino,
  nativeHawaiianOrOtherPacificIslander,
  whiteOrCaucasian,
  other
}

export enum EPrimaryIntakeGenderIdentification {
  female,
  male,
  transgenderMaleToFemale,
  transgenderFemaleToMale,
  genderVariantNonConforming,
  preferNotToAnswer,
  notListed
}

export enum EPrimaryIntakeLanguage {
  english,
  spanish,
  americanIndian,
  hindu,
  korean,
  japanese,
  chinese,
  southeastAsian,
  tagalog,
  russian,
  other
}

export enum EPrimaryIntakeHelpType {
  housing,
  food,
  clothing,
  counseling,
  relocation,
  legalAdvice,
  financialAssistance,
  other
}

export enum EPrimaryIntakeHumanTraffickingType {
  sex,
  labor,
  both,
  neither
}

export enum EPrimaryIntakeRelocation {
  yes,
  no,
  maybe
}

export enum EPrimaryIntakeResidencyType {
  diversionClass,
  nonResidential,
  residential,
  stillToBeDetermined,
  csec
}
