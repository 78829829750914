import React, {useEffect, useRef} from 'react';
import {Button} from 'react-bootstrap';
import {CaseFileDocument} from '../../redux/web/entities/caseFileDocument';
import {GcPdfViewer} from '@grapecity/gcpdfviewer';

export default function GcPdfViewerComponent(props: any) {
  const containerRef = useRef(null);
  let viewer: GcPdfViewer;
  const currentCaseFileDocument: CaseFileDocument = props.currentCaseFile;

  useEffect(() => {
    GcPdfViewer.LicenseKey = `${process.env.REACT_APP_GC_PDF_VIEWER_KEY}`;
    viewer = new GcPdfViewer(containerRef.current as any as HTMLElement, {
      workerSrc: '/documents-api-pdfviewer/demos/product-bundles/build/gcpdfviewer.worker.js',
      keepFileData: false,
      supportApi: {
        apiUrl: `${process.env.REACT_APP_API_URL}SupportApi`,
        token: 'btc-pdf-viewer',
        webSocketUrl: false
      },
      userData: {
        documentId: currentCaseFileDocument.id,
        userId: props.currentUserId
      }
    });


    // @ts-ignore
    viewer.toolbar.addItem({
      key: 'custom-save',
      icon: {
        type: 'svg',
        content: React.createElement('svg', {
          xmlns: 'http://www.w3.org/2000/svg', version: '1.1', width: '24', height: '24', viewBox: '0 0 24 24', fill: '#235e77'
        },
        React.createElement('path', {d: 'M 0 6 L 0 18 L 24 18 L 24 6 L 0 6 Z M 22 15.8 L 2 15.8 L 2 8.2 L 22 8.2 L 22 15.8 Z'}),
        React.createElement('g', {transform: 'matrix(0.236063, 0, 0, 0.236063, 4.444534, 9.18412)'},
          React.createElement('path', {d: 'M 18.229 11.674 L 17.267 10.712 L 3.795 24.184 L -1.978 24.184 L -1.978 18.411 L 13.417 3.014 L 21.115 10.712 C 21.647 11.243 21.647 12.106 21.115 12.637 L 11.495 22.26 L 9.569 20.335 L 18.229 11.674 Z M 19.191 -0.835 L 23.041 3.014 C 23.572 3.545 23.572 4.407 23.041 4.939 L 21.115 6.863 L 15.343 1.09 L 17.267 -0.835 C 17.798 -1.365 18.661 -1.365 19.191 -0.835 Z'}))
        )
      },
      label: 'Add Signature',
      title: 'Add Signature',
      enabled: false,
      action() {

        const rect = viewer.changeBoundsOrigin(0, [10, 10, 210, 40], 'TopLeft', 'BottomLeft');
        // @ts-ignore
        viewer.addAnnotation(0, {
          annotationType: 27,
          rect: rect,
          annotationName: 'signature',
          title: 'signature'
        });
      },
      onUpdate() {
        return {
          enabled: viewer.hasDocument,
          title: 'Add Signature'
        };
      }
    });


    if(viewer.toolbarLayout.viewer.default.includes('save')) {
      const openDocumentIndex = viewer.toolbarLayout.viewer.default.indexOf('open');
      viewer.toolbarLayout.viewer.default.splice(openDocumentIndex, 1);
      const saveIndex = viewer.toolbarLayout.viewer.default.indexOf('save');
      viewer.toolbarLayout.viewer.default.splice(saveIndex, 2);
      const themeIndex = viewer.toolbarLayout.viewer.default.indexOf('theme-change');
      viewer.toolbarLayout.viewer.default.splice(themeIndex, 1);
      const fillerIndex = viewer.toolbarLayout.viewer.default.indexOf('form-filler');
      viewer.toolbarLayout.viewer.default.splice(fillerIndex, 1);
      const editWidgetIndex = viewer.toolbarLayout.formEditor.default.indexOf('edit-widget-tx-field');
      viewer.toolbarLayout.formEditor.default.splice(editWidgetIndex, 0, 'custom-save');
    }

    viewer.addFormEditorPanel();
    viewer.applyToolbarLayout();
    viewer.layoutMode = 0;

    viewer.open(props.document);


    return () => {
      if(viewer) {
        viewer.dispose();
        viewer = null as any as GcPdfViewer;
      }
    };
  }, []);

  return (
    <>
      <div style={{marginBottom: '10px'}}>
        {!props.readOnly && <Button
          onClick={async () => {
            await viewer.saveChanges().then(() => {
              props.onSave();
            });
          }}
          style={{backgroundColor: '#005A9C'}}
        >
          Save Document
        </Button>}
      </div>
      <div ref={containerRef} style={{ width: '100%', height: '100vh' }} />
    </>
  );
}
