import * as React from 'react';
import { Route, Routes} from 'react-router-dom';
import NotFound from '../pages/NotFound/NotFound';
import {PrivateRoute} from '../components/util/PrivateRoute/PrivateRoute';
import Login from '../pages/Login/Login';
import {RoutePaths} from './RoutePaths';
import {LogOut} from '../pages/Logout/Logout';
import Home from '../pages/Home/Home';
import UserManagement from '../pages/Configuration/UserManagement/UserManagement';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import {RoleProtectedRoute} from '../components/util/RoleProtectedRoute/RoleProtectedRoute';
import Profile from '../pages/Profile/Profile';
import DocumentManagement from '../pages/Configuration/DocumentManagement/DocumentManagement';
import ParticipantClassManagement from '../pages/Configuration/ParticipantClassManagement/ParticipantClassManagement';
import ParticipantClass from '../pages/ParticipantClass/ParticipantClasses';
import CaseFile from '../pages/CaseFile/CaseFile';
import ChoreChart from '../pages/ChoreChart/ChoreChart';
import StaffChecklist from '../pages/StaffChecklist/StaffChecklist';
import CaseFileManagement from '../pages/Configuration/CaseFileManagement/CaseFileManagement';
import GrantExport from '../pages/GrantExport/GrantExport';
import {CaseFileRoute} from '../components/util/CaseFileRoute/CaseFileRoute';

const AppRoutes = () => {
    const roleProtectedRoute = (routeComponent: JSX.Element, roles: string[]) => {
      return (
        <RoleProtectedRoute allowedRoles={roles}>
          {routeComponent}
        </RoleProtectedRoute>
      );
    };
    const caseFileRoute = (routeComponent: JSX.Element) => {
      return (<CaseFileRoute>{routeComponent}</CaseFileRoute>);
    };

    return (<Routes>
        <Route path={RoutePaths.login} element={<Login/>}/>
        <Route path={RoutePaths.logout} element={<LogOut/>}/>
        <Route path={RoutePaths.forgotPassword} element={<ForgotPassword/>}/>
        <Route path={RoutePaths.resetPassword} element={<ResetPassword/>}/>
        <Route
          path={RoutePaths.root}
          element={
            <PrivateRoute>
                <Routes>
                  <Route path={RoutePaths.home} element={<Home/>}/>
                  <Route path={RoutePaths.userManagement} element={
                      roleProtectedRoute(<UserManagement/>, ['Administrator'])}
                  />
                  <Route path={RoutePaths.documentManagement} element={
                    roleProtectedRoute(<DocumentManagement/>, ['Administrator', 'Staff', 'Shelter Staff'])}
                  />
                  <Route path={RoutePaths.participantClassManagement} element={
                    roleProtectedRoute(<ParticipantClassManagement/>, ['Administrator', 'Staff', 'Shelter Staff'])}
                  />
                  <Route path={RoutePaths.caseFilePaths.CaseFile} element={
                    caseFileRoute(<CaseFile/>)}
                  />
                  <Route path={RoutePaths.caseFileManagement} element={
                    roleProtectedRoute(<CaseFileManagement/>, ['Administrator', 'Staff', 'Shelter Staff'])}
                  />
                  <Route path={RoutePaths.choreChartPaths.ViewParams} element={<ChoreChart/>}/>
                  <Route path={RoutePaths.choreChartPaths.View} element={<ChoreChart/>}/>
                  <Route path={RoutePaths.choreChartPaths.EditParams} element={roleProtectedRoute(<ChoreChart/>, ['Administrator'])}/>
                  <Route path={RoutePaths.choreChartPaths.Edit} element={roleProtectedRoute(<ChoreChart/>, ['Administrator'])}/>
                  <Route path={RoutePaths.checklistPaths.ViewParams} element={roleProtectedRoute(<StaffChecklist/>, ['Administrator', 'Shelter Staff'])}/>
                  <Route path={RoutePaths.checklistPaths.View} element={roleProtectedRoute(<StaffChecklist/>, ['Administrator', 'Shelter Staff'])}/>
                  <Route path={RoutePaths.checklistPaths.EditParams} element={roleProtectedRoute(<StaffChecklist/>, ['Administrator', 'Shelter Staff'])}/>
                  <Route path={RoutePaths.checklistPaths.Edit} element={roleProtectedRoute(<StaffChecklist/>, ['Administrator', 'Shelter Staff'])}/>
                  <Route path={RoutePaths.participantClasses} element={roleProtectedRoute(<ParticipantClass/>, ['Participant'])}/>
                  <Route path={RoutePaths.profilePaths.Edit} element={<Profile/>}/>
                  <Route path={RoutePaths.profilePaths.View} element={<Profile/>}/>
                  <Route path={RoutePaths.exportPath} element={roleProtectedRoute(<GrantExport/>, ['Administrator', 'Staff'])}/>
                  <Route path={RoutePaths.all} element={<NotFound/>}/>
                </Routes>
            </PrivateRoute>
          }
        />
        <Route path={RoutePaths.all} element={<NotFound/>}/>
      </Routes>
    );
};

export default AppRoutes;
