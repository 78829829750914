import {
  number,
  object, string, mixed,
} from 'yup';



// @ts-ignore
export const CaseFileDocumentModalSchema = object<CaseFileDocument>({
  id: string().nullable(),
  name: string().required('Name is required')
});
