import {
  CaseManagementStepEvaluation
} from '../../../../../../../../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import {ArrayHelpers, FieldArray} from 'formik';
import {Col, Row} from 'react-bootstrap';
import IconButton from '../../../../../../../../../components/util/widgets/IconButton/IconButton';
import {
  makeCaseManagementDrugTest, makeCaseManagementStepEvaluation
} from '../../../../../../../../../redux/web/factory/forms/weeklyCaseManagementForms';
import {ListTable} from '../../../../../../../../../components/util/lists/ListTable/ListTable';
import {DeletableListRow} from '../../../../../../../../../components/util/lists/ListTable/ListTableRow';
import React from 'react';
import {FieldArrayRow} from '../../../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/FieldArrayRow';
import {InputColumn} from '../../../../../../../../../components/util/form-components/InputColumn';
import {StandardTopPaddedRow} from '../../../../../../../../../components/util/form-components/standardLayout';
import Input from '../../../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {makeFieldArrayFieldName} from '../../../../../../../../../util';

export type TCaseManagementStepEvaluationEntry = Omit<CaseManagementStepEvaluation, 'id'>;

interface Props {
  stepEvaluations: CaseManagementStepEvaluation[];
  editable: boolean;
  fieldPrefix: string;
}

const getFieldName = (name: keyof CaseManagementStepEvaluation) => name;

export const CaseManagementStepEvaluationsFieldArray = (props: Props) => {
  const {fieldPrefix, stepEvaluations, editable} = props;
  const generateFieldName = (index: number) => `${fieldPrefix}.${index}`;

  const renderHeader = (helpers: ArrayHelpers) => (
    <Col style={{width: '100%', marginBottom: '10px'}}>
  <Row>
    <h5>Case Management Step Evaluations</h5>
  <div style={{display: 'flex', flex: '1', textAlign: 'right'}}>
  <IconButton
    icon={'plus'}
  styles={{width: '100%', textAlign: 'right'}}
  size={'2x'}
  onClick={() => helpers.push(makeCaseManagementStepEvaluation())}
  />
  </div>
  </Row>
  </Col>
);

  return (
    <FieldArray
      name={fieldPrefix}
  validateOnChange={false}
  render={helpers=> {
    return (
      <ListTable
        style={{marginBottom: '1rem'}}
    noItemsLabel={'No Step Evaluations Added'}
    items={stepEvaluations}
    renderHeader={() => renderHeader(helpers)}
    renderItem={(item, index) => (
      <DeletableListRow
        iconSize={'2x'}
    key={generateFieldName(index)}
    deleteColumnSize={1}
    style={{flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'visible'}}
    render={() => (
      <StepEvaluationRow
        entryPrefix={generateFieldName(index)}
    editable={editable}
    stepEvaluation={item}
    />
  )}
    editable={editable}
    onDelete={() => helpers.remove(index)}
    />
  )}
    />
  );
  }}
  />
);
};

type StepEvaluationRowProps = {
  editable: boolean;
  stepEvaluation: CaseManagementStepEvaluation;
  entryPrefix: string;
};
function StepEvaluationRow({stepEvaluation, entryPrefix, editable}: StepEvaluationRowProps) {
  return  (
    <FieldArrayRow style={{flex: '1', minWidth: '13rem', marginRight: '1.5rem'}}>
      <Col style={{padding: '0'}}>
        <StandardTopPaddedRow>
          <InputColumn label={'Time Frame'} columnSize={8}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('timeFrame'))}/>
          </InputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <InputColumn label={'Description'} columnSize={6}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('description'))} type={'textarea'}/>
          </InputColumn>
          <InputColumn label={'Additional Comments'} columnSize={6}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('comment'))} type={'textarea'}/>
          </InputColumn>
        </StandardTopPaddedRow>
      </Col>
    </FieldArrayRow>
);
}
