import React from 'react';
import {
  SecondaryIntake as SI,
  SecondaryIntakeCriminalAndHousing as SICriminalHousing
} from '../../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {useFormikContext} from 'formik';
import Input from '../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {
  StandardCheckboxInput,
  StandardInputColumn,
  StandardParentInputRow,
  StandardRadioInput,
  StandardTopPaddedRow
} from '../../../../../../../components/util/form-components/standardLayout';
import {
  ESecondaryIntakeHomelessStatus as EHomelessStatus
} from '../../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntakeHelperModels';
import {
  DeletableFieldArray
} from '../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/DeletableFieldArray';
import {makeSecondaryIntakeHouseholdResident} from '../../../../../../../redux/web/factory/forms/intakeForms';
import {
  FieldArrayRow
} from '../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/FieldArrayRow';
import DatepickerInput
  from '../../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {Alert} from 'react-bootstrap';
import {RedErrorMessage} from '../../../../../../../components/util/form-components/RedErrorMessage/RedErrorMessage';

// TODO add ethnicity
export const CriminalAndHousingForm = () => {
  const {values, setFieldValue} = useFormikContext<SI>();
  const formValues = values.criminalAndHousing;
  // return type not really needed, but it's cool you can do this
  const getFieldName = (name: keyof SICriminalHousing): `${keyof SI}.${keyof SICriminalHousing}` =>
    `criminalAndHousing.${name}`;

  const isHomeless = () => !(formValues.homelessStatus === EHomelessStatus.notHomeless);
  return (
    <>
      <StandardParentInputRow label={'Criminal'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Are you on probation or parole?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('isOnProbationOrParole')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, what?'} columnSize={8}>
            <Input name={getFieldName('probationOrParoleDescription')} disabled={!formValues.isOnProbationOrParole} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={`P.O.'s name and number:`}>
            <Input name={getFieldName('poNameAndNumber')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you have any pending court matters?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('pendingCourtMatters')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, please describe:'} columnSize={8}>
            <Input
              name={getFieldName('pendingCourtMattersDescription')}
              type={'textarea'}
              disabled={!formValues.pendingCourtMatters}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you have any warrants?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasWarrants')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, please explain:'} columnSize={8}>
            <Input
              name={getFieldName('warrantsDescription')}
              type={'textarea'}
              disabled={!formValues.hasWarrants}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Housing'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'What is your current residential status?'} columnSize={4}>
            <StandardRadioInput
              name={getFieldName('homelessStatus')}
              label={'Not homeless'}
              value={EHomelessStatus.notHomeless}
              checked={formValues.homelessStatus === EHomelessStatus.notHomeless}
            />
            <StandardRadioInput
              name={getFieldName('homelessStatus')}
              label={'Shelter'}
              value={EHomelessStatus.shelter}
              checked={formValues.homelessStatus === EHomelessStatus.shelter}
            />
            <StandardRadioInput
              name={getFieldName('homelessStatus')}
              label={'Safe house'}
              value={EHomelessStatus.safeHouse}
              checked={formValues.homelessStatus === EHomelessStatus.safeHouse}
            />
            <StandardRadioInput
              name={getFieldName('homelessStatus')}
              label={'Unhoused'}
              value={EHomelessStatus.unhoused}
              checked={formValues.homelessStatus === EHomelessStatus.unhoused}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, for how long:'} columnSize={4}>
            <Input
              name={getFieldName('homelessLength')}
              disabled={isHomeless()}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'Is this the first time?'} columnSize={4}>
            <StandardCheckboxInput
              name={getFieldName('firstTimeBeingHomeless')}
              label={'Yes'}
              disabled={isHomeless()}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If you are not homeless, where are you currently staying?'}>
            <Input name={getFieldName('currentResidence')} disabled={!isHomeless()} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'How long have you been staying at your current residence?'}>
            <Input name={getFieldName('currentResidenceLength')} disabled={!isHomeless()} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Household Residents'}>
            <DeletableFieldArray
              name={getFieldName('householdResidents')}
              values={formValues.householdResidents}
              factoryFn={makeSecondaryIntakeHouseholdResident}
              disabled={isHomeless()}
              noItemsLabel={'No residents added.'}
              renderRow={(item, rowName, getItemName, disabled) => (
                <FieldArrayRow style={{flex: 'auto', minWidth: '13rem', marginRight: '1.5rem'}}>
                  <StandardInputColumn label={'Name'} columnSize={4}>
                    <Input name={`${rowName}.${getItemName('name')}`} disabled={disabled}/>
                  </StandardInputColumn>
                  <StandardInputColumn label={'Birth Date (estimation is okay)'} columnSize={4}>
                    <DatepickerInput
                      name={`${rowName}.${getItemName('birthDate')}`}
                      disabled={disabled}
                      onChangeUtcHour={12}
                      ignoreOverflow={true}
                      showYearDropdown={true}
                    />
                  </StandardInputColumn>
                  <StandardInputColumn label={'Relation (sibling, friend, etc.)'} columnSize={4}>
                    <Input name={`${rowName}.${getItemName('relation')}`} disabled={disabled}/>
                  </StandardInputColumn>
                </FieldArrayRow>
              )}
              renderFooter={() => (
                  <>
                    {!!(isHomeless() && formValues.householdResidents.length) ?
                      <Alert variant='warning' style={{margin: '15px'}}>
                        Household residents will not be submitted if the field is disabled.
                      </Alert> : null}
                    <RedErrorMessage name={getFieldName('householdResidents')}/>
                  </>
              )}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
    </>
  );
};
