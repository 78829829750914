import {
  PrimaryIntakeHumanTraffickingIdentificationQuestions, PrimaryIntakeParProgramQuestions
} from '../../../../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {
  StandardParentInputRow ,
  StandardTopPaddedRow,
  StandardInputColumn,
  StandardCheckboxInput
} from '../../../../../../../../components/util/form-components/standardLayout';
import Input from '../../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import React, {useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import {TPrimaryIntakeUpsertForm} from '../../PrimaryIntakeModal';
import {AppTheme} from '../../../../../../../../appTheme';

export type THumanTraffickingIdentificationQuestionsForm = Omit<PrimaryIntakeHumanTraffickingIdentificationQuestions, 'id'> & {
  id?: string;
};
export type TParProgramQuestionsForm = Omit<PrimaryIntakeParProgramQuestions, 'id'> & {
  id?: string;
};

interface Props {
  humanTraffickingPrefix: string;
  parProgramPrefix: string;
}

export const HumanTraffickingIdentificationAndParQuestionsForm = (props: Props) => {
  const {values} = useFormikContext<TPrimaryIntakeUpsertForm>();
  const traffickingValues = values.humanTraffickingIdentificationQuestions;
  const parValues = values.parProgramQuestions;
  const humanTraffickingValues = values.humanTraffickingIdentificationQuestions;
  const parQuestionValues = values.parProgramQuestions;
  const [parDisabled, setParDisabled] = useState(false);

  const getHumanTraffickingFieldName = (name: keyof THumanTraffickingIdentificationQuestionsForm) => `${props.humanTraffickingPrefix}.${name}`;
  const getParProgramFieldName = (name: keyof TParProgramQuestionsForm) => `${props.parProgramPrefix}.${name}`;

  useEffect(() => {
    if (humanTraffickingValues.experiencedHumanTrafficking || humanTraffickingValues.tradedSexualFavors)
      setParDisabled(true);
    else
      setParDisabled(false);
  }, [humanTraffickingValues, parQuestionValues]);

  const renderParDisabledMessage = () => (
    <StandardTopPaddedRow style={{color: AppTheme.colors.danger}}>
      <h6>
        The PAR program question assessment section will not be submitted if the participant indicated they have experienced a form of human trafficking above.
      </h6>
    </StandardTopPaddedRow>
  );

  return (
    <>
      <StandardParentInputRow label={'Human Trafficking Identification Questions'}>
        <StandardTopPaddedRow>
          <h6>Human trafficking is the active involvement of trading sex or sexual favors for money or some other type of gain.</h6>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you experienced human trafficking?'} columnSize={4}>
            <StandardCheckboxInput name={getHumanTraffickingFieldName('experiencedHumanTrafficking')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Have you ever had to trade sex or sexual favors for money, food, shelter, or other items?'} columnSize={8}>
            <StandardCheckboxInput name={getHumanTraffickingFieldName('tradedSexualFavors')} label={'Yes'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If yes, what did you trade sex for?'}>
            <Input
              name={getHumanTraffickingFieldName('tradedSexualFavorsDescription')}
              type={'textarea'}
              disabled={!traffickingValues.tradedSexualFavors}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you feel you have been subjected to unfair labor expectations?'} columnSize={4}>
            <StandardCheckboxInput name={getHumanTraffickingFieldName('unfairLaborExpectations')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Have you ever had to work without pay or pay that does not meet minimum wage requirements?'} columnSize={8}>
            <StandardCheckboxInput name={getHumanTraffickingFieldName('illegalFinancialCompensation')} label={'Yes'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Other Important Information'}>
            <Input name={getHumanTraffickingFieldName('otherInformation')} type={'textarea'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <h6>If the participant answered YES to any of the above human trafficking questions, please skip the PAR assessment questions.</h6>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'PAR Program Questions'} style={parDisabled ? {backgroundColor: AppTheme.colors.largerOffsetBackground} : undefined}>
        {parDisabled ? renderParDisabledMessage() : null}
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Were you arrested for attempting to purchase sex or sexual favors?'}>
            <StandardCheckboxInput name={getParProgramFieldName('arrestedForSex')} label={'Yes'} disabled={parDisabled}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'What county were you arrested in?'} columnSize={7}>
            <Input name={getParProgramFieldName('arrestedCounty')} disabled={parDisabled || !parValues.arrestedForSex} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Case Number'} columnSize={5}>
            <Input name={getParProgramFieldName('caseNumber')} disabled={parDisabled || !parValues.arrestedForSex} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <h6>If the participant answered YES to the PAR program assessment section, please STOP HERE and give the assessment to Jennifer Duarte.</h6>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
    </>
  );
};
