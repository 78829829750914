import React from 'react';
import {CSSProperties} from 'react';
import {AppTheme} from '../../../../appTheme';

interface Props {
  existing: boolean;
  newText?: string;
  newColor?: string;
  existingText?: string;
  existingColor?: string;
  styles?: CSSProperties;
}

export function CompletionStateIndicator(props: Props) {
  return (
    <div style={{fontWeight: 'bolder', fontSize: '1rem', color: props.existing ? AppTheme.colors.warning : AppTheme.colors.success, ...props.styles}}>
      {props.existing ? props.existingText ?? 'Editing Existing' : props.newText ?? 'New'}
    </div>
  );
}
