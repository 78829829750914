import {
  SecondaryIntake,
  SecondaryIntakeCitizenshipAndIncomeInformation, SecondaryIntakeCommentsAndConcerns, SecondaryIntakeCriminalAndHousing,
  SecondaryIntakeEducationAndEmployment,
  SecondaryIntakeFamilyAndMedicalHistory,
  SecondaryIntakePersonalEmergencyInformationAndLifeIssues,
  SecondaryIntakeSubstanceAbuseAndTraffickingInformation
} from '../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {
  PrimaryIntake, PrimaryIntakeAssessmentQuestions, PrimaryIntakeClosingInformation,
  PrimaryIntakeHumanTraffickingIdentificationQuestions,
  PrimaryIntakeHumanTraffickingInformation,
  PrimaryIntakeParProgramQuestions,
  PrimaryIntakePersonalInformation
} from '../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {FieldOrGroup as QbField, SimpleField} from '@react-awesome-query-builder/bootstrap';
import {TypedMap} from '@react-awesome-query-builder/bootstrap';
import {startCase} from 'lodash';
import {capitalizeFirstLetter} from '../../../../../redux/util/string';

export const BaseFieldConfig = (entry: [string, any]): SimpleField => {
  const [key, val] = entry;
  return {
    label: startCase(key),
    type: typeof val === 'string' ? 'text' : typeof val,
    valueSources: ['value'],
    fieldSettings: {
      showSearch: true
    }
  };
};

export const BaseQueryDateConfig = (key: string): SimpleField => ({
  label: startCase(key),
  type: 'date',
  valueSources: ['value'],
  fieldSettings: {
    valueFormat: 'YYYY-MM-DD'
  },
  operators: [
    'equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'not_between'
  ]
});

export const getPrimaryIntakeTableName = (name: keyof PrimaryIntake) => `primaryIntake${name}`;
export const getSecondaryIntakeTableName = (name: keyof SecondaryIntake) => `secondaryIntake${name}`;

export const primaryIntakeTableNames = {
  personal: getPrimaryIntakeTableName('personalInformation'),
  traffickingIdentification: getPrimaryIntakeTableName('humanTraffickingIdentificationQuestions'),
  par: getPrimaryIntakeTableName('parProgramQuestions'),
  assessment: getPrimaryIntakeTableName('assessmentQuestions'),
  traffickingInformation: getPrimaryIntakeTableName('humanTraffickingInformation'),
  closing: getPrimaryIntakeTableName('closingInformation')
} as const;

export const getQueryPrimaryPersonalFieldName = (name: keyof PrimaryIntakePersonalInformation) => `${primaryIntakeTableNames.personal}.${name}`;
export const getQueryPrimaryTraffickingIdentificationFieldName = (name: keyof PrimaryIntakeHumanTraffickingIdentificationQuestions) => `${primaryIntakeTableNames.traffickingIdentification}.${name}`;
export const getQueryPrimaryParFieldName = (name: keyof PrimaryIntakeParProgramQuestions) => `${primaryIntakeTableNames.par}.${name}`;
export const getQueryPrimaryAssessmentFieldName = (name: keyof PrimaryIntakeAssessmentQuestions) => `${primaryIntakeTableNames.assessment}.${name}`;
export const getQueryPrimaryTraffickingInformationFieldName = (name: keyof PrimaryIntakeHumanTraffickingInformation) => `${primaryIntakeTableNames.traffickingInformation}.${name}`;
export const getQueryPrimaryClosingFieldName = (name: keyof PrimaryIntakeClosingInformation) => `${primaryIntakeTableNames.closing}.${name}`;

export const secondaryIntakeTableNames = {
  personal: getSecondaryIntakeTableName('personalEmergencyInformationAndLifeIssues'),
  citizenship: getSecondaryIntakeTableName('citizenshipAndIncomeInformation'),
  substance: getSecondaryIntakeTableName('substanceAbuseAndTraffickingInformation'),
  family: getSecondaryIntakeTableName('familyAndMedicalHistory'),
  education: getSecondaryIntakeTableName('educationAndEmployment'),
  criminal: getSecondaryIntakeTableName('criminalAndHousing'),
  comments: getSecondaryIntakeTableName('commentsAndConcerns')
} as const;

export const getQuerySecondaryPersonalFieldName = (name: keyof SecondaryIntakePersonalEmergencyInformationAndLifeIssues) => `${secondaryIntakeTableNames.personal}.${name}`;
export const getQuerySecondaryCitizenshipFieldName = (name: keyof SecondaryIntakeCitizenshipAndIncomeInformation) => `${secondaryIntakeTableNames.citizenship}.${name}`;
export const getQuerySecondarySubstanceFieldName = (name: keyof SecondaryIntakeSubstanceAbuseAndTraffickingInformation) => `${secondaryIntakeTableNames.substance}.${name}`;
export const getQuerySecondaryFamilyFieldName = (name: keyof SecondaryIntakeFamilyAndMedicalHistory) => `${secondaryIntakeTableNames.family}.${name}`;
export const getQuerySecondaryEducationFieldName = (name: keyof SecondaryIntakeEducationAndEmployment) => `${secondaryIntakeTableNames.education}.${name}`;
export const getQuerySecondaryCriminalFieldName = (name: keyof SecondaryIntakeCriminalAndHousing) => `${secondaryIntakeTableNames.criminal}.${name}`;
export const getQuerySecondaryCommentsFieldName = (name: keyof SecondaryIntakeCommentsAndConcerns) => `${secondaryIntakeTableNames.comments}.${name}`;

export type TQueryBuilderEnumConfig = {key: string; enum: any}[];

// custom fields type or implementation may need to be tweaked, not used atm
export function makeStandardConfig<T extends object>(
  form: T, structLabelPrefix?: string, structPrefix?: string, groupPrefix?: string, customFields?: TypedMap<QbField>, excludedKeys?: string[],
  dateKeys?: string[], enumerables?: TQueryBuilderEnumConfig): TypedMap<QbField> {

  const createField = (entry: [string, any]): SimpleField => {
    const [key, val] = entry;
    if (dateKeys?.includes(key) || val instanceof Date) {
      return {
        ...BaseQueryDateConfig(key),
        label: startCase(key)
      };
    }
    const enumerable = enumerables?.find(e => e.key === key)?.enum as unknown as any;
    if (enumerable) {
      return {
        ...BaseFieldConfig(entry),
        type: 'select',
        fieldSettings: {
          listValues:
            Object.entries(enumerable).filter(([k, v]) => typeof v === 'number').map(([k, v]) => (
              {value: v, title: startCase(k)}
            ))
        }
      };
    }
    return BaseFieldConfig(entry);
  };

  const standardConfig = Object.entries(form).reduce((accumulator, entry: [string, any]) => {
    const [key, value] = entry;
    if (excludedKeys?.includes(key))
      return accumulator;
    if (Array.isArray(value)) {
      return accumulator;
    }
    // recursive call as a struct if there is a nested object
    if (typeof value === 'object') {
      return {
        ...accumulator,
        [`${structPrefix}${capitalizeFirstLetter(key)}`]: {
          type: '!struct',
          label: `${structLabelPrefix}${startCase(key)}`,
          subfields: makeStandardConfig(value, structLabelPrefix, structPrefix, groupPrefix, customFields, excludedKeys, dateKeys, enumerables)
        } as QbField
      };
    }
    // if value is not an object, error message and create shape entry
    if (key === 'id')
      return accumulator;
    return {...accumulator, [capitalizeFirstLetter(key)]: createField(entry)};
  }, {});
  return {...standardConfig, ...customFields};
}
