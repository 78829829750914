import React from 'react';
import {Alert, Row} from 'react-bootstrap';
import {
  SecondaryIntake as SI,
  SecondaryIntakePersonalEmergencyInformationAndLifeIssues as SIPersonalIssues
} from '../../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {useFormikContext} from 'formik';
import Input from '../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {
  ESecondaryIntakeEthnicity as EEthnicity,
  ESecondaryIntakeGenderIdentification as EGender
} from '../../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntakeHelperModels';
import {
  StandardCheckboxInput,
  StandardInputColumn,
  StandardParentInputRow,
  StandardRadioInput,
  StandardTopPaddedRow
} from '../../../../../../../components/util/form-components/standardLayout';
import DatepickerInput
  from '../../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {
  DeletableFieldArray
} from '../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/DeletableFieldArray';
import {
  FieldArrayRow
} from '../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/FieldArrayRow';
import {
  makeSecondaryIntakeArrest,
  makeSecondaryIntakeChildNameAndAge
} from '../../../../../../../redux/web/factory/forms/intakeForms';
import {RedErrorMessage} from '../../../../../../../components/util/form-components/RedErrorMessage/RedErrorMessage';
import PhoneInputField
  from '../../../../../../../components/util/form-components/formik-inputs/PhoneNumberInput/PhoneNumberInput';

// TODO add ethnicity
export const PersonalEmergencyInformationLifeIssuesForm = () => {
  const {values, setFieldValue} = useFormikContext<SI>();
  const formValues = values.personalEmergencyInformationAndLifeIssues;
  // return type not really needed, but it's cool you can do this
  const getFieldName = (name: keyof SIPersonalIssues): `${keyof SI}.${keyof SIPersonalIssues}` =>
    `personalEmergencyInformationAndLifeIssues.${name}`;
  return (
    <>
      <StandardParentInputRow label={'Personal Information'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'First Name'} columnSize={4}>
            <Input name={getFieldName('firstName')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Last Name'} columnSize={5}>
            <Input name={getFieldName('lastName')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Middle Initial (can be blank)'} columnSize={3}>
            <Input name={getFieldName('middleInitial')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Any other names ever used'} columnSize={12}>
            <Input name={getFieldName('otherNames')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Street Address'} columnSize={8}>
            <Input name={getFieldName('streetAddress')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Apartment / Unit #'} columnSize={4}>
            <Input name={getFieldName('apartmentOrUnit')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'City'} columnSize={7}>
            <Input name={getFieldName('city')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'State'} columnSize={3}>
            <Input name={getFieldName('state')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'ZIP'} columnSize={2}>
            <Input name={getFieldName('zipCode')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Primary Phone (can be blank)'} columnSize={6}>
            <PhoneInputField name={getFieldName('primaryPhone')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Secondary Phone (can be blank)'} columnSize={6}>
            <PhoneInputField name={getFieldName('secondaryPhone')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Email'} columnSize={8}>
            <Input name={getFieldName('email')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'SSN or Government ID'}>
            <Input name={getFieldName('ssnOrGovtId')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Demographics'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'I Identify As'}>
            <Row>
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Female'}
                value={EGender.female}
                checked={formValues.identifyAs === EGender.female}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Male'}
                value={EGender.male}
                checked={formValues.identifyAs === EGender.male}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Transgender Male to Female'}
                value={EGender.transgenderMaleToFemale}
                checked={formValues.identifyAs === EGender.transgenderMaleToFemale}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Transgender Female to Male'}
                value={EGender.transgenderFemaleToMale}
                checked={formValues.identifyAs === EGender.transgenderFemaleToMale}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Gender Variant / Non-Conforming'}
                value={EGender.clientDoesNotIdentify}
                checked={formValues.identifyAs === EGender.clientDoesNotIdentify}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Prefer not to answer'}
                value={EGender.clientDoesNotKnow}
                checked={formValues.identifyAs === EGender.clientDoesNotKnow}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Not Listed'}
                value={EGender.clientRefused}
                checked={formValues.identifyAs === EGender.clientRefused}
              />
              <StandardRadioInput
                name={getFieldName('identifyAs')}
                type={'radio'}
                label={'Not Listed'}
                value={EGender.notCollected}
                checked={formValues.identifyAs === EGender.notCollected}
              />
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Ethnicity'}>
            <Row>
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'Asian'}
                value={EEthnicity.asian}
                checked={formValues.ethnicity === EEthnicity.asian}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'American Indian or Alaska Native'}
                value={EEthnicity.americanIndianOrAlaskaNative}
                checked={formValues.ethnicity === EEthnicity.americanIndianOrAlaskaNative}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'Black or African American'}
                value={EEthnicity.blackOrAfricanAmerican}
                checked={formValues.ethnicity === EEthnicity.blackOrAfricanAmerican}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'Hispanic or Latino'}
                value={EEthnicity.hispanicOrLatino}
                checked={formValues.ethnicity === EEthnicity.hispanicOrLatino}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'Native Hawaiian or Other Pacific Islander'}
                value={EEthnicity.nativeHawaiianOrOtherPacificIslander}
                checked={formValues.ethnicity === EEthnicity.nativeHawaiianOrOtherPacificIslander}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'White or Caucasian'}
                value={EEthnicity.whiteOrCaucasian}
                checked={formValues.ethnicity === EEthnicity.whiteOrCaucasian}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'Multiple Races'}
                value={EEthnicity.multipleRaces}
                checked={formValues.ethnicity === EEthnicity.multipleRaces}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'Other Race'}
                value={EEthnicity.other}
                checked={formValues.ethnicity === EEthnicity.other}
              />
              <Input
                name={getFieldName('otherRaceEthnicity')}
                disabled={formValues.ethnicity !== EEthnicity.other}
                valueOnDisabled={'N/A'}
              />
              <StandardRadioInput
                name={getFieldName('ethnicity')}
                type={'radio'}
                label={'Not Reported'}
                value={EEthnicity.notReported}
                checked={formValues.ethnicity === EEthnicity.notReported}
              />
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Birth Date'} columnSize={4}>
            <DatepickerInput name={getFieldName('birthDate')} onChangeUtcHour={12} showYearDropdown={true}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Marital Status'} columnSize={5}>
            <Input name={getFieldName('maritalStatus')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Do you have children?'} columnSize={3}>
            <StandardCheckboxInput name={getFieldName('hasChildren')} label={'Yes'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Children Names & Ages'}>
            <DeletableFieldArray
              name={getFieldName('childrenNamesAndAges')}
              values={formValues.childrenNamesAndAges}
              factoryFn={makeSecondaryIntakeChildNameAndAge}
              disabled={!formValues.hasChildren}
              noItemsLabel={'No children added.'}
              renderRow={(item, rowName, getItemName, disabled) => (
                <FieldArrayRow style={{flex: 'auto', minWidth: '13rem', marginRight: '1.5rem'}}>
                  <StandardInputColumn label={'Name'} columnSize={6} className='pl-2'>
                    <Input name={`${rowName}.${getItemName('name')}`} disabled={disabled}/>
                  </StandardInputColumn>
                  <StandardInputColumn label={'Birth Date'} columnSize={6} className='pl-2'>
                    <DatepickerInput
                      name={`${rowName}.${getItemName('birthDate')}`}
                      disabled={disabled}
                      onChangeUtcHour={12}
                      showYearDropdown={true}
                      ignoreOverflow={true}
                    />
                  </StandardInputColumn>
                </FieldArrayRow>
              )}
              renderFooter={() => (
                <>
                  {!formValues.hasChildren && formValues.childrenNamesAndAges.length ?
                    <Alert variant='warning' style={{margin: '15px'}}>
                      Children will not be submitted if the field is disabled.
                    </Alert> : null}
                  <RedErrorMessage name={getFieldName('childrenNamesAndAges')}/>
                </>
              )}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Life Issues'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever participated in Breaking the Chains before?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasParticipatedInBtc')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Have you ever been in a shelter or program?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasBeenInShelterOrProgram')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Have you ever used drugs or alcohol?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasUsedDrugsOrAlcohol')} label={'Yes'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If you have used drugs or alcohol, please list:'}>
            <Input name={getFieldName('drugsUsed')} disabled={!formValues.hasUsedDrugsOrAlcohol} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If yes, please list the date you last used'} columnSize={4}>
            <DatepickerInput
              name={getFieldName('drugsUsedDate')}
              disabled={!formValues.hasUsedDrugsOrAlcohol}
              valueOnDisabled={null}
              onChangeUtcHour={12}
              showYearDropdown={true}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'Have you ever been arrested?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasBeenArrested')} label={'Yes'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Please list arrest dates and descriptions below.'}>
            <DeletableFieldArray
              name={getFieldName('arrestDatesAndDescriptions')}
              values={formValues.arrestDatesAndDescriptions}
              factoryFn={makeSecondaryIntakeArrest}
              disabled={!formValues.hasBeenArrested}
              noItemsLabel={'No arrests added.'}
              renderRow={(item, rowName, getItemName, disabled) => (
                <FieldArrayRow style={{flex: 'auto', minWidth: '13rem', marginRight: '1.5rem'}}>
                  <StandardInputColumn label={'When'} columnSize={6} className='pl-2'>
                    <Input name={`${rowName}.${getItemName('when')}`} disabled={disabled}/>
                  </StandardInputColumn>
                  <StandardInputColumn label={'Description'} columnSize={6} className='pl-2'>
                    <Input name={`${rowName}.${getItemName('description')}`} disabled={disabled} type={'textarea'}/>
                  </StandardInputColumn>
                </FieldArrayRow>
              )}
              renderFooter={() => (
                <>
                  {!formValues.hasBeenArrested && formValues.arrestDatesAndDescriptions.length ?
                    <Alert variant='warning' style={{margin: '15px'}}>
                      Arrests will not be submitted if the field is disabled.
                    </Alert> : null}
                  <RedErrorMessage name={getFieldName('arrestDatesAndDescriptions')}/>
                </>
              )}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Emergency Contact Information'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'First Name'} columnSize={4}>
            <Input name={getFieldName('ecFirstName')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Last Name'} columnSize={5}>
            <Input name={getFieldName('ecLastName')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Relationship'} columnSize={3}>
            <Input name={getFieldName('ecRelationship')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Street Address'} columnSize={8}>
            <Input name={getFieldName('ecStreetAddress')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Apartment / Unit #'} columnSize={4}>
            <Input name={getFieldName('ecApartmentOrUnit')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'City'} columnSize={5}>
            <Input name={getFieldName('ecCity')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'State'} columnSize={3}>
            <Input name={getFieldName('ecState')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'ZIP'} columnSize={4}>
            <Input name={getFieldName('ecZipCode')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Primary Phone (can be blank)'} columnSize={6}>
            <PhoneInputField name={getFieldName('ecPrimaryPhone')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Secondary Phone (can be blank)'} columnSize={6}>
            <PhoneInputField name={getFieldName('ecSecondaryPhone')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
    </>
  );
};
