import React, {Dispatch, FormEvent, SetStateAction} from 'react';
import {FormControl, FormControlProps} from 'react-bootstrap';
import zxcvbn from 'zxcvbn';
import {FieldInputProps} from 'formik';
import {isNaN, split} from 'lodash';
import {AccountStatus, User} from '../redux/web/entities/user';

export function combineClasses(...arr: (any|string|undefined|null)[]): string {
  return arr.filter((val) => !!val).join(' ');
}

export type BootstrapFormEvent = FormEvent<typeof FormControl & FormControlProps>;

export function nullable<T>(value: T | null): T | undefined {
  if (value) {
    return value;
  }
  return undefined;
}

export type Stringify<T> = { [K in keyof T]: string|undefined };

export function isPassValid(password: string) {
  const result = zxcvbn(password);
  return result.score >= 1;
}

export const isEmailValid = (email: string) => {
  return email.length === 0 || /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email);
};

export const isImageExtension = (fileName: string) => (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(fileName).valueOf();

export const removeSpecialCharacters = (str: string) => str.replace(/[^a-zA-Z ]/g, '').trim();
export const camelCaseToTitle = (str: string) => str.replace(/([A-Z])/g, ' $1').trim();

export function getFieldValue(value: string | null | undefined) {
  if (value === undefined || value === null) {
    return '';
  }
  return value;
}

export function getFieldCheckValue<T>(field: FieldInputProps<T>) {
  if (field.value === undefined || field.value === null) {
    return false;
  }
  return field.value;
}

export type FormikSetFieldValue<T = string> = (field: T, value: any, shouldValidate?: boolean) => void;
export type FormikSetFieldTouched<T = string> = (field: T & string, isTouched?: boolean, shouldValidate?: boolean) => void;

export type Complete<T> = {
  [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>> ? T[P] : (T[P]);
};

export type NonNullableObject<T> = {
  [P in keyof T]: NonNullable<T[P]>
};

export type SetStateFunctional<S> = Dispatch<SetStateAction<S>>;

export function pipeLog<T>(value: T, extra?: any): T {
  const extraParams = [];
  if (extra) {
    extraParams.push(extra);
  }
  // tslint:disable-next-line:no-console
  console.log(value, ...extraParams);
  return value;
}

export const toMutable = <T>(val: readonly T[]) => val as T[];

export const nodeListToArray = (nodeList: NodeList | HTMLCollection) => [].slice.call(nodeList) as HTMLElement[];

export type EnumValues<T> = T[keyof T];

export const getExtension = (fileName: string) => fileName !== undefined ? fileName.slice(fileName.lastIndexOf('.'), fileName.length) : '';

export function getBase64(file: File):Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = error => reject(error);
  });
}

export function getBase64FromArrayBuffer(array: ArrayBuffer):Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const blob = new Blob([array], { type: 'application/pdf' });
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = error => reject(error);
  });
}


export const now = new Date();

export const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const localTzFriendlyFormat = localTz.replace('_', ' ');

const getTimezoneFromDate = (dateAsString: string) => dateAsString.match(/\(([^\)]+)\)$/)![1];

export const splitHTMLTimeInput = (militaryTime: string) => {
  return split(militaryTime, ':', 2).map((i) => {
    const int = parseInt(i, 10);
      if (isNaN(int)) {
        throw new Error('TimeNaN');
      }
      return int;
  });
};

export const setDateHoursAndMinutes = (date: Date, time: string) => {
  const parts = (time as any).split(':');
  date.setHours(parts[0]);
  date.setMinutes(parts[1]);
  return date;
};

export const makeFieldArrayFieldName = (entry: string, name: string) => `${entry}.${name}`;

