import React, {useState} from 'react';
import {AxiosError} from 'axios';
import {Card, Col, Container, Row} from 'react-bootstrap';
import styles from './GrantExport.module.scss';
import PageHeader from '../../components/layout/PageHeader/PageHeader';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../redux/core/types/WebState';
import {connect} from 'react-redux';
import {loadCaseFileDocumentState, loadPrimaryIntakeData} from '../../redux/web/stateResponses/stateManagement';
import {handleAxiosError} from '../../redux/util/http';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {useMount} from '../../hooks/useMount';
import ReportBuilder from './components/ReportBuilder';


type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function GrantExport(props: Props) {
  const {actions: {loadIntakes}} = props;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState(true);

  useMount(async () => {
    try {
      await loadIntakes();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load Data'}));
    }
    setLoading(false);
  });

  const renderContent = () => {
    return (
      <Row style={{flexGrow: 1}}>
        <Col style={{padding: '0'}}>
          <Card className={styles['page-body']} style={{borderLeftWidth: 0, borderRightWidth: 0}}>
            <Card.Body>
              <ReportBuilder/>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <PageHeader label='Chore Chart' removeMargin={true}>
      <Container fluid={true} className={styles['config-container']}>
        {loading ?  <CenteredSpinner/> : (
          errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
            renderContent()
        )}
      </Container>
    </PageHeader>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadIntakes: loadPrimaryIntakeData
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(GrantExport);












