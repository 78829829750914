import {
  TAdmissionCoverSheetForm
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/AdmissionCoverSheetModal/AdmissionCoverSheetModal';
import {AdmissionCoverSheet} from '../../entities/forms/admissionCoverSheet';
import {PrimaryIntake} from '../../entities/forms/intake/primaryIntake/PrimaryIntake';
import {SecondaryIntake} from '../../entities/forms/intake/secondaryIntake/secondaryIntake';
import {
  EPrimaryIntakeEthnicity,
  EPrimaryIntakeGenderIdentification
} from '../../entities/forms/intake/primaryIntake/primaryIntakeHelperModels';
import {startCase} from 'lodash';

export function makeAdmissionCoverSheet(
  userId: string, existingCoverSheet?: AdmissionCoverSheet, primaryIntake?: PrimaryIntake, secondaryIntake?: SecondaryIntake): TAdmissionCoverSheetForm {
    const prim = primaryIntake?.personalInformation;
    const sec = secondaryIntake?.personalEmergencyInformationAndLifeIssues;
    return existingCoverSheet ? {...existingCoverSheet, userId: userId} : {
      userId: userId,
      firstName: prim?.firstName ?? '',
      lastName: prim?.lastName ?? '',
      middleInitial: prim?.middleInitial ?? '',
      dateOfBirth: prim?.birthDate ?? '',
      streetAddress: prim?.streetAddress ?? '',
      apartmentOrUnit: prim?.apartmentOrUnit ?? '',
      city: prim?.city ?? '',
      state: prim?.state ?? '',
      zipCode: prim?.zipCode ?? '',
      primaryPhone: prim?.primaryPhone ?? '+1',
      secondaryPhone: prim?.secondaryPhone ?? '+1',
      maritalStatus: prim ? prim.maritalStatus : '',
      sexOrGender: prim ? startCase(EPrimaryIntakeGenderIdentification[prim.identifyAs]) : '',
      raceOrEthnicity: prim ? startCase(EPrimaryIntakeEthnicity[prim.ethnicity]) : '',
      emergencyContactFirstName: sec?.ecFirstName ?? '',
      emergencyContactLastName: sec?.ecLastName ?? '',
      emergencyContactRelationshipToClient: sec ? sec.ecRelationship : '',
      emergencyContactStreetAddress: sec?.ecStreetAddress ?? '',
      emergencyContactApartmentOrUnit: sec?.ecApartmentOrUnit ?? '',
      emergencyContactCity: sec?.ecCity ?? '',
      emergencyContactState: sec?.ecState ?? '',
      emergencyContactZipCode: sec?.ecZipCode ?? '',
      emergencyContactPrimaryPhone: sec ? sec.ecPrimaryPhone : '+1',
      emergencyContactSecondaryPhone: sec ? sec.ecSecondaryPhone : '+1',
      updatedOn: ''
    };
}
