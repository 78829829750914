import React from 'react';
import {HTMLAttributes, PropsWithChildren} from 'react';
import {Row} from 'react-bootstrap';

type Props = HTMLAttributes<HTMLElement> & PropsWithChildren;

export function FieldArrayRow({children, ...htmlProps}: Props) {
  return (
    <Row style={{flex: '1', marginRight: '15px'}} {...htmlProps}>
      {children}
    </Row>
  );
}
