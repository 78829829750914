import React from 'react';
import {
  PrimaryIntakeClosingInformation
} from '../../../../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {
  StandardParentInputRow ,
  StandardTopPaddedRow,
  StandardRadioInput,
  StandardInputColumn
} from '../../../../../../../../components/util/form-components/standardLayout';
import {Row} from 'react-bootstrap';
import {
  EPrimaryIntakeHumanTraffickingType, EPrimaryIntakeResidencyType
} from '../../../../../../../../redux/web/entities/forms/intake/primaryIntake/primaryIntakeHelperModels';
import {useFormikContext} from 'formik';
import {TPrimaryIntakeUpsertForm} from '../../PrimaryIntakeModal';
import Input from '../../../../../../../../components/util/form-components/formik-inputs/Input/Input';

export type TClosingInformationForm = Omit<PrimaryIntakeClosingInformation, 'id'> & {
  id?: string;
};

interface Props {
  fieldPrefix: string;
}

export const ClosingInformationForm = (props: Props) => {
  const {values} = useFormikContext<TPrimaryIntakeUpsertForm>();
  const closingInfoValues = values.closingInformation;
  const getFieldName = (name: keyof TClosingInformationForm) => `${props.fieldPrefix}.${name}`;
  return (
    <>
      <StandardParentInputRow label={'Closing Information'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Comments and Concerns'}>
            <Input name={getFieldName('commentsAndConcerns')} type={'textarea'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Client Program'}>
            <Row>
              <StandardRadioInput
                name={getFieldName('residencyType')}
                type={'radio'}
                label={'Diversion Class'}
                value={EPrimaryIntakeResidencyType.diversionClass}
                checked={closingInfoValues.residencyType === EPrimaryIntakeResidencyType.diversionClass}
              />
              <StandardRadioInput
                name={getFieldName('residencyType')}
                type={'radio'}
                label={'Non-Residential'}
                value={EPrimaryIntakeResidencyType.nonResidential}
                checked={closingInfoValues.residencyType === EPrimaryIntakeResidencyType.nonResidential}
              />
              <StandardRadioInput
                name={getFieldName('residencyType')}
                type={'radio'}
                label={'Residential'}
                value={EPrimaryIntakeResidencyType.residential}
                checked={closingInfoValues.residencyType === EPrimaryIntakeResidencyType.residential}
              />
              <StandardRadioInput
                name={getFieldName('residencyType')}
                type={'radio'}
                label={'Still to be Determined'}
                value={EPrimaryIntakeResidencyType.stillToBeDetermined}
                checked={closingInfoValues.residencyType === EPrimaryIntakeResidencyType.stillToBeDetermined}
              />
              <StandardRadioInput
                name={getFieldName('residencyType')}
                type={'radio'}
                label={'CSEC'}
                value={EPrimaryIntakeResidencyType.csec}
                checked={closingInfoValues.residencyType === EPrimaryIntakeResidencyType.csec}
              />
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Referred to'} columnSize={6}>
            <Input name={getFieldName('referredTo')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Person who did assessment'} columnSize={6}>
            <Input name={getFieldName('personWhoDidAssessment')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
    </>
  );
};
