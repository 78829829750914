import {makeStandardConfig, TQueryBuilderEnumConfig} from './configUtils';
import {
  EPrimaryIntakeEthnicity,
  EPrimaryIntakeGenderIdentification, EPrimaryIntakeLanguage, EPrimaryIntakeRelocation, EPrimaryIntakeResidencyType
} from '../../../../../redux/web/entities/forms/intake/primaryIntake/primaryIntakeHelperModels';
import {makePrimaryIntakeForm, makeSecondaryIntake} from '../../../../../redux/web/factory/forms/intakeForms';
import {
  PrimaryIntake, PrimaryIntakeClosingInformation, PrimaryIntakeHumanTraffickingInformation,
  PrimaryIntakePersonalInformation
} from '../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {
  SecondaryIntake,
  SecondaryIntakeCriminalAndHousing, SecondaryIntakeEducationAndEmployment,
  SecondaryIntakeFamilyAndMedicalHistory,
  SecondaryIntakePersonalEmergencyInformationAndLifeIssues
} from '../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {
  ESecondaryIntakeBirthStatus,
  ESecondaryIntakeCurrentHealthStatus,
  ESecondaryIntakeEthnicity,
  ESecondaryIntakeGenderIdentification,
  ESecondaryIntakeHomelessStatus
} from '../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntakeHelperModels';

const getPrimaryIntakeFieldName = (name: keyof PrimaryIntake) => name;
const getPrimaryPersonalName = (name: keyof PrimaryIntakePersonalInformation) => name;
const getPrimaryTraffickingInfoName = (name: keyof PrimaryIntakeHumanTraffickingInformation) => name;
const getPrimaryClosingFieldName = (name: keyof PrimaryIntakeClosingInformation) => name;

const getSecondaryIntakeFieldName = (name: keyof SecondaryIntake) => name;
const getSecondaryPersonal = (name: keyof SecondaryIntakePersonalEmergencyInformationAndLifeIssues) => name;
const getSecondaryMedical = (name: keyof SecondaryIntakeFamilyAndMedicalHistory) => name;
const getSecondaryEducation = (name: keyof SecondaryIntakeEducationAndEmployment) => name;
const getSecondaryCriminal = (name: keyof SecondaryIntakeCriminalAndHousing) => name;

const excludedPrimaryKeys = [getPrimaryIntakeFieldName('employeeName'), getPrimaryIntakeFieldName('userId')];
const primaryDates = [getPrimaryPersonalName('birthDate')];
const primaryEnums: TQueryBuilderEnumConfig = [
  {key: getPrimaryPersonalName('ethnicity'), enum: EPrimaryIntakeEthnicity},
  {key: getPrimaryPersonalName('identifyAs'), enum: EPrimaryIntakeGenderIdentification},
  {key: getPrimaryPersonalName('primaryLanguage'), enum: EPrimaryIntakeLanguage},
  {key: getPrimaryTraffickingInfoName('relocation'), enum: EPrimaryIntakeRelocation},
  {key: getPrimaryClosingFieldName('residencyType'), enum: EPrimaryIntakeResidencyType}
];

const excludedSecondaryKeys = [getSecondaryIntakeFieldName('userId'), getSecondaryIntakeFieldName('createdAt')];
const secondaryDates = [getSecondaryPersonal('birthDate'), getSecondaryPersonal('drugsUsedDate'),
  getSecondaryMedical('isPregnantDueDate'), getSecondaryEducation('dateGraduatedFromTradeSchool')];
const secondaryEnums: TQueryBuilderEnumConfig = [
  {key: getSecondaryPersonal('identifyAs'), enum: ESecondaryIntakeGenderIdentification},
  {key: getSecondaryPersonal('ethnicity'), enum: ESecondaryIntakeEthnicity},
  {key: getSecondaryMedical('birthStatus'), enum: ESecondaryIntakeBirthStatus},
  {key: getSecondaryMedical('currentHealthStatus'), enum: ESecondaryIntakeCurrentHealthStatus},
  {key: getSecondaryCriminal('homelessStatus'), enum: ESecondaryIntakeHomelessStatus}
];

const primaryIntake = makePrimaryIntakeForm();
const secondaryIntake = makeSecondaryIntake('');

export const PrimaryConfig = makeStandardConfig(primaryIntake, 'Primary Intake ', 'PrimaryIntake',
  'PrimaryIntake', undefined, excludedPrimaryKeys, primaryDates, primaryEnums);

export const SecondaryConfig = makeStandardConfig(secondaryIntake, 'Secondary Intake ', 'SecondaryIntake',
  'SecondaryIntake', undefined, excludedSecondaryKeys, secondaryDates, secondaryEnums);
