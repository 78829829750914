import React, {FormEvent, useState} from 'react';
import {Alert, Button, Col, Modal, Row, Spinner, Tab, Tabs} from 'react-bootstrap';
import {Form, Formik, FormikErrors, FormikValues} from 'formik';
import styles from '../ParticipantFormModalStyles.module.scss';
import {Form as BSForm} from 'react-bootstrap';
import {StandardTopPaddedRow} from '../../../../../../components/util/form-components/standardLayout';
import {ConfirmationDialog} from '../../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../../../redux/util/http';
import {
  WeeklyCaseManagementNotes,
  weeklyCaseManagementNotesStore
} from '../../../../../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import {makeWeeklyCaseManagementNotes} from '../../../../../../redux/web/factory/forms/weeklyCaseManagementForms';
import {bindActionCreators, Dispatch} from 'redux';
import {userStore} from '../../../../../../redux/web/entities/user';
import {WebState} from '../../../../../../redux/core/types/WebState';
import {connect} from 'react-redux';
import {CaseNotesFieldArray} from './components/forms/CaseNotesForm/CaseNotesFieldArray';
import {CaseManagementDrugTestFieldArray} from './components/forms/DrugTestsForm/DrugTestsFieldArray';
import {CaseManagementStepEvaluationsFieldArray} from './components/forms/StepEvaluationsForm/StepEvaluationsFieldArray';
import {CaseManagementMedicationsFieldArray} from './components/forms/MedicationForm/MedicationsFieldArray';
import {
  CaseManagementFinancialAssistancesFieldArray
} from './components/forms/FinancialAssistanceForm/FinancialAssistancesFieldArray';
import {CaseManagementContactsFieldArray} from './components/forms/ContactsForm/ContactsForm';
import {CaseManagementCounselingsFieldArray} from './components/forms/CounselingForm/CounselingsFieldArray';
import {
  CompletionStateIndicator
} from '../../../../../../components/util/widgets/CompletionStateIndicator/CompletionStateIndicator';

export type TWeeklyCaseNotesForm = Omit<WeeklyCaseManagementNotes, 'id'> & {
    id?: string;
  };

type Props = {
  userId: string;
  editable: boolean;
  existingCaseNotes?: WeeklyCaseManagementNotes;
  onSubmit: () => void;
  onCancel: () => void;
  disabled?: boolean;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function WeeklyCaseManagementNotesModal(props: Props) {
  const {userId, editable, onSubmit, onCancel, existingCaseNotes, getUserById, disabled = true, actions: {upsertCaseManagementNotes}} = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentModal, setCurrentModal] = useState<'caseNotes' | 'cancel'>('caseNotes');
  const [errorMessage, setErrorMessage] = useState('');
  const [validateOnChangeAfterSubmit, setValidateOnChangeAfterSubmit] = useState(false);
  const [tabKey, setTabKey] = useState<any>('caseNotes');
  const username = getUserById(userId)?.name ?? 'undefined name';
  const getFieldName = (name: keyof TWeeklyCaseNotesForm) => name;

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    values: TWeeklyCaseNotesForm,
    validate: (values: TWeeklyCaseNotesForm) => Promise<FormikErrors<TWeeklyCaseNotesForm>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    setValidateOnChangeAfterSubmit(true);
    e.persist();
    e.preventDefault();
    const errors = await validate(values);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
      setErrorMessage('Errors were found in the form.');
    } else {
      try {
        console.log(JSON.stringify(values));
        await upsertCaseManagementNotes(values);
        onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(getErrorResponseMessage(e));
      }
      setIsSubmitting(false);
    }
  };

  const renderCancelConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        open={currentModal === 'cancel'}
        onAccept={async () => onCancel()}
        onDecline={async () => setCurrentModal('caseNotes')}
        positiveVariant={'success'}
        negativeVariant={'danger'}
        positiveText={'Yes'}
        negativeText={'No'}
        prompt={`Are you sure you would like to cancel your edits to ${username}'s weekly case management notes?`}
      />
    );
  };

  const renderButtons = (setValues: (val: TWeeklyCaseNotesForm) => any) => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={() => disabled ? onCancel() : setCurrentModal('cancel')} variant={'danger'} className={styles['close-button']}>
            {disabled ? 'Close' : 'Cancel'}
          </Button>
        }
        {!disabled && !isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  const tabTitleFormatter = (title: string, form: (keyof TWeeklyCaseNotesForm)[], errors: FormikErrors<TWeeklyCaseNotesForm>) => {
    const originalFormat = {tabClassName: undefined, title: title};
    const errorFormat = {tabClassName: styles['tab'], title: `${title}*`};
    return Object.values(form).find(k => k in errors) ? errorFormat : originalFormat;
  };


  return (
    <>
      <Formik
        initialValues={makeWeeklyCaseManagementNotes(userId, existingCaseNotes)}
        onSubmit={() => undefined}>
        {({values, validateForm, handleSubmit, errors, setValues}) => (
          <Modal show={currentModal === 'caseNotes'} centered={true} size={'xl'}>
            <Modal.Body>
              <Modal.Title>
                <Row>
                  {`${username}'s Weekly Case Management Notes Form`}
                    <CompletionStateIndicator
                      existing={!!existingCaseNotes}
                      existingText={'Editing Completed Form'}
                      styles={{alignSelf: 'flex-end', marginLeft: 'auto', marginRight: '15px'}}
                    />
                </Row>
              </Modal.Title>
              <Col style={{paddingTop: '10px'}}>
                <Form noValidate={false} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                  <Tabs activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                    <Tab
                      eventKey={'caseNotes'}
                      {...tabTitleFormatter('Case Notes', [getFieldName('caseNotes')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <CaseNotesFieldArray
                          caseNotes={[...values.caseNotes].sort((a, b) => (a.date > b.date) ? 1 : -1)}
                          editable={editable}
                          fieldPrefix={getFieldName('caseNotes')}
                        />
                      </fieldset>
                    </Tab>
                    <Tab eventKey={
                      getFieldName('stepEvaluations')}
                         {...tabTitleFormatter('Step Evaluations', [getFieldName('stepEvaluations')], errors)}
                         className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <CaseManagementStepEvaluationsFieldArray
                          stepEvaluations={[...values.stepEvaluations]}
                          editable={true}
                          fieldPrefix={getFieldName('stepEvaluations')}
                        />
                      </fieldset>
                    </Tab>
                    <Tab eventKey={getFieldName('medications')}
                         {...tabTitleFormatter('Medication', [getFieldName('medications')], errors)}
                         className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <CaseManagementMedicationsFieldArray
                          medications={[...values.medications]}
                          editable={true}
                          fieldPrefix={getFieldName('medications')}
                        />
                      </fieldset>
                    </Tab>
                    <Tab eventKey={getFieldName('drugTests')}
                         {...tabTitleFormatter('Drug Tests', [getFieldName('drugTests')], errors)}
                         className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <CaseManagementDrugTestFieldArray
                          drugTests={[...values.drugTests]}
                          editable={true}
                          fieldPrefix={getFieldName('drugTests')}
                        />
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={getFieldName('financialAssistances')}
                      {...tabTitleFormatter('Financial', [getFieldName('financialAssistances')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <CaseManagementFinancialAssistancesFieldArray
                          financialAssistances={[...values.financialAssistances]}
                          editable={true}
                          fieldPrefix={getFieldName('financialAssistances')}
                        />
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={getFieldName('counselings')}
                      {...tabTitleFormatter('Counseling', [getFieldName('counselings')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <CaseManagementCounselingsFieldArray counselings={[...values.counselings]} editable={true} fieldPrefix={getFieldName('counselings')}/>
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={getFieldName('contacts')}
                      {...tabTitleFormatter('Contacts', [getFieldName('contacts')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <CaseManagementContactsFieldArray
                          contacts={[...values.contacts]}
                          editable={true}
                          fieldPrefix={getFieldName('contacts')}
                        />
                      </fieldset>
                    </Tab>
                  </Tabs>
                  {errorMessage !== '' ?
                    <div style={{marginTop: '1rem'}}>
                      <Alert variant='danger'>{errorMessage}</Alert>
                    </div>
                    : null}
                  <StandardTopPaddedRow style={{paddingTop: '10px'}}>
                    <BSForm.Group className={styles['form-buttons']}>
                      {renderButtons(setValues)}
                    </BSForm.Group>
                  </StandardTopPaddedRow>
                </Form>
              </Col>
            </Modal.Body>
          </Modal>
        )}
      </Formik>
      {renderCancelConfirmationDialog()}
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertCaseManagementNotes: weeklyCaseManagementNotesStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getUserById: userStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(WeeklyCaseManagementNotesModal);

