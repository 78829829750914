import React, {useCallback, useState} from 'react';
import {
  Builder,
  BuilderProps,
  Config,
  ImmutableTree,
  Query,
  Utils as QbUtils
} from '@react-awesome-query-builder/bootstrap';
// tslint:disable-next-line:no-import-side-effect
import '@react-awesome-query-builder/bootstrap/css/styles.scss';
import {SecondaryIntake} from '../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {intakeConfig, queryValue} from './config';
import {Button, Col, Row} from 'react-bootstrap';

interface Props {
  fields: SecondaryIntake[];
  loading: boolean;
  onSubmit: (query: string | undefined) => void;
}

export function IntakeQueryBuilder(props: Props) {


  // @ts-ignore
  const [state, setState] = useState({tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), intakeConfig), config: intakeConfig});

  const onChange = useCallback((immutableTree: ImmutableTree, config: Config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setState(prevState => ({ ...prevState, tree: immutableTree, config: config }));
  }, []);

  const renderBuilder = useCallback((builderProps: BuilderProps) => (
    <div className='query-builder-container' style={{ padding: '10px' }}>
      <div className='query-builder qb-lite'>
        <Builder {...builderProps} />
      </div>
    </div>
  ), []);

  return (
    <Col>
      <Row>
        <Button onClick={async () => props.onSubmit(QbUtils.sqlFormat(state.tree, state.config))}>
          Send query
        </Button>
      </Row>
      <Query
        {...intakeConfig}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
    </Col>
  );
}
