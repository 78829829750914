import {
  ESecondaryIntakeBirthStatus,
  ESecondaryIntakeCurrentHealthStatus,
  ESecondaryIntakeEthnicity,
  ESecondaryIntakeGenderIdentification, ESecondaryIntakeHomelessStatus
} from './secondaryIntakeHelperModels';
import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../../../../utils';
import {createStandardSelectors, getEntities, selector} from '../../../../selectors';
import {combineReducers} from 'redux';
import {CommonDispatch} from '../../../../index';
import {getSecondaryIntake, upsertSecondaryIntake} from '../../../../../../api/forms/formApi';

export interface SecondaryIntakeChildNameAndAge {
  id: string;
  name: string;
  birthDate: string;
}

export interface SecondaryIntakeArrest {
  id: string;
  when: string;
  description: string;
}

export interface SecondaryIntakePersonalEmergencyInformationAndLifeIssues {
  id: string;
  firstName: string;
  lastName: string;
  middleInitial: string;
  otherNames: string;
  streetAddress: string;
  apartmentOrUnit: string;
  city: string;
  state: string;
  zipCode: string;
  primaryPhone: string;
  secondaryPhone: string;
  identifyAs: ESecondaryIntakeGenderIdentification;
  email: string;
  ssnOrGovtId: string;
  birthDate: string;
  maritalStatus: string;
  ethnicity: ESecondaryIntakeEthnicity;
  otherRaceEthnicity: string;
  hasChildren: boolean;
  childrenNamesAndAges: SecondaryIntakeChildNameAndAge[];
  hasParticipatedInBtc: boolean;
  hasBeenInShelterOrProgram: boolean;
  hasUsedDrugsOrAlcohol: boolean;
  drugsUsed: string;
  drugsUsedDate: string;
  hasBeenArrested: boolean;
  arrestDatesAndDescriptions: SecondaryIntakeArrest[];
  ecFirstName: string;
  ecLastName: string;
  ecRelationship: string;
  ecOtherNames: string;
  ecStreetAddress: string;
  ecApartmentOrUnit: string;
  ecCity: string;
  ecState: string;
  ecZipCode: string;
  ecPrimaryPhone: string;
  ecSecondaryPhone: string;
}

export interface SecondaryIntakeCitizenshipAndIncomeInformation {
  id: string;
  bornAt: string;
  usCitizen: boolean;
  placeOfCitizenship: string;
  lengthOfUsLocation: string;
  hasUsedOtherNames: boolean;
  otherNames: string;
  sourceOfIncome: string;
  grossAnnual: string;
  grossMonth: string;
  afdc: boolean;
  calFresh: boolean;
  generalRelief: boolean;
  disability: boolean;
  ssi: boolean;
  ssa: boolean;
  ss: boolean;
  veteransBenefits: boolean;
  nativeAmerican: boolean;
  medicaid: boolean;
  medical: boolean;
  earnedEmployment: boolean;
  employerName: string;
  otherExplanation: string;
  socialWorkerName: string;
  socialWorkerPhone: string;
  checkingOrSavingsAccount: boolean;
  isAnythingWouldPreventAccount: boolean;
  isAnythingWouldPreventAccountDescription: string;
}

export interface SecondaryIntakeSubstanceAbuseAndTraffickingInformation {
  id: string;
  drugsAndAlcoholFrequency: string;
  blackoutOrSeizure: boolean;
  blackoutOrSeizureSubstance: string;
  blackoutOrSeizureDescription: string;
  parentsUsedDrugs: boolean;
  parentsUsedDrugsDescription: string;
  usedSubstancesDuringProstitution: boolean;
  receivedSubstanceAbuseTreatment: boolean;
  receivedSubstanceAbuseTreatmentDescription: string;
  usedSubstancesPriorToSexTrafficking: boolean;
  ageOfFirstSubstanceUse: string;
  hasEngagedInSexOrLaborTrade: boolean;
  ageOfFirstTrafficking: string;
  wasInRelationshipDuringTrafficking: boolean;
  hasBeenArrested: boolean;
  hadTrafficker: boolean;
  traffickerWasRelatedToProstitution: boolean;
  wasInFosterCare: boolean;
  wasInFosterCaseLength: string;
  hasHadPoliceContact: boolean;
  hasRunawayAsChild: boolean;
  freedomWasInJeopardy: boolean;
  freedomWasInJeopardyDueToSexTrafficking: boolean;
  freedomWasInJeopardyDueToLaborTrafficking: boolean;
  freedomWasInJeopardyOtherDescription: string;
  freedomWasInJeopardySexOrLaborAge: string;
  freedomWasInJeopardySexOrLaborDescription: string;
}

export interface SecondaryIntakeMedication {
  id: string;
  name: string;
  dose: string;
  frequency: string;
}

export interface SecondaryIntakeFamilyAndMedicalHistory {
  id: string;
  mothersName: string;
  fathersName: string;
  areBiologicalParents: boolean;
  areNotBiologicalParentsDescription: string;
  hasAnySiblings: boolean;
  siblingsCount: string;
  birthStatus: ESecondaryIntakeBirthStatus;
  hasContactWithFamily: boolean;
  hasContactWithWhoInFamily: string;
  hasHistoryOfAbuseInFamily: boolean;
  familyHistoryEmotionalAbuse: boolean;
  familyHistoryPhysicalAbuse: boolean;
  familyHistoryVerbalAbuse: boolean;
  familyHistorySexualAbuse: boolean;
  wasVictimOfFamilyAbuse: boolean;
  familyVictimEmotionalAbuse: boolean;
  familyVictimPhysicalAbuse: boolean;
  familyVictimVerbalAbuse: boolean;
  familyVictimSexualAbuse: boolean;
  familyHistoryOtherDescription: string;
  height: string;
  weight: string;
  wearsGlasses: boolean;
  hasHadTbTest: boolean;
  hasHadTbTestWhenAndWhere: string;
  tbTestResults: boolean;
  isPregnant: boolean;
  isPregnantDueDate: string;
  currentHealthStatus: ESecondaryIntakeCurrentHealthStatus;
  hasCurrentMedicalIssues: boolean;
  hasCurrentMedicalIssuesDescription: string;
  hasHadCancer: boolean;
  hasHadCancerKindAndWhen: string;
  cancerRemissionLength: string;
  hasPhysicalLimitations: boolean;
  hasPhysicalLimitationsDescription: string;
  isOnMedications: boolean;
  medications: SecondaryIntakeMedication[];
  shouldBeOnMedications: boolean;
  shouldBeOnMedicationsDescription: string;
  hasMentalOrBehavioralHealthIssues: boolean;
  hasMentalOrBehavioralHealthIssuesDescription: string;
  hasBeenHospitalized: boolean;
  hasBeenHospitalizedDescription: string;
  hasBeenOnFiftyOneFiftyHold: boolean;
  hasBeenOnFiftyOneFiftyHoldDescription: string;
  hasHadStd: boolean;
  hasHadStdDescription: string;
  physicianNameAndNumber: string;
  physiciansThatHaveTreatedNames: string;
  heartProblems: boolean;
  staph: boolean;
  lice: boolean;
  seizures: boolean;
  headInjury: boolean;
  bedBugs: boolean;
  ringWorm: boolean;
  rash: boolean;
  backProblems: boolean;
  dts: boolean;
  depression: boolean;
  anxiety: boolean;
}

export interface SecondaryIntakePreviousEmployer {
  id: string;
  name: string;
  title: string;
  supervisorName: string;
  contactNumber: string;
}

export interface SecondaryIntakeEducationAndEmployment {
  id: string;
  schoolAttending: string;
  hasGraduatedHighSchool: boolean;
  highSchoolGraduatedFrom: string;
  hasGed: boolean;
  hasGedFrom: string;
  hasAttendedCollege: boolean;
  highestLevelOfEducationCompleted: string;
  hasAttendedTradeSchool: boolean;
  tradeSchoolType: string;
  hasGraduatedTradeSchool: boolean;
  dateGraduatedFromTradeSchool: string;
  wouldLikeToFurtherEducation: boolean;
  isCurrentlyEmployed: boolean;
  currentEmployer: string;
  previousEmployers: SecondaryIntakePreviousEmployer[];
}

export interface SecondaryIntakeHouseholdResident {
  id: string;
  name: string;
  birthDate: string;
  relation: string;
}

export interface SecondaryIntakeCriminalAndHousing {
  id: string;
  isOnProbationOrParole: boolean;
  probationOrParoleDescription: string;
  poNameAndNumber: string;
  pendingCourtMatters: boolean;
  pendingCourtMattersDescription: string;
  hasWarrants: boolean;
  warrantsDescription: string;
  homelessStatus: ESecondaryIntakeHomelessStatus;
  homelessLength: string;
  firstTimeBeingHomeless: boolean;
  currentResidence: string;
  currentResidenceLength: string;
  householdResidents: SecondaryIntakeHouseholdResident[];
}

export interface SecondaryIntakeCommentsAndConcerns {
  id: string;
  commentsOrConcerns: string;
}

export interface SecondaryIntake {
  id: string;
  userId: string;
  createdAt?: string;
  personalEmergencyInformationAndLifeIssues: SecondaryIntakePersonalEmergencyInformationAndLifeIssues;
  citizenshipAndIncomeInformation: SecondaryIntakeCitizenshipAndIncomeInformation;
  substanceAbuseAndTraffickingInformation: SecondaryIntakeSubstanceAbuseAndTraffickingInformation;
  familyAndMedicalHistory: SecondaryIntakeFamilyAndMedicalHistory;
  educationAndEmployment: SecondaryIntakeEducationAndEmployment;
  criminalAndHousing: SecondaryIntakeCriminalAndHousing;
  commentsAndConcerns: SecondaryIntakeCommentsAndConcerns;
  updatedOn: string;
}

export type FlattenedSecondaryIntake = {
  id: string;
  userId: string;
} & SecondaryIntakePersonalEmergencyInformationAndLifeIssues & SecondaryIntakeCitizenshipAndIncomeInformation &
  SecondaryIntakeSubstanceAbuseAndTraffickingInformation & SecondaryIntakeFamilyAndMedicalHistory &
  SecondaryIntakeEducationAndEmployment & SecondaryIntakeCriminalAndHousing & SecondaryIntakeCommentsAndConcerns;

const actions = createStandardActions(placeholder<SecondaryIntake>(), 'SECONDARY_INTAKE/SET', 'SECONDARY_INTAKE/SAVE');
const selectors = createStandardSelectors(placeholder<SecondaryIntake>(), s => getEntities(s).secondaryIntakes);
export type SecondaryIntakeActions = GetActions<typeof actions>;
export const secondaryIntakes = combineReducers({items: standardItemsReducer<SecondaryIntake, SecondaryIntakeActions>(actions)});
export const secondaryIntakeStore = readonly({
  selectors: {
    ...selectors,
    getByUserId: selector(s => (userId: string) => selectors.getAsArray(s).find(intake => intake.userId === userId)),
    getByUserIds: selector(s => (userIds: string[]) => selectors.getAsArray(s).filter(intake => userIds.includes(intake.userId)))
  },
  actions: {
    ...actions,
    getByUserId: (userId: string) => async (dispatch: CommonDispatch) => {
      const response = await getSecondaryIntake(userId);
      await dispatch(actions.save(response));
    },
    upsert: (form: SecondaryIntake) => async (dispatch: CommonDispatch) => {
      const response = await upsertSecondaryIntake(form);
      await dispatch(actions.save(response));
    }
  }
});
