import {Alert, Button, Card, Col, Container, Form as BSForm, Modal, Row, Spinner, Tab, Tabs} from 'react-bootstrap';
import styles from './CaseFile.module.scss';
import PageHeader from '../../components/layout/PageHeader/PageHeader';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../redux/core/types/WebState';
import {connect} from 'react-redux';
import React, {FormEvent, useState} from 'react';
import {participantClassStore} from '../../redux/web/entities/participantClass';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../redux/util/http';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {useMount} from '../../hooks/useMount';
import CaseFileDocumentsGrid from './CaseFileDocumentsGrid';
import {loadCaseFileState} from '../../redux/web/stateResponses/stateManagement';
import {useLocation} from 'react-router-dom';
import CaseFilePreMadeFormsGrid from './components/CaseFilePreMadeFormsGrid/CaseFilePreMadeFormsGrid';
import {loadUserFormData} from '../../redux/web/stateResponses/userFormsData';
import {userStore} from '../../redux/web/entities/user';
import {getCaseFileHistoryByUserId} from '../../api/caseFileManagementApi';
import {HistoryViewer} from '../../components/util/HistoryViewer/HistoryViewer';

type Props = ReturnType<typeof mapDispatchToProps>;

function CaseFile(props: Props) {
  const {actions: {loadCaseFileStateForUser, loadPreMadeForms, loadUser}} = props;
  const [caseFileErrorMessage, setCaseFileErrorMessage] = useState<string | undefined>('');
  const [preMadeFormsErrorMessage, setPreMadeFormsErrorMessage] = useState<string | undefined>('');
  const [loadingCaseFile, setLoadingCaseFile] = useState(true);
  const [loadingPreMadeForms, setLoadingPreMadeForms] = useState(true);
  const [tabKey, setTabKey] = useState<any>('initialInfo');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();
  const userId = location.pathname.split('/')[2];
  const [caseFileHistory, setCaseFileHistory] = useState(getCaseFileHistoryByUserId(userId));

  useMount(async () => {
    await loadUser(userId);
  });

  useMount(async () => {
      try {
        await loadCaseFileStateForUser(userId);
      } catch (e: AxiosError | any) {
        setCaseFileErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load Case File'}));
      }
      setLoadingCaseFile(false);
  });

  useMount(async () => {
    try {
      await loadPreMadeForms(userId);
    } catch (e: AxiosError | any) {
      setPreMadeFormsErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load user pre-made forms'}));
    }
    setLoadingPreMadeForms(false);
  });

  const reloadHistory = () => {
    setCaseFileHistory(getCaseFileHistoryByUserId(userId));
  };

  const renderContent = () => {
    return (
      <Card className={styles['page-body']} style={{borderLeftWidth: 0, borderRightWidth: 0}}>
        <Card.Body style={{maxWidth: '100vw'}}>
          <Row>
            <Col>
              <Tabs style={{marginBottom: '1rem'}}>
                <Tab
                  eventKey={'caseFileDocuments'}
                  title={'Documents'}
                  className={styles['form-tab']}
                >
                  {loadingCaseFile ?  <CenteredSpinner/> : (
                    caseFileErrorMessage ? <CenteredErrorMessage message={caseFileErrorMessage} /> :
                      <CaseFileDocumentsGrid reloadCaseFileHistory={() => reloadHistory()}/>
                  )}
                </Tab>
                <Tab
                  eventKey={'caseFilePreMadeForms'}
                  title={'Pre-made Forms'}
                  className={styles['form-tab']}
                >
                  {loadingPreMadeForms ?  <CenteredSpinner/> : (
                    preMadeFormsErrorMessage ? <CenteredErrorMessage style={{position: 'relative'}} message={preMadeFormsErrorMessage} /> :
                      <CaseFilePreMadeFormsGrid userId={userId}/>
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <PageHeader label='Participant Classes' removeMargin={true}>
      <Container fluid={true} className={styles['config-container']}>
        {renderContent()}
        <Card className={styles['page-body']} style={{borderLeftWidth: 0, borderRightWidth: 0, marginLeft: '1rem'}}>
          <Card.Body>
            <HistoryViewer apiHistoryFunc={caseFileHistory} label={'Case File history'}/>
          </Card.Body>
        </Card>
      </Container>
    </PageHeader>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadUser: userStore.actions.getUser,
    loadCaseFileStateForUser: loadCaseFileState,
    loadPreMadeForms: loadUserFormData
  }, dispatch)});

export default connect(null, mapDispatchToProps)(CaseFile);
