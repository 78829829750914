export enum ESecondaryIntakeGenderIdentification {
  female,
  male,
  transgenderMaleToFemale,
  transgenderFemaleToMale,
  clientDoesNotIdentify,
  clientDoesNotKnow,
  clientRefused,
  notCollected
}

export enum ESecondaryIntakeEthnicity {
  asian,
  americanIndianOrAlaskaNative,
  blackOrAfricanAmerican,
  hispanicOrLatino,
  nativeHawaiianOrOtherPacificIslander,
  whiteOrCaucasian,
  multipleRaces,
  other,
  notReported
}

export enum ESecondaryIntakeBirthStatus {
  onlyChild,
  firstChild,
  middleChild,
  lastChild
}

export enum ESecondaryIntakeAbuseType {
  emotional,
  physical,
  verbal,
  sexual
}

export enum ESecondaryIntakeCurrentHealthStatus {
  excellent,
  good,
  fair,
  poor
}

export enum ESecondaryIntakeHomelessStatus {
  notHomeless,
  shelter,
  safeHouse,
  unhoused
}
