import React from 'react';
import styles from './PageHeader.module.scss';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';
import IconButton from '../../util/widgets/IconButton/IconButton';
import {AppTheme, getLogoLink} from '../../../appTheme';
import {User, userStore} from '../../../redux/web/entities/user';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../redux/core/types/WebState';
import {connect} from 'react-redux';
import {roleStore} from '../../../redux/web/entities/role';
import {PageFooter} from '../PageFooter/PageFooter';
import AdministratorComponent from '../../util/AdministratorComponent/AdministratorComponent';
import ParticipantComponent from '../../util/AdministratorComponent/ParticipantComponent';
import NotParticipantComponent from '../../util/AdministratorComponent/NotParticipantComponent';
import AdministratorOrShelterStaffComponent
  from '../../util/AdministratorComponent/AdministratorOrShelterStaffComponent';

type Props = {
  label: string;
  removeMargin?: boolean;
  scrollBody?: boolean;
  children: any;
} & ReturnType<typeof mapStateToProps>;

const HeaderLink = ({to, text}: {to: string; text: string}) =>
  (<Link style={{fontWeight: 'bold', margin: '0 .5rem', marginBottom: '5px', color: 'black'}} to={to}>{text}</Link>);

const getHeaderForRole = (currentUser: User | null) => {
    return (
      <>
        <HeaderLink to={RoutePaths.home} text={'Home'}/>
        <ParticipantComponent>
          <HeaderLink to={RoutePaths.participantClasses} text={'Classes'}/>
        </ParticipantComponent>
        <ParticipantComponent>
          <HeaderLink to={RoutePaths.caseFilePaths.CaseFile.replace(':id', currentUser?.id ?? '')} text={'Case File'}/>
        </ParticipantComponent>
        <AdministratorComponent>
          <HeaderLink to={RoutePaths.userManagement} text='Users'/>
        </AdministratorComponent>
        <AdministratorComponent>
          <HeaderLink to={RoutePaths.caseFileManagement} text='Case Files'/>
        </AdministratorComponent>
        <AdministratorComponent>
          <HeaderLink to={RoutePaths.documentManagement} text='Document Library'/>
        </AdministratorComponent>
        <NotParticipantComponent>
          <HeaderLink to={RoutePaths.participantClassManagement} text={'Class Management'}/>
        </NotParticipantComponent>
        <HeaderLink to={RoutePaths.choreChartPaths.View} text={'Chore Chart'}/>
        <AdministratorOrShelterStaffComponent>
          <HeaderLink to={RoutePaths.checklistPaths.View} text={'Staff Checklist'}/>
        </AdministratorOrShelterStaffComponent>
        <AdministratorComponent>
          <HeaderLink to={RoutePaths.exportPath} text='Export'/>
        </AdministratorComponent>
      </>
    );
};


const PageHeader = (props: Props) => {
  const {currentUser} = props;
  const marginStyle = props.removeMargin ? {} : {marginBottom: '1rem'};
  return (
    <div className={styles['page-body']} style={AppTheme.styles.offsetBackground}>
      <div className={styles['header']} style={{...AppTheme.styles.background, ...marginStyle }}>
        <a href={RoutePaths.home} style={{display: 'flex', maxWidth: '40%'}}>
          <img src={getLogoLink()} alt='BTC Portal' className={styles['logo']}/>
        </a>
        <div className={styles['links-profile-wrapper']}>
          <div className={styles['header-links']}>
            {getHeaderForRole(currentUser)}
          </div>
          <div className={styles['profile-logout']}>
            <div style={{display: 'flex', flexDirection: 'column', marginRight: '1rem', alignItems: 'flex-end'}}>
              <Link to={RoutePaths.profilePaths.View}>
                <h5 style={{margin: 0}}>
                  {currentUser!.name}
                </h5>
              </Link>
              <div style={{fontWeight: 'bold'}}>
                {currentUser!.role.roleName}
              </div>
            </div>
            <IconButton styles={{marginLeft: '1rem'}} icon='sign-out-alt' link={RoutePaths.logout} size={'2x'} color={'#005A9C'}/>
          </div>
        </div>
      </div>
      <div className={styles['content']}>
        {props.children}
      </div>
      <PageFooter/>
    </div>
  );

};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({}, dispatch)});
const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state),
  roleName: roleStore.selectors.getById(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
