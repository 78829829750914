import {array, date, mixed, object, string} from 'yup';
import {createAllObjectShapes} from '../../../../../../redux/util/yup';
import {
  makeAssessmentQuestions,
  makeClosingInformation,
  makeHumanTraffickingInformation,
  makeParProgramQuestions,
  makePersonalInformation,
  makePrimaryIntakeForm,
  makeSecondaryIntake,
  makeSecondaryIntakeArrest,
  makeSecondaryIntakeChildNameAndAge, makeSecondaryIntakeCriminalAndHousing,
  makeSecondaryIntakeEducationAndEmployment,
  makeSecondaryIntakeFamilyAndMedicalHistory, makeSecondaryIntakeHouseholdResident,
  makeSecondaryIntakeMedication,
  makeSecondaryIntakePersonalEmergencyInformationAndLifeIssues, makeSecondaryIntakePreviousEmployer
} from '../../../../../../redux/web/factory/forms/intakeForms';
import {
  SecondaryIntake, SecondaryIntakeCriminalAndHousing, SecondaryIntakeEducationAndEmployment,
  SecondaryIntakeFamilyAndMedicalHistory, SecondaryIntakePersonalEmergencyInformationAndLifeIssues
} from '../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {
  ESecondaryIntakeCurrentHealthStatus
} from '../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntakeHelperModels';

const getFieldName = (name: keyof SecondaryIntake) => name;
const getPersonalEmergencyLifeFieldName = (name: keyof SecondaryIntakePersonalEmergencyInformationAndLifeIssues) => name;
const getFamilyMedicalFieldName = (name: keyof SecondaryIntakeFamilyAndMedicalHistory) => name;
const getEducationEmploymentFieldName = (name: keyof SecondaryIntakeEducationAndEmployment) => name;
const getCriminalHousingFieldName = (name: keyof SecondaryIntakeCriminalAndHousing) => name;

// @ts-ignore
export const SecondaryIntakeModalSchema = object<SecondaryIntake>(
  createAllObjectShapes(makeSecondaryIntake(''), undefined, {
    [getFieldName('id')]: string().nullable(),
    [getFieldName('userId')]: string().nullable(),
    [getFieldName('personalEmergencyInformationAndLifeIssues')]:
      object({...createAllObjectShapes(makeSecondaryIntakePersonalEmergencyInformationAndLifeIssues(),
          undefined, {
        [getPersonalEmergencyLifeFieldName('middleInitial')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('primaryPhone')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('secondaryPhone')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecPrimaryPhone')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecSecondaryPhone')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecRelationship')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('email')]: string().nullable().email('Email must be in a valid format.'),
        [getPersonalEmergencyLifeFieldName('childrenNamesAndAges')]: array().when(
          getPersonalEmergencyLifeFieldName('hasChildren'), {
            is: true,
            then: array().of(
              object({...createAllObjectShapes(makeSecondaryIntakeChildNameAndAge())})
            ).min(1, 'Your children must be listed.'),
            otherwise: array().required()
          }),
        [getPersonalEmergencyLifeFieldName('drugsUsedDate')]: string().when(
          getPersonalEmergencyLifeFieldName('hasUsedDrugsOrAlcohol'), {
            is: true,
            then: string().required(`Drugs Used Date is a required field. If no response is given, please put "N/A."`)
              .typeError(`Drugs Used Date is a required field. If no response is given, please put "N/A."`),
            otherwise: string().nullable()
          }),
        [getPersonalEmergencyLifeFieldName('arrestDatesAndDescriptions')]: array().when(
          getPersonalEmergencyLifeFieldName('hasBeenArrested'), {
            is: true,
            then: array().of(
              object({...createAllObjectShapes(makeSecondaryIntakeArrest())})
            ).min(1, 'Arrest dates and descriptions must be listed.'),
            otherwise: array().required()
          })}
        )}),
    [getFieldName('familyAndMedicalHistory')]:
      object({...createAllObjectShapes(makeSecondaryIntakeFamilyAndMedicalHistory(),
          undefined, {
        [getFamilyMedicalFieldName('medications')]: array().when(
          `${getFamilyMedicalFieldName('isOnMedications')}`, {
            is: true,
            then: array().of(
              object({...createAllObjectShapes(makeSecondaryIntakeMedication())})
            ).min(1, 'Medications you are currently on must be listed.'),
            otherwise: array().required()
          }),
        [getFamilyMedicalFieldName('currentHealthStatus')]: mixed().oneOf(Object.values(ESecondaryIntakeCurrentHealthStatus)),
        [getFamilyMedicalFieldName('isPregnantDueDate')]: string().when(
          getFamilyMedicalFieldName('isPregnant'), {
            is: true,
            then: string().required(`Is Pregnant Due Date is a required field. If no response is given, please put "N/A."`)
              .typeError(`Is Pregnant Due Date is a required field. If no response is given, please put "N/A."`),
            otherwise: string().nullable()
          })}
        )}),
    [getFieldName('educationAndEmployment')]:
      object({...createAllObjectShapes(makeSecondaryIntakeEducationAndEmployment(),
          undefined, {
        [getEducationEmploymentFieldName('previousEmployers')]: array().required().of(
          object({...createAllObjectShapes(makeSecondaryIntakePreviousEmployer())})
        ),
        [getEducationEmploymentFieldName('dateGraduatedFromTradeSchool')]: string().when(
          getEducationEmploymentFieldName('hasGraduatedTradeSchool'), {
            is: true,
            then: string().required(`Date Graduated From Trade School is a required field. If no response is given, please put "N/A."`)
              .typeError(`Date Graduated From Trade School is a required field. If no response is given, please put "N/A."`),
            otherwise: string().nullable()
          })}
        )}),
    [getFieldName('criminalAndHousing')]:
      object({...createAllObjectShapes(makeSecondaryIntakeCriminalAndHousing(),
          undefined, {
            [getCriminalHousingFieldName('householdResidents')]: array().of(
              object({...createAllObjectShapes(makeSecondaryIntakeHouseholdResident())})
            )}
        )})
  })
);
