import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import styles from './DocumentManagement.module.scss';
import {connect} from 'react-redux';
import {WebState} from '../../../redux/core/types/WebState';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {bindActionCreators, Dispatch} from 'redux';
import {CenteredSpinner} from '../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {handleAxiosError} from '../../../redux/util/http';
import {loadDocumentManagementData} from '../../../redux/web/stateResponses/stateManagement';
import UserGrid from './components/DocumentGrid/DocumentGrid';
import {AxiosError} from 'axios';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function DocumentManagement(props: Props) {
  const {actions: {loadDocumentData}} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    async function loadData() {
      try {
        await loadDocumentData();
      } catch (e: AxiosError | any) {
        setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load users'}));
      }
      setLoading(false);
    }
    loadData();
  });

  const renderContent = () => {
    return (
      <Row style={{flexGrow: 1}}>
        <Col>
          <Card className={styles['page-body']} style={{borderLeftWidth: 0, borderRightWidth: 0}}>
            <Card.Body>
              <UserGrid/>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <PageHeader label='User Management' removeMargin={true}>
      <Container fluid={true} className={styles['config-container']}>
        {loading ?  <CenteredSpinner/> : (
          errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
            renderContent()
        )}
      </Container>
    </PageHeader>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) =>
  ({actions: bindActionCreators({loadDocumentData: loadDocumentManagementData}, dispatch)});
const mapStateToProps = (state: WebState) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentManagement);
