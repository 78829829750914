import React from 'react';
import {
  ListHeaderWithDropDown, ResetValueFunc
} from '../../../../../components/util/lists/ListHeaderWithDropDown/ListHeaderWithDropDown';
import {getRole, User, userStore} from '../../../../../redux/web/entities/user';
import {ListTable} from '../../../../../components/util/lists/ListTable/ListTable';
import {ArrayHelpers, FieldArray} from 'formik';
import {DeletableListRow} from '../../../../../components/util/lists/ListTable/ListTableRow';
import {InputColumn} from '../../../../../components/util/form-components/InputColumn';
import {DropdownOption} from '../../../../../components/util/form-components/SearchableDropdown/SearchableDropdown';
import {selector} from '../../../../../redux/web/selectors';
import {connect} from 'react-redux';
import {makeUser} from '../../../../../redux/web/factory';
import {roleStore} from '../../../../../redux/web/entities/role';

type Props = {
  users: User[];
  userDropDownOptions: DropdownOption[];
  prefix: string;
  editable: boolean;
} & ReturnType<typeof mapStateToProps>;

function ParticipantClassUserSelection(props: Props) {
  const {prefix, getUser, editable, users, userDropDownOptions} = props;
  const onAdd = (helpers: ArrayHelpers, id: string, resetValue: ResetValueFunc) => {
    helpers.push(getUser(id).id);
    resetValue();
  };
  return (
    <FieldArray
      name={prefix}
      validateOnChange={false}
      render={helpers=> {
        return (
          <ListTable
            style={{marginBottom: '1rem'}}
            noItemsLabel={'No Participants Added'}
            items={users}
            renderHeader={() => (
              <ListHeaderWithDropDown
                label={'Add Participants'}
                dropDownOptions={userDropDownOptions.filter(u => users.every(us => u.value !== us.id))}
                onAdd={(v, r) => onAdd(helpers, v, r)}
                disableAddButton={!editable}
                addOnSelect={true}
                placeholder={'Search Participants'}
              />
            )}
            renderItem={(item, index) => (
              <DeletableListRow
                iconSize={'2x'}
                key={index}
                deleteColumnSize={1}
                render={() => (
                  <UserRow
                    prefix={`${prefix}.${index}`}
                    editable={editable}
                    user={item}
                    userDropDownOptions={userDropDownOptions}
                  />
                )}
                editable={editable}
                onDelete={() => helpers.remove(index)}
              />
            )}
          />
        );
      }}
    />
  );
}

type UserRowProps = {
  editable: boolean;
  user: User;
  prefix: string;
  userDropDownOptions: DropdownOption[];
};
function UserRow({editable, user, prefix, userDropDownOptions}: UserRowProps) {
  return  (
    <div style={{display: 'flex', flexGrow: 1}}>
      <InputColumn label={'Name'} columnSize={6} className='pl-2'>
        <h4>{user.name}</h4>
      </InputColumn>
      <InputColumn label={'Role'} columnSize={6} className='pl-2'>
        <h4>{user.role.roleName}</h4>
      </InputColumn>
    </div>
  );
}
const mapStateToProps = selector(s => ({
  getUser: userStore.selectors.getById(s)
}));
const mapToDispatch = () => ({});
export default connect(mapStateToProps, mapToDispatch)(ParticipantClassUserSelection);
