import {User, userStore} from '../../../../../redux/web/entities/user';
import React, {FormEvent, useState} from 'react';
import {ArrayHelpers, Field, FieldArray, Form, Formik, FormikErrors} from 'formik';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/core/types/WebState';
import {convertToDropDownOptions} from '../../../../../redux/util';
import {roleStore} from '../../../../../redux/web/entities/role';
import {connect} from 'react-redux';
import {
  ParticipantClass,
  ParticipantClassAssignment,
  participantClassStore
} from '../../../../../redux/web/entities/participantClass';
import {
  ListHeaderWithDropDown,
  ResetValueFunc
} from '../../../../../components/util/lists/ListHeaderWithDropDown/ListHeaderWithDropDown';
import {ListTable} from '../../../../../components/util/lists/ListTable/ListTable';
import {DeletableListRow} from '../../../../../components/util/lists/ListTable/ListTableRow';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import CheckBoxInput from '../../../../../components/util/form-components/formik-inputs/CheckBoxInput/CheckBoxInput';
import {Button, Col, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {zonedTimeToUtc} from 'date-fns-tz';
import {localTz} from '../../../../../util';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../../redux/util/http';
import {ParticipantClassModalInterface} from '../ParticipantClassModal/ParticipantClassModal';
import styles from '../../../UserManagement/components/UserModal/UserModal.module.scss';

export interface AttendanceModalInterface {
  assignments: ParticipantClassAssignment[];
}

type Props = {
  prefix: string;
  onCancel: () => void;
  onSubmit: () => void;
  participantClass: ParticipantClass;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function AttendanceModal(props: Props) {
  const {prefix, participantClass, onSubmit, onCancel, actions: {upsertParticipantClass}} = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const getFieldName = (name: keyof ParticipantClassAssignment) => name;
  const [attendingParticipants, setAttendingParticipants] =
    useState(participantClass.participantClassAssignments
      .filter((pca) => pca.attendance)
      .map((pca) => pca.participantId));

  const processSubmit = async (e: FormEvent<HTMLFormElement>, association: ParticipantClass) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    try {
      const request: ParticipantClass = {
        ...association
      } as any as ParticipantClass;
      await upsertParticipantClass(request);
      onSubmit();
    } catch (e: AxiosError | any) {
      setErrorMessage(getErrorResponseMessage(e));
    }
    setIsSubmitting(false);
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={'danger'} className={styles['close-button']}>
            {'Cancel'}
          </Button>
        }
        {!isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  return (
    <Modal show={true}>
      <Modal.Body>
        <Modal.Title>
          Class Attendance
        </Modal.Title>
        <Formik<ParticipantClass> initialValues={{...participantClass}} onSubmit={() => undefined}>
          {({values}) => {
            return (
              <Form noValidate={false} onSubmit={(e) => processSubmit(e, values)}>
                <Col>
                  {values.participantClassAssignments.map((assignment, index) =>
                    <Row key={index}>
                      <CheckBoxInput
                        name={`${prefix}[${index}].${getFieldName('attendance')}`}
                        label={assignment.participantName}
                        style={{paddingTop: '10px'}}
                        checked={attendingParticipants.includes(assignment.participantId)}
                        onChange={(checked: boolean) => {
                          checked ? setAttendingParticipants([...attendingParticipants, assignment.participantId]) :
                            setAttendingParticipants(attendingParticipants.filter(id => id !== assignment.participantId));
                        }
                      }
                      />
                    </Row>
                    )}
                </Col>
                <Row style={{paddingTop: '10px'}}>
                  <BSForm.Group style={{marginLeft: 'auto', marginRight: '10px', borderRadius: '10px', minWidth: '80px'}}>
                    {renderButtons()}
                  </BSForm.Group>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertParticipantClass: participantClassStore.actions.upsert
  }, dispatch)});

const mapStateToProps = (state: WebState) => ({
  getParticipantClass: participantClassStore.selectors.getById(state),
  participantClasses: participantClassStore.selectors.getAsArray(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(AttendanceModal);
