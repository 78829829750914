import {api} from './index';
import {CaseFile} from '../redux/web/entities/caseFile';
import {CaseFileDocument} from '../redux/web/entities/caseFileDocument';
import {CaseFileDocumentState} from '../redux/web/stateResponses/stateManagement';
import {Dictionary} from '../redux/util';
import {
  MergeCaseFileDocumentsRequest
} from '../pages/CaseFile/Modals/CaseFileDocumentMergeModal/CaseFileDocumentMergeModal';
import {BtcHistoryEvent} from '../types/BtcHistoryEvent';


export async function upsertCaseFile(caseFile: CaseFile) {
  return (await api.post<CaseFile>('/caseFile', caseFile)).data;
}

export async function getCaseFileState(userId: string) {
  return (await api.get<Dictionary<CaseFile>>(`/caseFile/state/${userId}`)).data;
}

export async function getCaseFileHistoryByUserId(id: string) {
  return (await api.get<BtcHistoryEvent[] | null>(`/caseFile/history/${id}`)).data;
}


// Case File Document Management

export async function upsertCaseFileDocument(caseFile: CaseFileDocument) {
  return (await api.post<CaseFileDocument>('/caseFileDocument', caseFile)).data;
}

export async function getCaseFileDocumentManagementState(userId: string) {
  return (await api.get<CaseFileDocumentState>(`/caseFileDocument/state/${userId}`)).data;
}

export async function getCaseFileDocumentDataById(id: string) {
  return (await api.get<CaseFileDocument>(`/CaseFileDocument/${id}`)).data;
}

export async function assignCaseFileDocument(userId: string, btcDocumentId: string) {
  return (await api.post<CaseFileDocument>(`/caseFileDocument/assign/${userId}/${btcDocumentId}`)).data;
}

export async function disableCaseFileDocument(caseFileDocumentId: string) {
  return (await api.delete<CaseFileDocument>(`/caseFileDocument/${caseFileDocumentId}`)).data;
}

export async function mergeCaseFileDocuments(userId: string, request: MergeCaseFileDocumentsRequest) {
  return (await api.post<CaseFileDocument>(`caseFileDocument/merge/${userId}`, request)).data;
}

export async function sendSigningEmail(userId: string, caseFileDocumentId: string) {
  return (await api.post<CaseFileDocument>(`/caseFileDocument/sendSigningEmail/${userId}/${caseFileDocumentId}`)).data;
}

export async function voidSigningEmail(envelopeId: string) {
  return (await api.post<CaseFileDocument>(`/caseFileDocument/voidSigningEmail/${envelopeId}`)).data;
}
