import {boolean, date, mixed, number, object, string, StringSchema} from 'yup';
import {getEnumValues} from './index';
import {startCase} from 'lodash';

export const requiredFk = () => requiredNumber().min(1, 'Required');
export const requiredNumber = () => number().required('Required').typeError('Must be a number');

export const requiredLabel = (minLength: number = 3) => string().required('Required').min(minLength, 'Must at least be 3 characters');

export const requiredString = () => string().required('Required');
export const requiredBool = () => boolean().required('Required');

export const requiredStringEnum = <T extends string>(enumObject: object) => requiredString().oneOf(getEnumValues(enumObject)) as StringSchema<T>;

// Map all fields in an object to a yup schema (can be used with nested objects as well),
// some cases such as dates will likely need to be put in the custom shapes.
// A partially defined custom shape of a nested object probably will not work as this stands
// (e.g. only defining two entries of an object that has 6 entries), as all generated shapes are overwritten
// by any defined custom shapes (look at final return statement if that does not make sense)
export function createAllObjectShapes (
  data: object,
  defaultShapeConfig?: {defaultRequired: boolean; strictTypes: boolean; requireId?: boolean},
  customShapes?: {[p: string]: any},
  errorMessageConfig?: {customMessage: string; addKeyNameToMessageStart: boolean}): {[p: string]: any} {
  // destructure configs
  const {customMessage, addKeyNameToMessageStart} = errorMessageConfig ?? {customMessage: '', addKeyNameToMessageStart: false};
  const {defaultRequired, strictTypes, requireId = false} = defaultShapeConfig ?? {defaultRequired: true, strictTypes: false, idRequired: false};
  // helper function
  const createShape = (val: any, message: string) => {
    if (strictTypes) {
      if (val instanceof Date)
        return defaultRequired ? date().required(message) : date().nullable();
      if (typeof val === 'string')
        return defaultRequired ? string().required(message) : string().nullable();
      if (typeof val === 'boolean')
        return defaultRequired ? boolean().required(message) : boolean().nullable();
      if (typeof val === 'number')
        return defaultRequired ? number().required(message) : number().nullable();
    }
    return defaultRequired ? mixed().required(message) : mixed().nullable();
  };

  // reduce object entries into yup shape
  const generatedSchema = Object.entries(data).reduce((accumulator, entry) => {
    const [key, value] = entry;
    // recursive call if there is a nested object
    if (typeof value === 'object')
      return {...accumulator, [key]: object(createAllObjectShapes(value, defaultShapeConfig))};
    // if value is not an object, error message and create shape entry
    const errorMessage = customMessage ? `${addKeyNameToMessageStart ? startCase(key) + ' ' : ''}${customMessage}` :
      `${startCase(key)} is a required field. If no response is given, please put "N/A."`;
    if (!requireId && key === 'id' || key === 'updatedOn')
      return {...accumulator, [key]: string().nullable()};
    return {...accumulator, [key]: createShape(value, errorMessage)};
  }, {});
  return {...generatedSchema, ...customShapes};
}
