import {api} from './index';

export async function processExport(query: string) {
  return (await api.post('/export', {query: query})).data;
}

export function downloadExport(reportName: string) {
  window.open(`${process.env.REACT_APP_API_URL}export/download/${reportName}`);
}

export async function sendBuilderQuery(query: string) {
  return (await api.post<string[]>('/export/query', {query: query})).data;
}
